import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import CheckButton from 'react-validation/build/button';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import ClientService from '../services/client.service';
import TenantService from '../services/tenant.service';
import NewsService from '../services/news.service';
import LambdaService from '../services/lambda.service';
import GroupsService from '../services/group.service';
import MyKademyService from '../services/mykademy.service';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Moment from 'react-moment';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import pageHeader from '../cssmodules/pageheader.module.scss';

import '../sass/admin/clientlist.scss';
import ClientList from '../components/ClientList';
import OrgBlock from '../components/OrgBlock';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert" id="alert-error">
        This field is required!
      </div>
    );
  }
};

const today = Moment.now;
const disablePresentDt = (current) => {
  return current.isAfter(today);
};

export default class NewsClient extends Component {
  constructor(props) {
    super(props);

    //this.getParticularTenant = this.getParticularTenant.bind(this);

    this.onChangeClient = this.onChangeClient.bind(this);
    this.onChangeTenant = this.onChangeTenant.bind(this);
    this.publishNewsToClient = this.publishNewsToClient.bind(this);
    this.updateNewsCLient = this.updateNewsCLient.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.removeClient = this.removeClient.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeAssignOption = this.changeAssignOption.bind(this);
    this.changePublishOption = this.changePublishOption.bind(this);
    this.changeAssignDate = this.changeAssignDate.bind(this);
    this.changePublishDate = this.changePublishDate.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.selectOrganisation = this.selectOrganisation.bind(this);
    this.changeAssignOptionForOrg = this.changeAssignOptionForOrg.bind(this);
    this.groupAssignHandler = this.groupAssignHandler.bind(this);
    this.changeAssignLaterDateForOrg = this.changeAssignLaterDateForOrg.bind(
      this,
    );
    this.changePublishOptionForOrg = this.changePublishOptionForOrg.bind(this);
    this.changePublishLaterDateForOrg = this.changePublishLaterDateForOrg.bind(
      this,
    );
    this.state = {
      clients: [],
      tenants: [],
      publishedClients: [],
      client_id: '',
      client_name: '',
      tenant_id: [],
      activeIndex: 0,
      getParticularClient: {},
      loading: true,
      createLoading: false,
      tenantLoading: false,
      showModal: false,
      errmessage: '',
      successmessage: '',
      news: {},
      groups: [],
      selectedGroups: [],
      showTenant: true,
      edit: false,
      editOfId: '',
      clientToRemove: '',
      assign_option: 'assignNow',
      publish_option: 'publishNow',
      assignDate: '',
      publishDate: '',
      isLoading: false,
      options: [],
      selectedOrganisation: [],
      organisationError: false,
      disabledGroup: [],
    };
  }

  async componentDidMount() {
    let allClients = await ClientService.getAllValid();
    //  console.log('allClients', allClients);
    let news = await NewsService.readMore(this.props.match.params.newsId);
    // console.log('news', news);
    let publishedClients = await NewsService.getPublishedClientByNews(
      this.props.match.params.newsId,
    );
    console.log(publishedClients);
    console.log(allClients);
    this.setState({
      loading: false,
      publishedClients: publishedClients,
      news: news,
      clients: allClients,
      selectedGroups:
        publishedClients.length > 0 && this.state.edit === true
          ? publishedClients[0].group
          : [],
      disabledGroup:
        publishedClients.length > 0 ? publishedClients[0].group : [],
    });
  }

  resetForm() {
    console.log('resetForm');
    this.setState({
      showTenant: false,
      client_id: '',
      editOfId: '',
      groups: [],
      edit: false,
      assign_option: 'assignNow',
      publish_option: 'publishNow',
      assignDate: '',
      publishDate: '',
      selectedOrganisation: [],
      selectedGroups: [],
      disabledGroup: [],
    });
    if (document.getElementById('alert-error')) {
      document.getElementById('alert-error').style.display = 'none';
    }

    if (document.getElementById('alert-error-next')) {
      document.getElementById('alert-error-next').style.display = 'none';
    }
  }

  selectOrganisation(option) {
    console.log('selectOrganisation', option);
    let {
      client_id,
      publishedClients,
      organisationError,
      errmessage,
      selectedOrganisation,
    } = this.state;
    let obj = publishedClients.find((item) => item.client.id === client_id);
    console.log('Object ', obj);

    this.typeahead.clear();
    if (option[0]) {
      console.log('see', option[0]);

      if (
        (obj &&
          obj.organisation &&
          obj.organisation.find(
            (item) => item.id.toString() === option[0].id.toString(),
          )) ||
        selectedOrganisation.find(
          (item) => item.id.toString() === option[0].id.toString(),
        )
      ) {
        this.setState({
          organisationError: true,
          errmessage: 'Orgnization is already selected',
        });
        setTimeout(() => {
          document.querySelector('.alert-danger').style.display = 'none';
          this.setState({
            organisationError: false,
            errmessage: '',
          });
        }, 3000);
      } else {
        console.log('Bot Found');
        // organisationError errmessage
        let selectedOrganisation = [...this.state.selectedOrganisation];
        /* Filter  */
        option[0].publish_option = 'publishNow';
        option[0].assign_option = 'assignNow';
        option[0].assignNow = 1;
        option[0].publishNow = 1;
        option[0].isPublished = true;
        option[0].isAssigned = true;
        selectedOrganisation.push(option[0]);
        this.setState({ selectedOrganisation:selectedOrganisation });
      }
    }
  }

  toggleModal(obj) {
    console.log('toggleModal');
    const { id, name } = obj;
    this.setState({
      showModal: !this.state.showModal,
      clientToRemove: {
        id,
        name,
      },
      errmessage: '',
      successmessage: '',
    });
  }

  async getClientDetail(obj) {
    console.log('getClientDetail');
    console.log(obj);
    console.log(this.state.publishedClients.find(item=>item.id===obj.clientId));
    const { index, id, clientId, clientName } = obj;

    let assign_option = '';
    let date = '';

    if (this.state.publishedClients[index].isAssigned) {
      assign_option = 'assignNow';
    }
    if (this.state.publishedClients[index].isPublished) {
      assign_option = 'publishNow';
    }
    if (!this.state.publishedClients[index].isPublished == false) {
      assign_option = 'publishLater';
      date = this.state.publishedClients[index].publishAt;
    }
    if (this.state.publishedClients[index].isAssigned == false) {
      assign_option = 'assignLater';
      date = this.state.publishedClients[index].assignAt;
    }

    console.log(this.state.publishedClients[index]);

    const {
      tenant,
      publishAt,
      assignAt,
      group,
      isAssigned,
      isPublished,
    } = this.state.publishedClients[index];

    this.setState({
      activeIndex: index,
      getParticularClient: this.state.publishedClients[index],
      client_id: id,
      tenant_id: tenant ? tenant : [],
      editOfId: clientId,
      client_name: clientName,
      edit: true,
      tenantLoading: true,
      assign_option,
      publishDate: new Date(publishAt),
      assignDate: new Date(assignAt),
      selectedGroups: group,
      disabledGroup: group,
      assign_option: isAssigned ? 'assignNow' : 'assignLater',
      publish_option: isPublished ? 'publishNow' : 'publishLater',
      date,
    });

    let allTenants = await TenantService.getAllValid(id);
    console.log(allTenants);

    this.setState({
      showTenant: true,
      tenants: allTenants.tenants,
      tenantLoading: false,
      groups: allTenants.groups,
    });
  }

  fadeOut() {
    console.log('fadeOut');
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      4000,
    );
  }

  async onChangeClient(e) {
    console.log('onChangeClient', e.target.value);
    this.setState({
      client_id: e.target.value,
      tenantLoading: true,
      showTenant: true,
      selectedOrganisation: [],
      option: [],
    });

    if (e.target.value != '') {
      let allTenants = await TenantService.getAllValid(e.target.value);
      console.log(allTenants);
      this.setState({
        tenants: allTenants.tenants,
        tenantLoading: false,
        client_name: allTenants.clientname,
      });
    }

    // Loading Group after selecting client
    let groups = await GroupsService.getClientGroups({
      clientId: e.target.value,
    });
    this.setState({
      groups: groups,
    });
  }

  changeAssignOption(e) {
    console.log('changeAssignOption');
    if (e.target.value == 'assignLater') {
      this.setState({
        publish_option: 'publishLater',
      });
    }
    this.setState({
      assign_option: e.target.value,
    });
  }

  changeAssignOptionForOrg(e, index) {
    console.log('changeAssignOptionForOrg');
    console.log('evebt', e);
    console.log('index', index);
    let selectedOrganisation = [...this.state.selectedOrganisation];
    console.log(selectedOrganisation);
    console.log(e.target.value);
    if (e.target.value == 'assignLater') {
      selectedOrganisation[index].publish_option = 'publishLater';
      selectedOrganisation[index].assign_option = 'assignLater';
      selectedOrganisation[index].isAssigned = false;
      selectedOrganisation[index].isPublished = false;     
      delete selectedOrganisation[index].assignNow;
      delete selectedOrganisation[index].publishNow;
    }
    selectedOrganisation[index].assign_option = e.target.value;
    if (e.target.value == 'assignNow') {
      selectedOrganisation[index].isAssigned = true;
      selectedOrganisation[index].assignNow = 1;
      selectedOrganisation[index].isPublished = false;  
      selectedOrganisation[index].publish_option = 'publishLater';
      selectedOrganisation[index].publishNow = 0;
     
    }
    console.log(selectedOrganisation[index]);
    this.setState({
      selectedOrganisation,
    });
  }

  changeAssignLaterDateForOrg(date, index) {
    console.log('changeAssignLaterDateForOrg');
    let selectedOrganisation = [...this.state.selectedOrganisation];
    selectedOrganisation[index].assignLaterDate = this.convertDate(date);
    selectedOrganisation[index].assign_option = 'assignLater';
    this.setState({
      selectedOrganisation,
    });
  }

  changePublishOptionForOrg(e, index) {
    console.log('changePublishOptionForOrg');
    let selectedOrganisation = [...this.state.selectedOrganisation];

    if (e.target.value == 'publishNow') {
      selectedOrganisation[index].publishNow = 1;
      selectedOrganisation[index].isPublished = true;
    } else {
      delete selectedOrganisation[index].publishNow;
      selectedOrganisation[index].isPublished = false;
    }

    selectedOrganisation[index].publish_option = e.target.value;

    this.setState({
      selectedOrganisation,
    });
  }

  changePublishLaterDateForOrg(date, index) {
    console.log('changePublishLaterDateForOrg', date, index);
    let selectedOrganisation = [...this.state.selectedOrganisation];
    if (date <= selectedOrganisation[index].assignLaterDate) {
      this.setState({
        errmessage:
          'Publish later date should be greater than Assign later date in organisation',
        organisationError: true,
      });
    } else {
      selectedOrganisation[index].publishLaterDate = this.convertDate(date);
      selectedOrganisation[index].publish_option = 'publishLater';

      delete selectedOrganisation[index].publishNow;
      this.setState({
        selectedOrganisation,
        errmessage: '',
        organisationError: false,
      });
    }
  }

  changePublishOption(e) {
    console.log('changePublishOption');
    this.setState({
      publish_option: e.target.value,
    });
  }
  convertDate(date) {
    console.log('convertDate');
    // console.log('convertDate', date._d);
    let modifiedPublishData = new Date(date._d);
    let utcmins = this.getUTCMinutes(modifiedPublishData);
    modifiedPublishData.setMinutes(utcmins);
    modifiedPublishData.setSeconds(0);
    // console.log('modifed :', modifiedPublishData);
    return modifiedPublishData;
  }
  changePublishDate(date) {
    console.log('changePublishDate');
    let publishDate = this.convertDate(date);
    if (new Date(this.state.assignDate) >= new Date(publishDate)) {
      this.setState({
        organisationError: true,
        errmessage:
          'Publish later date should be greater than Assign later date in organisation',
      });
      setTimeout(() => {
        this.setState({
          organisationError: false,
          errmessage: '',
        });
      }, 3000);
    } else {
      this.setState({
        organisationError: false,
        errmessage: '',
      });
    }

    this.setState({
      publishDate: publishDate,
    });
  }

  changeAssignDate(date) {
    console.log('changeAssignDate', this.convertDate(date));
    let assignDate = this.convertDate(date);
    if (new Date(this.state.publishDate) <= new Date(assignDate)) {
      this.setState({
        organisationError: true,
        errmessage:
          'Publish later date should be greater than Assign later date in organisation',
      });
      setTimeout(() => {
        this.setState({
          organisationError: false,
          errmessage: '',
        });
      }, 3000);
    } else {
      this.setState({
        organisationError: false,
        errmessage: '',
      });
    }
    this.setState({
      assignDate: assignDate,
    });
  }

  onChangeTenant(e) {
    console.log('onChangeTenant');
    let tenant_id = [...this.state.tenant_id];

    if (e.target.checked) {
      tenant_id.push(e.target.value);
    } else {
      let index = tenant_id.indexOf(e.target.value);
      tenant_id.splice(index, 1);
    }

    this.setState({
      tenant_id: tenant_id,
    });
  }

  changeStatus(e) {
    console.log('changeStatus');
    if (e.target.value === 'deleted') {
      this.props.history.push(
        '/news/' + this.props.match.params.newsId + '/clients/deleted',
      );
    }
  }
  getUTCMinutes() {
    console.log('getUTCMinutes');
    let newDate = this.props.value ? new Date(this.props.value) : new Date();
    let da = newDate;
    let localMinute = da.getMinutes();
    let utcMinute = da.getUTCMinutes();
    return Math.abs((localMinute - utcMinute + 30) % 60);
  }
  publishNewsToClient(e) {
    console.log('publishNewsToClient');
    e.preventDefault();

    this.setState({
      errmessage: '',
      createLoading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      if (
        this.state.assignDate != '' &&
        this.state.publishDate != '' &&
        this.state.publishDate <= this.state.assignDate
      ) {
        this.setState({
          errmessage:
            'Publish later date should be greater than Assign later date',
          createLoading: false,
        });
      } else {
     
        NewsService.publishNewsToClient(
          this.props.match.params.newsId,
          this.state.client_id,
          this.state.tenant_id,
          this.state.assign_option,
          this.state.publish_option,
          this.state.assignDate,
          this.state.publishDate,
          this.filterOrganization(this.state.selectedOrganisation),
          this.state.selectedGroups,
        ).then(
          (response) => {
            console.log(response.error);
            let publishedClients = [...this.state.publishedClients];

            // Add item to it
            publishedClients.push(response);

            // Set state
            this.setState({
              publishedClients: publishedClients,
              successmessage: `News published to client "${response.client.name}" successfully`,
              createLoading: false,
            });

            this.resetForm();

            this.fadeOut();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.data) ||
              error.message ||
              error.toString();

            this.setState({
              createLoading: false,
              errmessage: resMessage,
            });
          },
        );
      }
    } else {
      this.setState({
        createLoading: false,
      });
    }
  }
  filterOrganization (data) {
    return data.map((item) => {
      let name = item.name;
      let bracketSplit = name.split('(');
      if (bracketSplit.length > 1) {
        name = bracketSplit[0].trim();
      }
      let obj = item;
      obj.name = name;
      return obj;
    });
  };
  updateNewsCLient(e) {
    console.log('updateNewsCLient');
    e.preventDefault();

    const {
      client_id,
      tenant_id,
      selectedOrganisation,
      selectedGroups,
      assign_option,
      publish_option,
      assignDate,
      publishDate,
    } = this.state;
    let payload = {
      clientId: client_id,
      id: this.props.match.params.newsId,
      tenantId: tenant_id,
      organisation: this.filterOrganization(selectedOrganisation),
      group: selectedGroups,
      assignNow: assign_option === 'assignNow' ? 1 : 0,
      publishNow: publish_option === 'publishNow' ? 1 : 0,
      assignLaterDate: assignDate,
      publishLaterDate: publishDate,
    };

    NewsService.publishNewsToClientEdit(payload).then(
      (response) => {
        let publishedClients = [...this.state.publishedClients];

        // Add item to it
        publishedClients[this.state.activeIndex] = response;

        let msg = `News published for Tenants of client "${response.client.name}" edited successfully`;
        // Set state

        this.resetForm();

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          createLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  removeClient() {
    console.log('removeClient');
    this.setState({
      createLoading: true,
    });

    NewsService.unpublishNewsToClient(
      this.state.news._id,
      this.state.clientToRemove.id,
    ).then(
      (response) => {
        let client = [...this.state.publishedClients];
        let clientFiltered = client.filter(
          (client) => client.client._id !== this.state.clientToRemove.id,
        );
        this.setState({
          successmessage: `client "${this.state.clientToRemove.name}" removed from the News "${this.state.news.title}" successfully`,
          createLoading: false,
          publishedClients: clientFiltered,
          showModal: !this.state.showModal,
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          createLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  async handleSearch(query) {
    console.log('handleSearch');
    this.setState({
      isLoading: true,
    });

    let options =
      this.state.client_name === 'Mykademy'
        ? await MyKademyService.getOrg(query)
        : await LambdaService.organisation({ keyword: query });
    console.log('options', options);

    this.setState({
      isLoading: false,
      options,
    });
  }
  groupAssignHandler(e) {
    try {
      console.log('groupAssignHandler', e.target.checked, e.target.value);
      console.log(this.state.groups);
      let hasObj = this.state.selectedGroups.includes(e.target.value);
      if (e.target.checked == true) {
        // Add

        console.log(hasObj);
        let cloneSelectedGrouops = [...this.state.selectedGroups];
        if (!hasObj) {
          cloneSelectedGrouops.push(e.target.value);
          console.log(cloneSelectedGrouops);
          this.setState({
            selectedGroups: [...cloneSelectedGrouops],
          });
        }
      } else {
        // Remove

        console.log(hasObj);
        if (hasObj) {
          let cloneSelectedGrouops = [...this.state.selectedGroups];
          let index = cloneSelectedGrouops.indexOf(e.target.value);
          console.log(index);
          cloneSelectedGrouops.splice(index, 1);
          this.setState({
            selectedGroups: [...cloneSelectedGrouops],
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const {
      publishedClients,
      activeIndex,
      loading,
      edit,
      client_id,
      selectedGroups,
    } = this.state;

    return (
      <div id="clientList">
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />
            <main
              role="main"
              className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>Clients </h1>
                <div className={pageHeader.rightSide}>
                  <select
                    name="status"
                    className="form-control status"
                    onChange={this.changeStatus}
                    defaultValue={'active'}
                  >
                    <option value="active">Active</option>
                    <option value="deleted">Deleted</option>
                  </select>
                  <Link to="/news" className="right btn btn-dark rounded-0">
                    Back to news
                  </Link>
                </div>
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row contHolder">
                <div className="col-md-4  contLeftSide">
                  <ClientList
                    clients={publishedClients}
                    onEdit={(data) => this.getClientDetail(data)}
                    onRemove={(data) => this.toggleModal(data)}
                  />
                </div>
                <div className="col-md-8 contRightSide">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-9">
                          <h4>{this.state.news.title}</h4>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <div className="smallTxt"><b>Note :</b> <i>Use the form below to assign News to new Client and Organisations. If you have already assigned newsfeed to some clients and organisations, please select appropriate client and click edit Icon on left hand side.</i></div>
                      
                    </div>
                    <div className="card-body">
                      <Form
                        onSubmit={
                          this.state.edit
                            ? this.updateNewsCLient
                            : this.publishNewsToClient
                        }
                        ref={(c) => {
                          this.form = c;
                        }}
                        className="bp-form"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Client </label>

                              {this.state.edit && (
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state?.client_name}
                                  readOnly
                                />
                              )}

                              {!this.state.edit && (
                                <Select
                                  name="client_id"
                                  className="form-control"
                                  onChange={this.onChangeClient}
                                  validations={[required]}
                                  value={this.state.client_id}
                                >
                                  <option value="">Select</option>
                                  {this.state.clients.map((client, index) => (
                                    <option value={client._id} key={index}>
                                      {client.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            </div>
                            {this.state.tenantLoading && (
                              <div className="form-group">
                                <span className="spinner-border spinner-border-sm"></span>
                              </div>
                            )}

                            <div className="form-group">
                              <label>Assign Option</label>

                              <br></br>
                              <select
                                className="form-control"
                                value={this.state.assign_option}
                                onChange={this.changeAssignOption}
                                disabled={edit}
                              >
                                <option value="assignNow">Assign Now</option>
                                <option value="assignLater">
                                  Assign Later
                                </option>
                              </select>
                            </div>
                            {this.state.assign_option == 'assignLater' && (
                              <div className="form-group">
                                <label>Assign Later date</label>
                                <span className={this.state.edit && 'disabled'}>
                                  <Datetime
                                    isValidDate={disablePresentDt}
                                    placeholder="Select6 date and time"
                                    onChange={this.changeAssignDate}
                                    value={this.state.assignDate}
                                  />
                                </span>
                              </div>
                            )}

                            <div className="form-group">
                              <label>Publish Option</label>
                              <br></br>
                              <select
                                className="form-control"
                                value={this.state.publish_option}
                                onChange={this.changePublishOption}
                                disabled={edit}
                              >
                                {this.state.assign_option == 'assignNow' && (
                                  <option value="publishNow">
                                    Publish now
                                  </option>
                                )}

                                <option value="publishLater">
                                  Publish Later
                                </option>
                              </select>
                            </div>

                            {this.state.publish_option == 'publishLater' && (
                              <div className="form-group">
                                <label>Publish Later date</label>
                                <span className={this.state.edit && 'disabled'}>
                                  <Datetime
                                    isValidDate={disablePresentDt}
                                    placeholder="Select date and time"
                                    onChange={this.changePublishDate}
                                    value={this.state.publishDate}
                                    onAssign={(e, data) => console.log(e, data)}
                                  />
                                </span>
                              </div>
                            )}

                            {this.state.client_name &&
                              this.state?.groups?.length > 0 && (
                                <div className="form-group tenants">
                                  <label>Group</label>
                                  <br></br>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    {this.state?.groups.map((group) => (
                                      <div className="block" key={group.id}>
                                        <input
                                          type="checkbox"
                                          name="tenant_id[]"
                                          className="tenant-checkbox"
                                          value={group.id}
                                          disabled={
                                            this.state.disabledGroup.includes(
                                              group.id,
                                            )
                                              ? true
                                              : false
                                          }
                                          checked={
                                            this.state.selectedGroups.includes(
                                              group.id,
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={this.groupAssignHandler}
                                        ></input>{' '}
                                        <span>{group.name}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}

                            {this.state?.tenants.length > 0 &&
                              this.state?.showTenant && (
                                <div className="form-group tenants">
                                  <label>Tenants</label>
                                  <br></br>
                                  <label>
                                    {this.state?.tenants.map((tenant) => (
                                      <div className="block" key={tenant._id}>
                                        <input
                                          type="checkbox"
                                          name="tenant_id[]"
                                          className="tenant-checkbox"
                                          value={tenant._id}
                                          onChange={this.onChangeTenant}
                                          checked={
                                            this.state.tenant_id.includes(
                                              tenant._id,
                                            )
                                              ? true
                                              : false
                                          }
                                        ></input>{' '}
                                        <span>{tenant.name}</span>
                                      </div>
                                    ))}
                                  </label>
                                </div>
                              )}
                            {
                              <div className="form-group">
                              <AsyncTypeahead
                                labelKey={(option) => `${option.name}`}
                                isLoading={this.state.isLoading}
                                minLength={3}
                                onSearch={this.handleSearch}
                                options={this.state.options}
                                placeholder="Search for an organisation"
                                onChange={this.selectOrganisation}
                                id="select-org"
                                ref={(ref) => (this.typeahead = ref)}
                                className={this.state.client_name.length===0 ? 'disabled' :''}
                              />
                            </div>
                            }
                           

                            {publishedClients.length > 0 &&
                              publishedClients.map((item) => {
                                if (
                                  item.client.id === client_id &&
                                  item.organisation &&
                                  item.organisation.length > 0
                                ) {
                                  return (
                                    <div className="orgListBlock">
                                      {item.organisation.map((org, index) => (
                                        <OrgBlock
                                          obj={{
                                            organisation: org,
                                            index: index,
                                            status: false,
                                            
                                          }}
                                        />
                                      ))}
                                    </div>
                                  );
                                }
                              })}

                            {this.state.selectedOrganisation.map(
                              (organisation, index) => (
                                <OrgBlock
                                  obj={{
                                    organisation: organisation,
                                    index: index,
                                    status: true,
                                  }}
                                  onAssign={(e, index) =>
                                    this.changeAssignOptionForOrg(e, index)
                                  }
                                  onPublish={(e, index) =>
                                    this.changePublishOptionForOrg(e, index)
                                  }
                                  onAssignDate={(e, index) =>
                                    this.changeAssignLaterDateForOrg(e, index)
                                  }
                                  onPublishDate={(e, index) =>
                                    this.changePublishLaterDateForOrg(e, index)
                                  }
                                />
                              ),
                            )}

                            {this.state.errmessage && (
                              <div className="form-group">
                                <div
                                  className="alert alert-danger error-flash-message"
                                  role="alert"
                                  id="alert-error-next"
                                >
                                  {this.state.errmessage}
                                </div>
                              </div>
                            )}

                            <div className="form-group cardfooter mt-4">
                              <Button
                                color="primary"
                                disabled={
                                  this.state.createLoading ||
                                  this.state.organisationError
                                }
                              >
                                {this.state.edit ? 'Save' : 'Publish'}
                                {this.state.createLoading && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                              </Button>
                              <Button onClick={this.resetForm}>Cancel</Button>

                              <CheckButton
                                style={{ display: 'none' }}
                                ref={(c) => {
                                  this.checkBtn = c;
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Remove Client from the news</ModalHeader>

                <ModalBody>
                  Are you sure to remove the client "
                  {this.state.clientToRemove.name}" from the news "
                  {this.state.news.title}"
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.createLoading}
                      onClick={this.removeClient}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.createLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleModal}>
                      <i className="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
