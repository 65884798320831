import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PropTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import EditReplyForm from './EditReplyForm';
import ReplyOptions from './ReplyOptions';
import { Global } from '../api/Global';
class EachReply extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showReplayForm: false,
      showEditReplayForm: false,
      item: null,
    };
  }
  async componentDidMount() {
    if (this.props.item) {
      await this.setState({
        item: this.props.item ? this.props.item : null,
      });
    }
  }
  editReplyStatus = (val) => {
    try {
      this.setState({
        showEditReplayForm: val,
      });
    } catch (error) {
      console.log(error);
    }
  };
  removeReply = (val) => {
    try {
      let payload = {
        id: val,
      };
      API.comment
        .removeReply(payload)
        .then((response) => {
          if (response.status && response.status === 200) {
            let replyComments = [...this.state.replyComments];
            let obj = replyComments.find((item) => item._id === val);
            let index = replyComments.indexOf(obj);
            replyComments.splice(index, 1);
            this.setState(
              {
                replyComments: replyComments,
                showReplayForm: true,
              },
              () => {
                this.props.onAlert('Reply Comment is successfully deleted ');
              },
            );
          } else {
            console.log();
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };
  addReplayComment(obj) {
    let replyComments = [...this.state.replyComments];
    replyComments.unshift(obj);

    this.setState((prevState) => {
      return {
        replyComments: replyComments,
        totalReplies: prevState.totalReplies + 1,
      };
    });
  }
  editReply = (obj) => {
    try {
      this.props.onLoading(true);
      API.comment
        .editReply(obj)
        .catch((error) => console.log(error))
        .then((response) => {
          this.props.onLoading(false);

          if (response.status && response.status === 200) {
            let item = Object.assign({}, this.state.item);
            item.replyDescription = obj.msg;

            this.setState({
              showEditReplayForm: false,
              item: item,
            });
            this.props.onAlert('Reply is successfully edited ');
          }
        });
    } catch (error) {
      this.props.onLoading(false);
      console.log(error);
    }
  };
  setOptionsStatus = (val) => {
    try {
      this.setState({
        showOptions: val,
      });
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const timeAgo = new TimeAgo('en-US');
    const { item, showReplayForm, showEditReplayForm } = this.state;

    return (
      <>
        {item ? (
          <div className="userComment">
            <div className="img">
              <img
                src={
                  item.creator &&
                  item.creator.profilePic &&
                  item.creator.profilePic.length > 0
                    ? item.creator.profilePic
                    : '/img/logo.png'
                }
                width="25"
                height="25"
                alt="user"
                title="user"
              />
            </div>
            <div className="rightSide">
              <div className="rightTop">
                <div className="userInfo">
                  <h4>
                    {item.creator &&
                    item.creator.name &&
                    item.creator.name.length > 0
                      ? item.creator.name
                      : 'No Name'}
                  </h4>

                  <span className="timeago">
                    {item.updatedAt
                      ? timeAgo.format(new Date(item.updatedAt))
                      : ''}
                  </span>
                </div>
              </div>

              {showEditReplayForm ? (
                <EditReplyForm
                  id={item._id}
                  msg={item.replyDescription}
                  onEditReply={(val) => this.editReply(val)}
                />
              ) : (
                <div className="userCont">{item.replyDescription}</div>
              )}
            </div>
            {Global.user() !== 'learner' ? (
              <div className="link options">
                <button
                  className="link"
                  onClick={() => this.setOptionsStatus(true)}
                ></button>
                <img
                  src="/img/options.svg"
                  height="15"
                  width="15"
                  alt="Options"
                  title="Options"
                />

                <ReplyOptions
                  id={item._id}
                  onRemove={(val) => this.props.onRemove(val)}
                  onEdit={(val) => this.editReplyStatus(val)}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}
/*
EachReply.propTypes = {
    data: PropTypes.object,
    commentID: PropTypes.string.isRequired,
    totalLiked: PropTypes.number,
    likedByUser: PropTypes.bool,
    totalReplies: PropTypes.number,
    onAddComment: PropTypes.func,
  };
  */
export default React.memo(EachReply);
