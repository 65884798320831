import axios from 'axios';

export const Poll = {
  remove: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/poll/' +
          payload.id +
          '/delete?' +
          window.location.href.split('?')[1],

        data: {
          newsFeedClientId: payload.newsFeedClientID,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
};
