import axios from 'axios';
import AuthService from './auth.service';
const API_URL = process.env.REACT_APP_API_GATEWAY_URL;

class LambdaService {
  organisation(params = null) {
    delete axios.defaults.headers.common['access-token'];
    const axiosConfig = {
      headers: {
        'x-api-key': process.env.REACT_APP_API_GATEWAY_KEY,
        'Content-Type': 'application/json',
      },
      params,
    };
    return axios
      .get(API_URL + '/organisation', axiosConfig)
      .then((response) => {
        axios.defaults.headers.common[
          'access-token'
        ] = AuthService.getCurrentUser().accessToken;
        
        const options = response.data.body.map((i) => {
        
          let domain = i?.domain || i?.acct_domain;
          let name = domain ? `${i.name} (${domain})` : `${i.name}`;
          return {
            name: name,
            id: i.id,
          };
        });
        return options;
      });
  }
}

export default new LambdaService();
