import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import { Link } from 'react-router-dom';
import pageHeader from '../cssmodules/pageheader.module.scss';
import NewsService from '../services/news.service';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class CreateNews extends Component {
  constructor(props) {
    super(props);

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
    this.createNews = this.createNews.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
    this.state = {
      title: '',
      description: '',
      createLoading: false,
      errmessage: '',
      successmessage: '',

      imageArray: [],
      imageNameArray: [],
      images: [],

      videoArray: [],
      videoNameArray: [],
      videos: [],

      attachmentArray: [],
      attachmentNameArray: [],
      attachments: [],
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  uploadImage(e) {
    // Validation for Image extensions
    var imageFilePatten = /\.(gif|jpe?g|png|svg)$/i;
    var isValidFileType = true;
    var inValidFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (imageFilePatten.test(e.target.files[i].name) === false) {
        isValidFileType = false;
        inValidFiles.push(e.target.files[i].name);
      }
    }

    if (!isValidFileType) {
      alert('Sorry invalid file types : ' + inValidFiles.join(','));
      return false;
    }

    let imageObj = [];
    imageObj.push(e.target.files);

    let imageArray = [...this.state.imageArray];
    let imageNameArray = [...this.state.imageNameArray];
    for (let i = 0; i < imageObj.length; i++) {
      for (let j = 0; j < imageObj[i].length; j++) {
        imageArray.push(URL.createObjectURL(imageObj[i][j]));
        imageNameArray.push(imageObj[i][j].name);
      }
    }

    let images = [...this.state.images];

    images.push(e.target.files);

    this.setState({
      images: images,
      imageArray: imageArray,
      imageNameArray: imageNameArray,
    });
  }

  uploadVideo(e) {
    // Validation for Image extensions
    var filePatten = /\.(mov|mp4|mpeg)$/i;
    var isValidFileType = true;
    var inValidFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (filePatten.test(e.target.files[i].name) === false) {
        isValidFileType = false;
        inValidFiles.push(e.target.files[i].name);
      }
    }

    if (!isValidFileType) {
      alert('Sorry invalid file types : ' + inValidFiles.join(','));
      return false;
    }

    let videoObj = [];
    videoObj.push(e.target.files);

    let videoArray = [...this.state.videoArray];
    let videoNameArray = [...this.state.videoNameArray];
    for (let i = 0; i < videoObj.length; i++) {
      for (let j = 0; j < videoObj[i].length; j++) {
        videoArray.push(URL.createObjectURL(videoObj[i][j]));
        videoNameArray.push(videoObj[i][j].name);
      }
    }

    let videos = [...this.state.videos];

    videos.push(e.target.files);

    this.setState({
      videos: videos,
      videoArray: videoArray,
      videoNameArray: videoNameArray,
    });
  }

  uploadAttachment(e) {
    // Validation for Image extensions
    var filePatten = /\.(pdf|docx|doc|pptx|ppt|xlsx|xls|zip|tar|rar)$/i;
    var isValidFileType = true;
    var inValidFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      if (filePatten.test(e.target.files[i].name) === false) {
        isValidFileType = false;
        inValidFiles.push(e.target.files[i].name);
      }
    }

    if (!isValidFileType) {
      alert('Sorry invalid file types : ' + inValidFiles.join(','));
      return false;
    }

    let attachmentObj = [];
    attachmentObj.push(e.target.files);

    let attachmentArray = [...this.state.attachmentArray];
    let attachmentNameArray = [...this.state.attachmentNameArray];
    for (let i = 0; i < attachmentObj.length; i++) {
      for (let j = 0; j < attachmentObj[i].length; j++) {
        attachmentArray.push(URL.createObjectURL(attachmentObj[i][j]));
        attachmentNameArray.push(attachmentObj[i][j].name);
      }
    }

    let attachments = [...this.state.attachments];

    attachments.push(e.target.files);

    this.setState({ attachments, attachmentArray, attachmentNameArray });
  }

  removeImage(index) {
    let imageArray = [...this.state.imageArray];
    let imageNameArray = [...this.state.imageNameArray];
    imageArray.splice(index, 1);
    imageNameArray.splice(index, 1);

    this.setState({ imageArray, imageNameArray });
  }

  removeVideo(index) {
    let videoArray = [...this.state.videoArray];
    let videoNameArray = [...this.state.videoNameArray];
    videoArray.splice(index, 1);
    videoNameArray.splice(index, 1);

    this.setState({ videoArray, videoNameArray });
  }

  removeAttachment(index) {
    let attachmentArray = [...this.state.attachmentArray];
    let attachmentNameArray = [...this.state.attachmentNameArray];
    attachmentArray.splice(index, 1);
    attachmentNameArray.splice(index, 1);

    this.setState({ attachmentArray, attachmentNameArray });
  }

  createNews(e) {
    e.preventDefault();

    this.setState({
      errmessage: '',
      createLoading: true,
    });

    this.form.validateAll();
    if (this.checkBtn.context._errors.length === 0) {
      NewsService.create(
        this.state.title,
        this.state.description.value,
        this.state.images,
        this.state.imageNameArray,
        this.state.videos,
        this.state.videoNameArray,
        this.state.attachments,
        this.state.attachmentNameArray,
      ).then(
        (response) => {
          this.setState({
            successmessage: `News "${response.title}" created successfully`,
            createLoading: false,
          });
          this.fadeOut('/news');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.data) ||
            error.message ||
            error.toString();

          this.setState({
            createLoading: false,
            errmessage: resMessage,
          });
        },
      );
    } else {
      this.setState({
        createLoading: false,
      });
    }
  }

  fadeOut(redirectTo) {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
        this.props.history.push(redirectTo);
      }.bind(this),
      2000,
    );
  }
  getContent() {
    var content = '';
    if (this.state.description && this.state.description.value) {
      content = this.state.description.value;
    }
    return content;
  }

  render() {
    return (
      <div>
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>News </h1>
                <div className={pageHeader.rightSide}>
                  <Link to="/news" className="right btn btn-dark rounded-0">
                    Back to news
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <Form
                    onSubmit={this.createNews}
                    ref={(c) => {
                      this.form = c;
                    }}
                    className="bp-form"
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label>News Title</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="title"
                        value={this.state.title}
                        validations={[required]}
                        onChange={this.onChangeTitle}
                        placeholder="Title"
                      />
                    </div>

                    <div className="form-group">
                      <label>News Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={this.getContent()}
                        config={{
                          placeholder:
                            'Great, continue typing here to elaborate.',
                        }}
                        onInit={(description) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', description );
                        }}
                        onChange={(event, description) => {
                          const data = description.getData();
                          // console.log( { event, description, data } );
                          this.setState({
                            description: { value: data },
                          });
                        }}
                      />
                      {/*
                      <Textarea
                        name="description"
                        className="form-control"
                        validations={[required]}
                        onChange={this.onChangeDescription}
                        placeholder="Description"
                        value={this.state.description.value}
                      />
                      */}
                    </div>

                    <div className="form-group">
                      <label>Images</label>
                      <br />
                      <input
                        type="file"
                        multiple
                        onChange={(e) => this.uploadImage(e)}
                        style={{ display: 'none' }}
                        ref={(imageInput) => (this.imageInput = imageInput)}
                        accept="image/*"
                      />
                      <a
                        onClick={() => this.imageInput.click()}
                        className="btn btn-outline-secondary"
                      >
                        <i
                          className="fa fa-file-image-o"
                          aria-hidden="true"
                        ></i>
                        Add Images
                      </a>
                    </div>

                    <div className="fileList box">
                      {(this.state.imageArray || []).map((url, index) => (
                        <div className="fileItem">
                          <img
                            src={url}
                            className="img-thumbnail"
                            width="200"
                            height="200"
                          />
                          <button
                            title="Remove"
                            onClick={() => this.removeImage(index)}
                            className="controlBtn"
                          >
                            <img src="/img/whiteclose.svg" />
                          </button>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <label>Video</label>
                      <br />
                      <input
                        type="file"
                        multiple
                        onChange={(e) => this.uploadVideo(e)}
                        style={{ display: 'none' }}
                        ref={(videoInput) => (this.videoInput = videoInput)}
                        accept="video/mp4,video/x-m4v,video/*"
                      />
                      <a
                        onClick={() => this.videoInput.click()}
                        className="btn btn-outline-secondary"
                      >
                        <i
                          className="fa fa-video-camera"
                          aria-hidden="true"
                        ></i>
                        Add Video
                      </a>
                    </div>

                    <div className="fileList box">
                      {(this.state.videoArray || []).map((url, index) => (
                        <div className="fileItem">
                          {/* <img src={url} className="img-thumbnail" width="200" height="200"/> */}
                          <video
                            controls="controls"
                            preload="metadata"
                            className="img-thumbnail"
                            width="200"
                            height="200"
                          >
                            <source src={url} />
                          </video>
                          <button
                            title="Remove"
                            onClick={() => this.removeVideo(index)}
                          >
                            <img src="/img/whiteclose.svg" />
                          </button>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <label>Attachment</label>
                      <br />
                      <input
                        type="file"
                        multiple
                        onChange={(e) => this.uploadAttachment(e)}
                        style={{ display: 'none' }}
                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.pdf,.zip,.rar"
                        ref={(attachmentInput) =>
                          (this.attachmentInput = attachmentInput)
                        }
                      />
                      <a
                        onClick={() => this.attachmentInput.click()}
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa fa-file-o" aria-hidden="true"></i>
                        Add Attachment
                      </a>
                    </div>

                    <div
                      className="fileList row"
                      style={{ marginLeft: '-15px', marginBottom: '10px' }}
                    >
                      {(this.state.attachmentArray || []).map((url, index) => (
                        <div className="fileItem col-sm-4">
                          <div
                            className="attachment-thumbnail"
                            title="View attachment"
                          >
                            <a href={url} title="View attachment">
                              <i
                                className="fa fa-file-o"
                                aria-hidden="true"
                              ></i>

                              <span>
                                {this.state.attachmentNameArray[index]}
                              </span>
                            </a>

                            <button
                              title="Remove"
                              className="controlBtn"
                              onClick={() => this.removeAttachment(index)}
                            >
                              <img src="/img/close.svg" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="form-group">
                      <button
                        className="right btn btn-primary"
                        disabled={this.state.createLoading}
                      >
                        Save
                        {this.state.createLoading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </button>

                      <Link to="/news" className="right btn btn-secondary">
                        Cancel
                      </Link>
                      {this.state.errmessage && (
                        <div className="form-group">
                          <div
                            className="alert alert-danger error-flash-message"
                            role="alert"
                          >
                            {this.state.errmessage}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: 'none' }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />

                      {this.state.successmessage && (
                        <div className="form-group">
                          <div className="alert alert-success" role="alert">
                            {this.state.successmessage}
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
