import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import AuthService from '../../services/auth.service';
import styles from '../../cssmodules/sidebar.module.scss';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
    };
  }
  signout() {
    AuthService.logout();
  }
  componentDidMount() {
    let username = AuthService.getCurrentUser().name;

    this.setState({
      username: username,
    });
  }
  render() {
    return (
      <div className="col-lg-2 col-md-3 col-sm-4  d-md-block pl-0 adminLeftCol">
        <a
          className={styles.profilePic}
          href="#"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            className={styles.img}
            width="150"
            height="150"
            src="/img/whitelogo.svg"
            alt="Profile"
          />
          <span>{this.state.username}</span>
        </a>
        <nav className={styles.sidebar}>
          <div className="sidebar-sticky">
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link active">
                  <img src="/img/dashboard.svg" width="30" height="30" />

                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/clients" className="nav-link">
                  <img src="/img/avatar.svg" width="30" height="30" />
                  <span>Clients</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/groups" className="nav-link">
                  <img src="/img/avatar.svg" width="30" height="30" />
                  <span>Groups</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/news" className="nav-link">
                  <img src="/img/paper.svg" width="30" height="30" />
                  News
                </Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" onClick={this.signout} href="/">
                  <img src="/img/logout.svg" width="30" height="30" />
                  <span>Signout</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
