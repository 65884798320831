import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
class CarouselItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      optionStatus: false,
    };
  }
  setOption(val) {
    try {
      this.setState({
        optionStatus: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  deleteOption() {
    try {
      return this.state.optionStatus ? (
        <button onClick={() => this.props.onDeleteItem(this.props.item)}>
          <img src="/img/close.svg" />
        </button>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }
  truncateFileName(filename) {
    return filename.slice(0, 20) + '...';
  }
  render() {
    const { item } = this.props;
    return (
      <>
        <div
          onMouseEnter={() => this.setOption(true)}
          onMouseLeave={() => this.setOption(false)}
        >
          {item.type === 'image' ? (
            <>
              <img src={item.src} alt="slide" />
              {this.deleteOption()}
            </>
          ) : item.type === 'video' ? (
            <>
              {this.deleteOption()}
              <div className="playBtn">
                <img src="/img/play-light-2-pt.svg" alt="slide" />
              </div>
              <video width="320" height="240" controls>
                <source src={item.src} type="video/mp4" />
              </video>
            </>
          ) : item.type === 'attachment' ? (
            <>
              {this.deleteOption()}
              <a className="fileLink" target="_blank">
                <img src="/img/document.svg" className="doc" alt="slide" />

                <strong>{this.truncateFileName(item.name)}</strong>
              </a>
            </>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

export default React.memo(CarouselItem);
