import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';

import TenantService from '../services/tenant.service';
import Loader from '../components/Loader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import pageHeader from '../cssmodules/pageheader.module.scss';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Tenant extends Component {
  constructor(props) {
    super(props);
    this.editTenantIcon = React.createRef();
    this.createTenantIcon = React.createRef();
    this.getParticularTenant = this.getParticularTenant.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.createTenant = this.createTenant.bind(this);
    this.updateTenant = this.updateTenant.bind(this);
    this.deleteTenant = this.deleteTenant.bind(this);
    this.onChangeNameToEdit = this.onChangeNameToEdit.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.state = {
      tenants: [],
      activeIndex: 0,
      particularTenant: {},
      loading: true,
      createLoading: false,
      editLoading: false,
      deleteLoading: false,
      showModal: false,
      showDeleteModal: false,
      errmessage: '',
      successmessage: '',
      clientName: '',
      _id: null,
    };
  }

  async componentDidMount() {
    let allTenants = await TenantService.getAll(
      this.props.match.params.clientId,
    );

    this.setState(
      {
        tenants: allTenants.tenants,
        particularTenant: allTenants.tenants[0] ? allTenants.tenants[0] : {},
        nameToEdit: allTenants.tenants[0] ? allTenants.tenants[0].name : '',
        _id: allTenants.tenants[0] ? allTenants.tenants[0]._id : null,
        loading: false,
        name: '',
        clientName: allTenants.clientname,
      },
      () => {},
    );
  }

  getParticularTenant(index) {
    this.setState({
      activeIndex: index,
      particularTenant: this.state.tenants[index],
      nameToEdit: this.state.tenants[index].name,
      _id: this.state.tenants[index]._id,
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
      createLoading: false,
      name: '',
    });
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      deleteLoading: false,

      errmessage: '',
    });
  }

  fadeOut() {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      2500,
    );
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeNameToEdit(e) {
    this.setState({
      nameToEdit: e.target.value,
    });
  }

  changeStatus(e) {
    if (e.target.value === 'deleted') {
      this.props.history.push(
        '/clients/' + this.props.match.params.clientId + '/tenants/deleted',
      );
    }
  }

  updateTenant(e) {
    this.setState({
      errmessage: '',
      editLoading: true,
    });

    let payload = {
      name: this.state.nameToEdit,
      id: this.state.particularTenant._id,
      icon: this.editTenantIcon.current.files[0],
    };
    console.log('updateing', payload);
    TenantService.update(payload).then(
      async (response) => {
        //Create a new array based on current state:
        console.log(response);
        let tenants = [...this.state.tenants];

        tenants[this.state.activeIndex].name = response.name;
        tenants[this.state.activeIndex].icon = response.icon;

        let particularTenant = Object.assign({}, this.state.particularTenant);
        console.log(particularTenant.icon);
        console.log(response.icon);
        particularTenant.icon = response.icon;
        await this.setState({
          tenants: tenants,
          successmessage: `Tenant  edited successfully`,
          editLoading: false,
          particularTenant: particularTenant,
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          editLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  deleteTenant() {
    this.setState({
      deleteLoading: true,
    });

    TenantService.delete(this.state.particularTenant._id).then(
      (response) => {
        let tenant = [...this.state.tenants];
        let tenantFiltered = tenant.filter(
          (tenant) => tenant._id !== this.state.particularTenant._id,
        );
        this.setState({
          successmessage: `Tenant "${this.state.particularTenant.name}" deleted successfully`,
          deleteLoading: false,
          tenants: tenantFiltered,
          showDeleteModal: !this.state.showDeleteModal,
          particularTenant: tenantFiltered[0] ? tenantFiltered[0] : {},
          activeIndex: 0,
          nameToEdit: tenantFiltered[0] ? tenantFiltered[0].name : '',
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          deleteLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  createTenant(e) {
    e.preventDefault();

    this.setState({
      errmessage: '',
      createLoading: true,
    });

    this.form.validateAll();
    if (this.checkBtn.context._errors.length === 0) {
      let payload = {
        name: this.state.name,
        clientId: this.props.match.params.clientId,
        icon: this.createTenantIcon.current.files[0],
      };
      TenantService.create(payload).then(
        (response) => {
          this.toggleModal();
          // Create a new array based on current state:
          let tenants = [...this.state.tenants];

          // Add item to it
          tenants.push(response);

          // Set state
          this.setState({
            tenants: tenants,
            successmessage: `Tenant "${response.name}" created successfully`,
          });

          this.fadeOut();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            createLoading: false,
            errmessage: resMessage,
          });
        },
      );
    } else {
      this.setState({
        createLoading: false,
      });
    }
  }

  render() {
    const {
      clientName,
      tenants,
      activeIndex,
      particularTenant,
      loading,
    } = this.state;

    return (
      <div id="clientList">
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />
            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>
                  Tenants of client "{clientName}"
                </h1>

                <div className={pageHeader.rightSide}>
                  <select
                    name="status"
                    className="form-control status"
                    onChange={this.changeStatus}
                  >
                    <option value="active" selected>
                      Active
                    </option>
                    <option value="deleted">Deleted</option>
                  </select>
                  <button
                    className="right btn btn-dark rounded-0"
                    onClick={this.toggleModal}
                  >
                    Create Tenants
                  </button>
                </div>
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row contHolder">
                {tenants.length > 0 ? (
                  <div className="col-md-4 pr-0 contLeftSide">
                    <div class="holder">
                      <div className="header">
                        <h3>Tenants List</h3>
                        {tenants.length > 10 ? (
                          <button className="viewAllBtn"> View All</button>
                        ) : (
                          ''
                        )}
                      </div>
                      <ul className="list-group">
                        {tenants.map((tenant, index) => (
                          <li
                            key={tenant._id}
                            className={`listgroupitem ${
                              index === activeIndex ? 'active' : ''
                            }`}
                            onClick={() => this.getParticularTenant(index)}
                          >
                            <div className="clienticon">
                              {tenant.icon ? (
                                <img
                                  className="icon"
                                  src={tenant.icon}
                                  alt="Client"
                                />
                              ) : (
                                <img
                                  src={require('../user.svg')}
                                  alt="Client"
                                />
                              )}
                            </div>

                            <div className="clientinfo">
                              <h5> {tenant.name}</h5>

                              <button
                                className="iconBtn"
                                onClick={() => this.toggleDeleteModal()}
                              >
                                <img src="/img/grayclose.svg" />
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-12">
                    <div
                      className=" alert alert-danger error-flash-message"
                      role="alert"
                    >
                      Sorry, no any tenants of client {clientName}
                    </div>
                  </div>
                )}

                {this.state.particularTenant.name && (
                  <div className="col-md-8 pl-0 contRightSide">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-9">
                            <h4>Tenant Information</h4>
                          </div>
                          <div className="col-md-3">
                            {/* <button className="right btn btn-secondary "><i className="fa fa-sitemap" aria-hidden="true"></i>Tenants</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="col-sm-2 d-flex justify-content-center">
                          {this.state.particularTenant.icon ? (
                            <img
                              src={this.state.particularTenant.icon}
                              width="100"
                              height="100"
                            />
                          ) : (
                            <img
                              src={require('../user.svg')}
                              width="100"
                              height="100"
                            />
                          )}
                        </div>
                        <Form className="bp-form col-sm-10">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Name</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="nameToEdit"
                                  value={this.state.nameToEdit}
                                  onChange={this.onChangeNameToEdit}
                                />
                              </div>
                              <div className="form-group">
                                <label>Icon</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="nameToEdit"
                                  ref={this.editTenantIcon}
                                />
                              </div>
                              <div className="form-group">
                                <label>ID</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="nameToEdit"
                                  value={this.state._id}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                      <div class="card-footer">
                        <Button
                          className="btn btn-secondary"
                          disabled={this.state.editLoading}
                          onClick={this.updateTenant}
                        >
                          Save
                          {this.state.editLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Create Tenant</ModalHeader>
                <Form
                  onSubmit={this.createTenant}
                  ref={(c) => {
                    this.form = c;
                  }}
                  className="bp-form"
                >
                  <ModalBody>
                    <div className="form-group">
                      <label>Tenant name</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        value={this.state.name}
                        validations={[required]}
                        onChange={this.onChangeName}
                        placeholder="name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Icon</label>
                      <input
                        type="file"
                        className="form-control"
                        name="name"
                        placeholder="name"
                        ref={this.createTenantIcon}
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div className="form-group">
                      <Button
                        color="primary"
                        disabled={this.state.createLoading}
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>Save
                        {this.state.createLoading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </Button>
                      <Button color="secondary" onClick={this.toggleModal}>
                        <i class="fa fa-close" aria-hidden="true"></i>Cancel
                      </Button>
                    </div>
                    {this.state.message && (
                      <div className="form-group">
                        <div
                          className="alert alert-danger error-flash-message"
                          role="alert"
                        >
                          {this.state.errmessage}
                        </div>
                      </div>
                    )}
                    <CheckButton
                      style={{ display: 'none' }}
                      ref={(c) => {
                        this.checkBtn = c;
                      }}
                    />
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal
                isOpen={this.state.showDeleteModal}
                toggle={this.toggleDeleteModal}
                className="modal"
              >
                <ModalHeader>Delete Tenant </ModalHeader>

                <ModalBody>
                  Are you sure to delete the tenant "
                  {this.state.particularTenant.name}"
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.deleteLoading}
                      onClick={this.deleteTenant}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.deleteLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleDeleteModal}>
                      <i class="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
