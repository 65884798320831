import React, { Component } from 'react';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Paginate from '../components/Pagination';
import NewsService from '../services/news.service';
import EachNews from './eachnews';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import pageHeader from '../cssmodules/pageheader.module.scss';

export default class News extends Component {
  constructor(props) {
    super(props);

    this.handlePagination = this.handlePagination.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteNews = this.deleteNews.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.state = {
      news: [],
      loading: true,
      errmessage: '',
      successmessage: '',
      pagination: '',
      page: 1,
      showModal: false,
      newsToDelete: '',
      createLoading: false,
    };
  }

  async componentDidMount() {
   
    let allNews = await NewsService.getAll();
    
    this.setState({
      news: allNews && allNews.data ? allNews.data : [],
      pagination: allNews && allNews.meta ? allNews.meta : {},
      loading: false,
    });
  }
  async reloadNews(){
    this.setState({
      loading: true
    })
    let allNews = await NewsService.getAll();
    console.log("reloadNews");
   if(allNews){
    console.log(allNews);
    
    this.setState({
      news: allNews && allNews.data ? allNews.data : [],
      pagination: allNews && allNews.meta ? allNews.meta : {},
      loading: false,
    });
   }
   
  }
  async seachWithKeyword(e) {
    await NewsService.getNewsByKeyword({
      search: e.target.value,
    }).then((res) => {
      if (res) {
        this.setState({
          news: res.data,
          pagination: res.meta,
        });
      }
    });
  }
  async handlePagination(page) {
    this.setState({
      loading: true,
    });

    let allNews = await NewsService.getAll({
      page,
    });

    this.setState({
      news: allNews.data,
      pagination: allNews.meta,

      loading: false,
    });
  }

  changeStatus(e) {
    if (e.target.value === 'deleted') {
      this.props.history.push('/news/deleted');
    }
  }

  fadeOut() {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      4000,
    );
  }

  toggleModal(id, title) {
    this.setState({
      showModal: !this.state.showModal,
      newsToDelete: {
        id,
        title,
      },
      errmessage: '',
      successmessage: '',
    });
  }

  deleteNews() {
    this.setState({
      createLoading: true,
    });

    NewsService.deleteNews(this.state.newsToDelete.id).then(
      (response) => {
        let news = [...this.state.news];
        let newsFiltered = news.filter(
          (news) => news._id !== this.state.newsToDelete.id,
        );
        this.setState({
          successmessage: `News "${this.state.newsToDelete.title}" deleted successfully`,
          createLoading: false,
          news: newsFiltered,
          showModal: !this.state.showModal,
        });

        if (this.state.news.length === 0) {
          this.handlePagination(
            this.state.pagination.currentPage === 1
              ? 1
              : this.state.pagination.currentPage - 1,
          );
        }

        this.fadeOut('/news');
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          createLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  render() {
    const { news, loading } = this.state;

    return (
      <div>

        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
             
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>News </h1>

                <div className={pageHeader.rightSide}>
                  <Link
                    to="/news/create"
                    className="right btn btn-dark rounded-0"
                  >
                    Create News
                  </Link>
                </div>
              </div>
              <div className={pageHeader.searchBar}>
                <input
                  type="text"
                  placeholder="Search News from keyword ..."
                  onKeyUp={(e) => this.seachWithKeyword(e)}
                />
                <select
                  name="status"
                  className="form-control status"
                  onChange={this.changeStatus}
                  value="active"
                >
                  <option value="active">Active</option>
                  <option value="deleted">Deleted</option>
                </select>
              </div>
              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  {news.length > 0
                    ? news.map((news, index) => (
                        <EachNews
                          key={news._id}
                          _id={news._id}
                          title={news.title}
                          description={news.description}
                          createdAt={news.createdAt}
                          isEven={index % 2 === 0}
                          publishBtnStatus={true}
                          editBtnStatus={true}
                          deleteBtnStatus={true}
                          medias={news.medias}
                          creator={news.creator}
                          isPinned={news.isPinned}
                          toggleModal={(_id, title) =>
                            this.toggleModal(_id, title)
                          }
                          onReLoadNews={()=>this.reloadNews()}
                        />
                      ))
                    : ''}
                </div>
              </div>

              {news.length > 0 && (
                <div className="pagination-section">
                  <Paginate
                    pages={this.state.pagination.pages}
                    currentPage={this.state.pagination.currentPage}
                    handlePagination={this.handlePagination}
                  />
                </div>
              )}

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Delete News</ModalHeader>

                <ModalBody>
                  <div class="text-center">
                    Are you sure to delete the news "
                    {this.state.newsToDelete.title}"
                  </div>
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.createLoading}
                      onClick={this.deleteNews}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.createLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleModal}>
                      <i class="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
