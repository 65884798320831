import React, { useEffect, useState,useRef } from 'react';
import ClientService from '../../services/client.service';
import LambdaService from '../../services/lambda.service';
import MyKademyService from '../../services/mykademy.service';
import GroupsService from "../../services/group.service";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import styles from "../../cssmodules/addorg.module.scss";
export default function EditGroup(props) {
  const {name,id,orgs,onClose,onEdit,client} = props;
  console.log(orgs);
  const [data, setData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [clientName, setClientName] = useState(null);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [groupName,setGroupName]=useState(name);
  const selectOrg =  useRef(null);
  useEffect(() => {
    getAllClients();
    
    
  }, []);
  useEffect(()=>{
    if(orgs){
      let orgsNames = orgs.map(item=>{
        let name = item.domain ? item.name+"("+item.domain+")" : item.name;
        return {
          id:item.id,
          name
        }
      })
      console.log(orgsNames);
      //setSelectedOrgs(orgs);
      setSelectedOrgs(orgsNames);
    }
    console.log(props);
   
    setClientName(name);
  },[props.name,props.orgs])
  const getAllClients = async () => {
    try {
      let result = await ClientService.getAll();
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };
  const selectClientHandler = (e) => {
    if (e.target.value) {
      setSelectedClientId(e.target.value);
      setClientName(data.find((item) => item.id === e.target.value).name);
    }
  };
  const handleSearch = async (query) => {
    try {
   
      setIsLoading(true);

      let options =
      client.name === 'Mykademy'
          ? await MyKademyService.getOrg(query)
          : await LambdaService.organisation({ keyword: query });
    
      let queryGroups = options.map(item=>{
        let obj = item;
        obj.id = item.id.toString();
        return obj;
      })
      let filterGroups = queryGroups.filter(item=>{
        return selectedOrgs.filter(obj=>obj.id.toString() === item.id.toString()).length === 0
      })
      setOptions(filterGroups);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const selectOrganisation = (data) => {
    if (data.length > 0) {
      let total = selectedOrgs.filter((item) => item.id === data[0].id);
      console.log(total);
      if (total.length === 0) {
        let orgs = selectedOrgs.filter((item) => item !== null);
        orgs.push(data[0]);
        setSelectedOrgs(orgs);
        selectOrg.current.clear();
      }
    }
  };
  const removeOrgHandler = (e) => {
    let clone = [...selectedOrgs];
    let id = e.currentTarget.dataset.id;   
    let obj = clone.find(
      (item) => item.id.toString() === id.toString(),
    );
   
    let index = selectedOrgs.indexOf(obj);  
    console.log(index)
    e.preventDefault();
    
    clone.splice(index, 1);  
    setSelectedOrgs([...clone]);
  };
  const filterOrganization = (data) => {
    console.log(data);
    
   

    return data.map((item) => {
      let name = item.name;
      /* Just find domain only which is in bracket */
      let startBracket = item.name.search(/\(/);
    let endBracket = item.name.search(/\)/);
    let domain='';
    if(startBracket>=0 && endBracket>=0){
      startBracket=startBracket+1;
      domain = item.name.slice(startBracket,endBracket);
    }

      let bracketSplit = name.split('(');
      if (bracketSplit.length > 1) {
        name = bracketSplit[0].trim();
      }
      let obj = item;
      obj.name = name;
      obj.domain = domain
      return obj;
    });
  };
  const saveOrgs = async()=>{
    try{
      let payload = {
        id:id.toString(),
        organisation : filterOrganization(selectedOrgs),
        name:groupName
      }
      let result = await GroupsService.updateGroup(payload);

      
      onEdit(result);
      console.log(result);
    }catch(error){
      console.error(error)
    }
   
  }
  return (
    <div className="bp-form">
      <h2>Edit Group : </h2>
    
       <div className="form-group">
        <label>Name</label>       
      </div>
      <div>
      <input type="text" className="form-control" value={groupName} onChange={(e)=>setGroupName(e.target.value)}  />
      </div>
      <div className="form-group">
        <label>Client</label>
      </div>
       <div className="form-group">
        <div>
          <select
            onChange={selectClientHandler}
            className="selectBox form-control"
            value={client._id}
            disabled={true}
          >
            <option value="">Select Client</option>
            {data.map((item) => (
              <option value={item.id} key={item.id} >
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-group">
        {clientName && (
          <AsyncTypeahead
            labelKey={(option) => `${option.name}`}
            isLoading={isLoading}
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Search for an organisation"
            onChange={(obj) => selectOrganisation(obj)}
            id="select-org"
            ref={selectOrg}
          />
        )}
      </div>
      
      <div className="form-group" style={{ marginTop: '50px' }}>
        {selectedOrgs && selectedOrgs.length > 0 && (
          <>
            <h4>Selected Organisations</h4>
           
            <ul className={styles.orgList}>
              {selectedOrgs.map((item,index) => (
                <li key={item.id}>
                  {item.name} 
                 
                    <img src="/img/grayclose.svg" type="button"
                    data-id={item.id}
                    className="iconBtn"
                    width="12"
                    onClick={removeOrgHandler} />
                 
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <div className="form-group cardfooter">
        <button
          onClick={saveOrgs}
          className="btn btn-primary"
          disabled={selectedOrgs.length === 0}
        >
          Save
        </button>
        <button
          onClick={()=>onClose()}
          className="btn btn-secondary"
          
        >
          Close
        </button>
      </div>
    </div>
  );
}
