import React, { PureComponent } from 'react';
import SinglePost from './post/SinglePost';
import { API } from './api/API';
import { Global } from './api/Global';

class SchedulePostList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSchedule: false,
      posts: [],
      total: null,
      remainPost: null,
      meta: {
        perPage: 10,
        currentPage: 0,
        total: 0,
      },
    };
  }
  componentDidMount() {
    this.setState({
      total: this.props.total ? this.props.total : null,
    });
  }
  showNextPage() {
    let payload = {};
    let meta = { ...this.state.meta };
    payload.page = meta.currentPage + 1;
    this.props.onLoading(true);
    document.body.classList.add('overflowHidden');
    API.getSchedulePosts(payload)
      .catch((error) => {
        console.log(error);
      })
      .then((response) => {
        this.props.onLoading(false);
        document.body.classList.remove('overflowHidden');
        // currentPage :1 pages: 3 perPage: 10 total: 21

        let meta = { ...response.data.meta };
        let remainPost = meta.total - meta.currentPage * meta.perPage;
        remainPost = remainPost > meta.perPage ? meta.perPage : remainPost;
        let clonePosts = [...this.state.posts];
        let crawlData = response.data.data.forEach((item) => {
          clonePosts.push(Global.createNewsFeedObj(item));
        });

        if (response && response.data && response.data.data) {
          this.setState({
            posts: clonePosts,
            meta: meta,
            remainPost: remainPost,
          });
        } else {
          console.log('Response Error :', response);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.onLoading(false);
        document.body.classList.remove('overflowHidden');
      });
  }
  toggleShowSchedule() {
    try {
      // Need to call for page 1 only if it is intial
      if (!this.state.showSchedule && this.state.posts.length == 0) {
        this.showNextPage();
      }
      this.setState((prevState) => {
        return { showSchedule: !prevState.showSchedule };
      });
    } catch (error) {
      console.log(error);
    }
  }
  async updateItem(obj) {
    try {
      let posts = [...this.state.posts];
      let item = posts.find((item) => item.id === obj.newsFeedClientId);
      if (item) {
        let index = posts.indexOf(item);

        posts[index].title = obj.title;
        posts[index].content = obj.content;
        posts[index].gallery = obj.gallery;
        posts[index].galleryType = obj.galleryType;

        await this.setState({
          posts: posts,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  showScheduleList() {
    try {
      let { meta, remainPost, posts } = { ...this.state };
      return this.state.showSchedule ? (
        <>
          {posts.length > 0
            ? posts.map((item) => (
                <div className="schedulePost" key={item.id}>
                  <SinglePost
                    id={item.id}
                    img={item.img}
                    title={item.title}
                    updatedAt={item.createdAt}
                    from={item.from}
                    content={item.content}
                    gallery={item.gallery}
                    galleryType={item.galleryType}
                    attachments={item.attachments}
                    isSchedule={true}
                    medias={item.medias}
                    newsFeedID={item.newsFeedID}
                    creatorName="no name"
                    creatorId={item.creatorId}
                    likedByUser={item.likedByUser}
                    comments={[]}
                    totalComments={item.totalComments}
                    totalLikes={item.totalLikes}
                    poll={item.poll}
                    publishAt={item.publishAt}
                    isPollVoted={item.isPollVoted}
                    isCommentable={item.isCommentable}
                    isSharedWithAdmin={
                      item.isSharedWithAdmin ? item.isSharedWithAdmin : null
                    }
                    isSharedWithAll={
                      item.isSharedWithAll ? item.isSharedWithAll : null
                    }
                    isSharedWithLearner={
                      item.isSharedWithLearner ? item.isSharedWithLearner : null
                    }
                    onlySharedWith={
                      item.onlySharedWith ? item.onlySharedWith : null
                    }
                    onConfirmBox={(id) => this.props.onConfirmBox(id)}
                    onUpdated={() => this.changeUpdateNewsFeed()}
                    onCrawlPostList={() => this.props.onCrawlPostList()}
                    onLoading={(val) => this.props.onLoading(val)}
                    onRemovePost={(item) => this.removePost(item)}
                    onAlert={(data) => this.props.onAlert(data)}
                    onOK={() => this.props.onOK()}
                    onUpdateItem={(item) => this.updateItem(item)}
                  />
                </div>
              ))
            : ''}
          {meta &&
          meta.currentPage &&
          meta.pages &&
          meta.currentPage < meta.pages ? (
            <div className="all">
              <span>
                <a href="#" onClick={() => this.showNextPage()}>
                  View {remainPost} more Scheduled
                  {remainPost === 1 ? ' Post ' : ' Posts '}
                </a>
              </span>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      );
    } catch (err) {
      console.log(err);
    }
  }
  async removePost(id) {
    await API.removePost({ id: id })
      .catch((error) => {
        console.log(error);
      })
      .then((response) => {
        let posts = [...this.state.posts];
        let obj = posts.find((item) => item.id === id);
        let index = posts.indexOf(obj);
        posts.splice(index, 1);

        this.props.onAlert('Scheduled post has been removed.');
        this.setState((prevState) => {
          return { posts: posts, total: prevState.total - 1 };
        });
        // this.props.onCrawlPostList();
      });
  }
  render() {
    const { total } = this.state;
    return (
      <>
        <div id="schedulePostList">
          <div className="trigger">
            <div className="leftSide">
              <img
                src="/img/time.svg"
                width="30"
                height="30"
                alt="Scheduled Posts"
                title="Scheduled Posts"
              />
              <strong>{total ? total : ''} Scheduled Posts</strong>
            </div>
            <div className="rightSide">
              {this.state.showSchedule ? (
                <img
                  onClick={() => this.toggleShowSchedule()}
                  src="/img/close.svg"
                  width="30"
                  height="30"
                  alt="Scheduled Posts"
                  title="Scheduled Posts"
                />
              ) : (
                <img
                  onClick={() => this.toggleShowSchedule()}
                  src="/img/arrow.svg"
                  width="18"
                  height="18"
                  alt="Show scheduled Posts"
                  title="Show scheduled Posts"
                />
              )}
            </div>
          </div>
          {this.showScheduleList()}
        </div>
      </>
    );
  }
}

export default React.memo(SchedulePostList);
