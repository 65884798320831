import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PropTypes from 'prop-types';
import { Global } from '../api/Global';
class ReplyForm extends PureComponent {
  constructor(props) {
    super(props);
    this.commentBox = React.createRef();
    this.sendBtn = React.createRef();
    this.state = {
      newsFeedClientID: null,
      message: '',
      commentID: null,
      userInfo: null,
    };
  }
  componentDidMount() {
    try {
      this.commentBox.current.addEventListener('paste', function (e) {
        e.preventDefault();
      });

      this.commentBox.current.addEventListener('keyup', (e) => {
        this.sendBtn.current.disabled = !e.target.innerText.length > 0;
      });

      this.setState({
        newsFeedClientID: this.props.newsFeedClientID,
        commentID: this.props.commentID,
      });

      API.userInfo()
        .then((response) => {
          console.log(response);
          let userInfo = {
            name: response.name ? response.name : 'Olive',
            profilePic: response.profilePic
              ? response.profilePic
              : '/img/logo.png',
            orgIcon: response.orgIcon ? response.orgIcon : '/img/logo.png',
            orgName: response.orgName ? response.orgName : 'Olive',
          };
          this.setState({
            userInfo: userInfo,
          });
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }
  setMessage() {
    try {
      this.setState({
        message: this.commentBox.current.innerText,
      });
    } catch (error) {
      console.log(error);
    }
  }
  postReply() {
    try {
      this.setState({
        message: '',
      });
      this.commentBox.current.innerText = '';

      this.props.onLoading(true);
      if (this.state.message.trim().length == 0) {
        this.props.onAlert('Message is empty');
        this.props.onLoading(false);
      } else {
        let payload = {
          newsFeedClientId: this.state.newsFeedClientID,
          replyDescription: this.state.message,
          id: this.state.commentID,
          urlParams: window.location.href.split('?')[1],
        };

        if (Global.socket) {
          Global.socket.emit('reply', payload);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { userInfo, message } = this.state;
    return (
      <div className="commentForm replyForm">
        <div className="leftSide">
          <div className="img">
            {' '}
            {userInfo ? (
              <img
                src={userInfo.profilePic}
                width="30"
                height="30"
                alt={userInfo.organisationName || ''}
              />
            ) : (
              <img src="/img/logo.png" width="30" height="30" alt="Olive" />
            )}
          </div>
          <div
            ref={this.commentBox}
            onInput={() => this.setMessage()}
            className="comment"
            placeholder="Write your reply here"
            contentEditable="true"
            suppressContentEditableWarning={true}
          ></div>
        </div>
        <div className="rightSide">
          <button
            ref={this.sendBtn}
            className="sendComment btn btn-primary"
            onClick={() => this.postReply()}
            disabled={message.length > 0 ? '' : 'disabled'}
          >
            <img className="xs-only" src="/img/enter.svg" />
            <span>Reply</span>
          </button>
        </div>
      </div>
    );
  }
}

ReplyForm.propTypes = {
  newsFeedClientID: PropTypes.string.isRequired,
  commentID: PropTypes.string.isRequired,
  onLoading: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  onAddReplyComment: PropTypes.func.isRequired,
};
export default React.memo(ReplyForm);
