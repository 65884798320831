import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PollResult from './PollResult';
import { Global } from '../api/Global';

class PostPoll extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pollQuestion: '',
      options: [],
      startsAt: null,
      type: 'single',
      singleAns: null,
      multipleAns: [],
      pollResultStatus: false,
      voted: false,
      result: null,
      totalParticipations: 0,
      showConfirmBox: false,
      id: null,
    };
  }
  componentDidUpdate() {}
  componentDidMount() {
    if (this.props.data) {
      this.setState({
        pollQuestion: this.props.data.pollQuestion,
        options: this.props.data.options,
        startsAt: this.props.data.startsAt
          ? this.props.data.startsAt
          : Global.getCurrentDate(),
        type: this.props.data.type,
        id: this.props.data['_id'],
        voted: this.props.isPollVoted ? this.props.isPollVoted : false,
        totalParticipations: this.props.data.totalParticipations,
      });
    }
  }
  setOption(e) {
    try {
      /* Single Option Choosed on Radio Button */
      if (this.state.type === 'single' && e.target.checked) {
        if (e.target.checked) {
          this.setState({
            singleAns: e.target.value,
          });
        }
      } else {
        /* Multiple Options Choosed on Checkbox Button */
        let ans = [...this.state.multipleAns];

        if (e.target.checked) {
          ans.push(e.target.value);
          this.setState({
            multipleAns: ans,
          });
        } else {
          let index = ans.indexOf(e.target.value);
          ans.splice(index, 1);
          this.setState({
            multipleAns: ans,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  voteForPoll() {
    try {
      let data = {};

      data.options =
        this.state.type === 'single'
          ? this.state.singleAns
          : this.state.multipleAns;
      data.id = this.state.id;

      API.votePoll(data)
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          this.setState((prevState) => {
            return {
              totalParticipations: prevState.totalParticipations + 1,
              voted: true,
              options: response.data.data.options,
              pollResultStatus: true,
            };
          });
        });
    } catch (error) {
      console.log(error);
    }
  }
  setInputStatus() {
    try {
      return this.state.voted ? 'disabled' : '';
    } catch (error) {
      console.log(error);
    }
  }
  setVoteBtnStatus() {
    try {
      return this.state.voted
        ? 'disabled'
        : this.state.type === 'single'
        ? this.state.singleAns
          ? ''
          : 'disabled'
        : this.state.multipleAns.length > 0
        ? ''
        : 'disabled';
    } catch (error) {
      console.log(error);
    }
  }
  showResult() {
    try {
      let showResult = !this.state.pollResultStatus;
      this.setState({
        pollResultStatus: showResult,
      });
    } catch (error) {
      console.log(error);
    }
  }
  setClass() {
    return this.state.voted ? 'poll disabled displayPoll' : 'displayPoll';
  }
  isViewResultAuthorize() {
    try {
      let status = true;
      if (!this.state.voted && Global.user() === 'learner') {
        status = false;
      }
      return status;
    } catch (error) {
      console.log(error);
    }
  }
  setConfirmBoxStatus(val) {
    try {
      this.setState({
        showConfirmBox: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <>
        <section className={this.setClass()}>
          <div className="cont">
            <div
              className={`pollQuestion  ${this.state.voted ? 'hide' : ''}
              `}
            >
              {!this.state.voted ? (
                <>
                  <h3>{this.state.pollQuestion} </h3>
                  <p>
                    Select your response below, then select the vote button to
                    add your submission
                  </p>
                </>
              ) : (
                ''
              )}

              {this.state.options.length > 0 && this.state.voted === false ? (
                <div className="pollOptions">
                  {this.state.options.map((item) => {
                    return (
                      <label key={item['_id']} className="firstOption option">
                        <div className="leftSide">
                          {this.state.type && this.state.type === 'single' ? (
                            <div className="input">
                              <label className="label">
                                <input
                                  disabled={this.setInputStatus()}
                                  type="radio"
                                  value={item['_id']}
                                  name="poll"
                                  onChange={(e) => this.setOption(e)}
                                />
                                <div className="radiobtn"></div>
                              </label>
                            </div>
                          ) : (
                            <div className="input">
                              <div className="label">
                                <input
                                  disabled={this.setInputStatus()}
                                  type="checkbox"
                                  name="poll"
                                  value={item['_id']}
                                  onChange={(e) => this.setOption(e)}
                                />
                                <div className="checkbox"></div>
                              </div>
                            </div>
                          )}

                          {item.optionName && item.optionName.length > 0 ? (
                            <div className="name">{item.optionName}</div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="userImg">
                          {item.optionThumbnail &&
                          item.optionThumbnail.length > 0 ? (
                            <img
                              src={item.optionThumbnail}
                              width="75"
                              height="75"
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </label>
                    );
                  })}
                </div>
              ) : (
                ''
              )}

              <div className="voteBtn">
                {this.state.voted !== true ? (
                  <button
                    className="btn btn-dark"
                    disabled={this.setVoteBtnStatus()}
                    onClick={() => this.voteForPoll()}
                  >
                    Vote
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>

            {this.state.voted ? (
              <PollResult
                voted={this.state.voted}
                options={this.state.options}
                pollQuestion={this.state.pollQuestion}
                totalParticipations={this.state.totalParticipations}
              />
            ) : (
              ''
            )}
          </div>
        </section>
      </>
    );
  }
}

export default React.memo(PostPoll);
