import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import AuthService from '../../services/auth.service';
import styles from '../../cssmodules/nav.module.scss';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
    };
  }

  componentDidMount() {
    let username = AuthService.getCurrentUser().name;

    this.setState({
      username: username,
    });
  }

  render() {
    return (
      <div className={styles.navholder}>
        <nav className="navbar sticky-top  flex-md-nowrap p-0">
          <Link to="/dashboard" className={styles.navbarbrand}>
            <img src={require('../../olive-group-logo.png')} alt="Logo" />
          </Link>
          {/* 
          <ul className="navbar-nav px-3">
            <li className="nav-item dropdown no-arrow">

              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                  {this.state.username}
                </span>
                <img
                  className="img-profile rounded-circle"
                  src="https://brownthomas-image.s3.eu-west-1.amazonaws.com/15786399127542.png"
                  alt="Profile"
                ></img>
              </a>

              <div
                className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown"
              >
                <a className="dropdown-item" href="#">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </a>
                <a className="dropdown-item" href="#">
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
                </a>
                <a className="dropdown-item" href="#">
                  <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  data-toggle="modal"
                  data-target="#logoutModal"
                >
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>*/}
        </nav>
      </div>
    );
  }
}
