import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


class GroupsService {
    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));;
      }
      getClientGroups(payload) {
        const user = this.getCurrentUser();
        if(!user) return false; 
        const token = user.accessToken;
        if(!token) return false;
        axios.defaults.headers.common['access-token'] = token;
        return axios
        .get(API_URL + "/clients/"+payload.clientId+"/groups")
        .then(response => {
           
           return response?.data?.data;
        }).catch(error => {
          
          return error;
        });
      }
  getGroups() {
    const user = this.getCurrentUser();
    if(!user) return false; 
    const token = user.accessToken;
    if(!token) return false;
    axios.defaults.headers.common['access-token'] = token;
    return axios
    .get(API_URL + "/groups")
    .then(response => {
       
       return response?.data?.data;
    }).catch(error => {
      
      return error;
    });
  }
  updateGroup(payload) {
    console.log(payload);
    const user = this.getCurrentUser();
    if(!user) return false; 
    const token = user.accessToken;
    if(!token) return false;
    axios.defaults.headers.common['access-token'] = token;
    let body = {
      "organisation" : payload.organisation,
      "name": payload.name
    }
    return axios
    .post(`${API_URL}/groups/${payload.id}/update`,body)
    .then(response => {
       console.log(response);
       return response?.data?.data;
    }).catch(error => {
      
      return error;
    });
  }
  createGroup(payload) {
    console.log(payload);
    const user = this.getCurrentUser();
    if(!user) return false; 
    const token = user.accessToken;
    if(!token) return false;
    axios.defaults.headers.common['access-token'] = token;
    let body = {...payload}
    return axios
    .post(`${API_URL}/groups/create`,body)
    .then(response => {
       console.log(response);
       return response?.data?.data;
    }).catch(error => {
      
      return error;
    });
  }
  deleteGroup(payload) {
    console.log(payload);
    const user = this.getCurrentUser();
    if(!user) return false; 
    const token = user.accessToken;
    if(!token) return false;
    axios.defaults.headers.common['access-token'] = token;
    let body = {
      isDelete : 1
    }
    return axios
    .delete(`${API_URL}/groups/${payload.id}/delete`,{ data: {isDelete : 1}})
    .then(response => {
       console.log(response);
       return response;
    }).catch(error => {
      
      return error;
    });
  }
}

export default new GroupsService();