import React, { PureComponent } from 'react';
import { API } from '../api/API';
import SchedulePost from '../SchedulePost';
import Moment from 'react-moment';
import EachOption from './EachOption';
import 'moment-timezone';
import Select from 'react-dropdown-select';
import ConfirmBox from '../ui/ConfirmBox';
import { Global } from '../api/Global';

class EditPoll extends PureComponent {
  constructor(props) {
    super(props);
    this.pollQuestion = React.createRef();
    this.firstFileUpload = React.createRef();
    this.firstInput = React.createRef();

    this.state = {
      id: '',
      type: 'single',
      schedulePostStatus: false,
      startsAt: null,
      closesAt: null,
      schedulePostTitle: null,
      confirmBoxMsg: '',
      confirmBoxStatus: false,
      tempOption: {
        url: '',
        name: '',
        id: 0,
        order: 1,
      },
      options: [],
      pollResponses: [
        {
          id: 0,
          title: 'Users can select only one response',
          value: 'single',
        },
        {
          id: 1,
          title: 'Users can select multiple response',
          value: 'multiple',
        },
      ],
      selectedPollResponse: [
        {
          id: 0,
          title: 'Users can select only one response',
          value: 'single',
        },
      ],
      isStarted: true,
    };
  }
  setPollItem = (e) => {
    let name = e.currentTarget.textContent.trim();
    if (name.length > 0) {
      if (e.currentTarget.dataset.child === 'first') {
        let tempOption = Object.assign({}, this.state.tempOption);
        tempOption.name = name;
        this.setState({
          tempOption: tempOption,
        });
      }
    }
  };
  setPollResponse(obj) {
    this.changeType(obj[0].value);
  }
  removeImage(item) {
    try {
      let options = [...this.state.options];
      let index = options.indexOf(item);
      delete options[index].url;

      this.setState({
        options: options,
      });
    } catch (error) {
      console.log(error);
    }
  }

  cancelPoll() {
    this.setState({
      confirmBoxMsg: '',
      confirmBoxStatus: false,
    });
  }

  componentDidMount() {
    this.pollQuestion.current.addEventListener('paste', function (e) {
      e.preventDefault();
    });

    let allRepsTxt = document.querySelectorAll('.pollOptions .editableBox');

    let i = 0;
    while (i < allRepsTxt.length) {
      allRepsTxt[i].addEventListener('keyup', (e) => {
        e.target.innerText.length > 0
          ? e.target.classList.add('on')
          : e.target.classList.remove('on');
      });
      i++;
    }

    this.setState({
      id: this.props.id,
      type: this.props.type,
      options: this.props.options,
      pollQuestion: this.props.pollQuestion,
      isStarted: this.props.isStarted ? this.props.isStarted : false,
      closesAt: this.props.closesAt ? this.props.closesAt : null,
      startsAt: this.props.startsAt ? this.props.startsAt : null,
    });
  }
  changeTitle() {
    try {
      let pollQuestion = this.pollQuestion.current.textContent.trim();
      if (pollQuestion.length > 0) {
        this.setState({
          pollQuestion: this.pollQuestion.current.textContent,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  chooseFirstFile() {
    try {
      this.firstFileUpload.current.click();
    } catch (error) {
      console.log(error);
    }
  }

  addOption() {
    try {
      if (
        this.state.tempOption.name &&
        this.state.tempOption.name.trim().length > 0
      ) {
        let max = Math.max(...this.state.options.map((item) => item.order));
        let nextOrder = max + 1;

        let tempOption = Object.assign({}, this.state.tempOption);
        let options = [...this.state.options];
        tempOption.id = Math.floor(Math.random() * 99999);
        tempOption.order = nextOrder;

        this.firstInput.current.textContent = '';
        options.push(tempOption);

        this.setState({
          options: options,
          tempOption: {
            url: '',
            name: '',
            order: null,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    /* Creating Random ID Generator */
  }

  async uploadFile() {
    try {
      let payload = {
        file: this.firstFileUpload.current.files[0],
      };
      await API.fileUpload(payload)
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          let tempOption = Object.assign({}, this.state.tempOption);
          tempOption.url = response.data.data.imageThumbnail[0].url;

          this.setState({
            tempOption: tempOption,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  schedulePostStatus(val, title) {
    try {
      this.setState({
        schedulePostStatus: val,
        schedulePostTitle: title,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async setSchedulePoll(val, trigger) {
    try {
      await this.setState((prevState) => {
        return {
          schedulePostStatus: false,
          closesAt: trigger === 'close' ? new Date(val) : prevState.closesAt,
          startsAt: trigger === 'start' ? new Date(val) : prevState.startsAt,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  removeOption(val) {
    try {
      let options = [...this.state.options];

      let obj = options.find((item) => item.id === val.id);
      let index = options.indexOf(obj);
      options.splice(index, 1);
      this.setState({
        options: options,
      });
    } catch (error) {
      console.log(error);
    }
  }
  changeType(val) {
    try {
      this.setState({
        type: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  editPoll() {
    try {
      let validation = true;
      if (this.state.startsAt == null) {
        this.props.onAlert('Poll Start Schedule is missing');
        validation = false;
      }
      if (this.state.closesAt != null) {
        if (this.state.closesAt < this.state.startsAt) {
          this.props.onAlert(
            'Poll start date must not be greather than poll close dat',
          );
          validation = false;
        }
      }

      let payload = {
        id: this.state.id,
        startsAt: this.state.startsAt,
        closesAt: this.state.closesAt,
        options: this.state.options,
        pollQuestion: this.state.pollQuestion,
        type: this.state.type,
      };

      if (validation) {
        API.editPoll(payload)
          .catch((error) => console.log(error))
          .then((response) => {
            let res = response.data.data;
            this.props.onEditedPoll(res);
          });
      }
    } catch (error) {
      console.log(error);
    }
  }
  editOption(item) {
    let options = [...this.state.options];
    let obj = options.find((obj) => obj.id === item.id);
    let index = options.indexOf(obj);
    options[index] = item;
    this.setState(
      {
        options: options,
      },
      () => {
        console.log('Edited options');
      },
    );
  }
  addImage() {}

  setConfirmBoxStatus(val) {
    this.setState({
      confirmBoxStatus: val,
    });
  }
  showConfirmBox(val, msg) {
    this.setState({
      confirmBoxStatus: val,
      confirmBoxMsg: msg,
    });
  }
  removeDate = (val) => {
    try {
      let newDate = Global.getCurrentUTCDate().getTime();

      if (val === 'start') {
        this.setState({
          startsAt: newDate,
          schedulePostStatus: false,
        });
      } else if (val === 'close') {
        this.setState({
          closesAt: null,
          schedulePostStatus: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      options,
      startsAt,
      closesAt,
      confirmBoxStatus,
      confirmBoxMsg,
    } = this.state;

    return (
      <>
        {confirmBoxStatus ? (
          <ConfirmBox
            msg={confirmBoxMsg}
            onOK={() => this.props.onRemovePoll()}
            onCancel={() => this.cancelPoll()}
          />
        ) : (
          ''
        )}
        <section id="poll" data-started={this.state.isStarted}>
          <div className="pollStart">
            <div className="startendinfo">
              <div className="startAt">
                <span className="lbl">Start at :</span>
                <button
                  className="time"
                  disabled={this.state.schedulePostStatus}
                  onClick={() =>
                    this.schedulePostStatus(true, 'Start Schedule')
                  }
                >
                  <img
                    src="/img/time.svg"
                    alt="Make a schedule"
                    title="Make a schedule"
                  />
                </button>
                {startsAt ? (
                  <Moment format="MMM Do YYYY, h:mm:ss a">{startsAt}</Moment>
                ) : (
                  ''
                )}
              </div>
              <div className="endAt">
                <span className="lbl">End at :</span>
                <button
                  className="time"
                  disabled={this.state.schedulePostStatus}
                  onClick={() => this.schedulePostStatus(true, 'End Schedule')}
                >
                  <img
                    src="/img/time.svg"
                    alt="Make a schedule"
                    title="Make a schedule"
                  />
                </button>
                {closesAt ? (
                  <Moment format="MMM Do YYYY, h:mm:ss a">{closesAt}</Moment>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <button
            className="closePoll"
            onClick={() =>
              this.showConfirmBox(
                true,
                'Are you sure you want to remove poll from current post ?',
              )
            }
          >
            <img src="/img/close.svg" />
          </button>

          {this.state.schedulePostStatus ? (
            <SchedulePost
              onScheduled={(val, trigger) => this.setSchedulePoll(val, trigger)}
              btnTitle="Schedule Poll"
              onAlert={(val) => this.props.onAlert(val)}
              onStatus={(val) => this.setSchedulePoll(val)}
              onRemove={(val) => this.removeDate(val)}
              title={this.state.schedulePostTitle}
            />
          ) : (
            ''
          )}
          <div className="cont">
            <div
              className="pollQuestion"
              ref={this.pollQuestion}
              placeholder="Ask your question"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onInput={() => this.changeTitle()}
            >
              {this.props.pollQuestion}
            </div>
            <div className="pollOptions">
              {this.state.options && this.state.options.length > 0
                ? this.state.options.map((item) => {
                    return (
                      <EachOption
                        key={item.id}
                        item={item}
                        onRemoveImg={(item) => this.removeImage(item)}
                        onLoading={(val) => this.props.onLoading(val)}
                        onEdit={(item) => this.editOption(item)}
                        onRemoveOption={(item) => this.removeOption(item)}
                      />
                    );
                  })
                : ''}

              <div className="addOption option">
                <input
                  type="file"
                  ref={this.firstFileUpload}
                  accept="image/*"
                  onChange={() => this.uploadFile()}
                />
                <button className="addBtn" onClick={() => this.addOption()}>
                  <img
                    width="26"
                    height="26"
                    src="/img/plus.svg"
                    alt="Add Option"
                    title="Add Option"
                  />
                </button>
                <div className="centerBox">
                  <div
                    placeholder="Add a response"
                    className="editableBox"
                    onKeyUp={(e) => this.setPollItem(e)}
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                    data-child="first"
                    ref={this.firstInput}
                  ></div>
                </div>

                {this.state.tempOption.url.length > 0 ? (
                  <div className="userImg">
                    <img
                      src={this.state.tempOption.url}
                      alt="user"
                      title="user"
                      width="69"
                      height="69"
                    />{' '}
                  </div>
                ) : (
                  ''
                )}

                <div className="btns">
                  <button
                    className="addImg"
                    onClick={() => this.chooseFirstFile()}
                  >
                    <img
                      data-child="first"
                      src="/img/image.svg"
                      alt="user"
                      title="user"
                      width="17"
                      height="17"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="pollResponse">
              <div className="label">Poll Responses:</div>
              <Select
                values={this.state.selectedPollResponse}
                onChange={(value) => this.setPollResponse(value)}
                options={this.state.pollResponses}
                labelField="title"
                valueField="id"
                className="selectbox "
              />
            </div>
          </div>
          {
            !this.state.isStarted &&  <footer>
            <button className="btn btn-dark" onClick={() => this.editPoll()}>
              Edit Poll
            </button>
          </footer>
          }
         
        </section>
        {this.state.isStarted && <div className="msg danger">Poll is not editable, either poll has been already started or ended.</div>}
      </>
    );
  }
}

export default React.memo(EditPoll);
