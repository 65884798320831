import React, { PureComponent } from 'react';
import CommentForm from './CommentForm';
import PropTypes from 'prop-types';
import EachComment from './EachComment';
import { API } from '../api/API';
import { Global } from '../api/Global';
import ConfirmBox from '../ui/ConfirmBox';

class CommentBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remainComments: 0,
      comments: [],
      meta: null,
      newsFeedClientID: null,
      limitShow: 5,
      showConfirmBox: false,
      currentCommentId: null,
      msg: '',
    };
  }
  propsToState() {
    this.setState({
      remainComments: this.props.meta
        ? this.props.meta.total - this.props.meta.perPage
        : 0,
      comments: this.props.comments,
      meta: this.props.meta ? this.props.meta : null,
      newsFeedClientID: this.props.newsFeedClientID,
    });
  }
  /* *********************************************************************** */
  /*                   Event Listening Add Comment                           */
  /* *********************************************************************** */
  onComment() {
    try {
      Global.socket.on('comment', async (response) => {
        let socketResponse = Object.assign({}, response);
        // Check to match newsFeedClient ID
        if (
          socketResponse.data.newsFeedClient === this.state.newsFeedClientID
        ) {
          let comments = [...this.state.comments];

          // New Comment Register
          let comment = socketResponse.data;

          // Refine Data
          if (comment.message) {
            delete comment.message;
          }

          comments.unshift(comment);

          if (comments.length > this.state.limitShow) {
            comments.pop();
          }

          this.setState((prevState) => {
            return {
              comments: comments,
              remainComments: prevState.remainComments + 1,
            };
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  /* *********************************************************************** */
  /*                   Event Listening Delete Comment                        */
  /* *********************************************************************** */
  onDeleteComment() {
    try {
      Global.socket.on('deleteComment', async (response) => {
        if (response && response.message === 'success') {
          if (response.data.newsFeedClient === this.state.newsFeedClientID) {
            let comments = [...this.state.comments];

            let obj = comments.find((item) => item._id === response.data._id);
            let index = comments.indexOf(obj);
            comments.splice(index, 1);
            this.setState({
              comments: comments,
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    this.propsToState();
    if (Global.socket) {
      this.onComment();
      this.onDeleteComment();
    }
  }

  removeComment = async () => {
    try {
      // deleteComment
      let obj = {
        id: this.state.currentCommentId,
        urlParams: Global.urlParams,
      };
      await this.setState(
        {
          showConfirmBox: false,
          msg: '',
        },
        () => {
          if (Global.socket) {
            Global.socket.emit('deleteComment', obj);
          }
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  async loadMoreComments() {
    try {
      let meta = Object.assign({}, this.state.meta);
      meta.currentPage += 1;
      let remainComments = meta.total - meta.currentPage * meta.perPage;

      let payload = {
        id: this.state.newsFeedClientID,
        currentPage: meta.currentPage,
      };
      this.props.onLoading(true);
      await API.getComments(payload)
        .catch((error) => console.log(error))
        .then((response) => {
          let comments = [...this.state.comments];

          let i = 0;
          while (i < response.data.data.length) {
            comments.push(response.data.data[i]);
            i++;
          }

          this.setState(
            (prevState) => {
              return {
                meta: meta,
                remainComments: remainComments,
                comments: comments,
                limitShow: comments.length,
              };
            },
            () => {
              this.props.onLoading(false);
            },
          );
        });
    } catch (error) {
      console.log(error);
      this.props.onLoading(false);
    }
  }
  async showComfirmBox(data) {
    await this.setState({
      showConfirmBox: true,
      currentCommentId: data,
      msg: 'Are you sure you want to delete comment ?',
    });
  }
  async addComment(data) {
    try {
      var comments = [...this.state.comments];
      comments.unshift(data);

      let meta = Object.assign({}, this.state.meta);
      meta.total = meta.total + 1;
      await this.setState((prevState) => {
        return { comments: comments, meta: meta };
      });
      this.props.onLoading(false);
      this.props.onAddComment();
    } catch (error) {
      console.log(error);
    }
  }
  cancelRemoveComment() {
    this.setState({
      showConfirmBox: false,
      msg: '',
    });
  }
  displayViewMoreCommentBar() {
    if (this.state.remainComments && this.state.meta) {
      if (
        this.state.remainComments > 0 &&
        this.state.meta.currentPage < this.state.meta.pages
      ) {
        return (
          <div className="all" onClick={() => this.loadMoreComments()}>
            <span className="moreComments">
              View
              {this.state.remainComments < this.state.meta.perPage ? (
                <> {this.state.remainComments} </>
              ) : (
                <> {this.state.meta.perPage} </>
              )}
              more comments
            </span>
          </div>
        );
      }
    }
  }
  render() {
    var { newsFeedClientID, meta, remainComments } = this.state;

    return (
      <>
        {this.state.showConfirmBox ? (
          <ConfirmBox
            msg={this.state.msg}
            onOK={() => this.removeComment()}
            onCancel={() => this.cancelRemoveComment()}
          />
        ) : (
          ''
        )}

        <div className="commentBox">
          {this.displayViewMoreCommentBar()}

          {this.state.comments.length > 0
            ? this.state.comments.map((item, index) => {
                return index < this.state.limitShow ? (
                  <EachComment
                    newsFeedClientID={newsFeedClientID}
                    data={item}
                    key={item._id}
                    commentID={item._id}
                    totalLiked={item.totalLikes}
                    likedByUser={item.likedByUser === 1}
                    totalReplies={item.totalReplies}
                    onLoading={(val) => this.props.onLoading(val)}
                    onAlert={(data) => this.props.onAlert(data)}
                    onRemoveComment={(val) => this.showComfirmBox(val)}
                    onConfirm={(val) => this.props.onConfirm(val)}
                  />
                ) : (
                  ''
                );
              })
            : ''}
          {newsFeedClientID ? (
            <CommentForm
              newsFeedClientID={newsFeedClientID}
              onLoading={(val) => this.props.onLoading(val)}
              onAddComment={(data) => this.addComment(data)}
              onAlert={(data) => this.props.onAlert(data)}
            />
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

CommentBox.propTypes = {
  meta: PropTypes.object,
  comments: PropTypes.array,
  newsFeedClientID: PropTypes.string.isRequired,
  onAddComment: PropTypes.func,
  onAlert: PropTypes.func,
  onLoading: PropTypes.func,
  totalLikes: PropTypes.number,
};

export default React.memo(CommentBox);
