import axios from 'axios';

export const Comment = {
  edit: async function (payload) {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/comment/' +
          payload.id +
          '/update?' +
          window.location.href.split('?')[1],

        data: {
          commentDescription: payload.msg,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },

  editReply: async function (payload) {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/reply/' +
          payload.id +
          '/update?' +
          window.location.href.split('?')[1],

        data: {
          replyDescription: payload.msg,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  remove: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/comment/' +
          payload.commentId +
          '/delete?' +
          window.location.href.split('?')[1],

        data: {
          newsFeedClientId: payload.newsFeedClientID,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
  removeReply: async (payload) => {
    try {
      return await axios({
        method: 'post',
        url:
          process.env.REACT_APP_API_URL +
          '/thirdparty/news-feed/reply/' +
          payload.id +
          '/delete?' +
          window.location.href.split('?')[1],

        data: {
          newsFeedClientId: payload.newsFeedClientID,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .catch((error) => error)
        .then((response) => response);
    } catch (error) {
      console.log(error);
    }
  },
};
