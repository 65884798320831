import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ConfirmBox from '../ui/ConfirmBox';

class ReplyOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.commentBox = React.createRef();
    this.sendBtn = React.createRef();
    this.state = {
      id: null,
      confirmMsg: null,
      confirmMsgBoxStatus: false,
    };
  }
  componentDidMount() {
    this.setState({
      id: this.props.id,
    });
  }
  showConfirmMsgBox() {
    this.setState({
      msg: 'Are you sure you want to remove reply comment ?',
      confirmMsgBoxStatus: true,
    });
  }
  removeReplyComment(id) {
    this.props.onRemove(id);
    this.setState({
      msg: '',
      confirmMsgBoxStatus: false,
    });
  }
  cancleRemoveReplyComment() {
    this.setState({
      msg: '',
      confirmMsgBoxStatus: false,
    });
  }
  render() {
    const { id, confirmMsgBoxStatus } = this.state;
    return (
      <>
        {confirmMsgBoxStatus ? (
          <ConfirmBox
            msg="Are you sure you want to remove reply comment ?"
            onOK={() => this.removeReplyComment(id)}
            onCancel={() => this.cancleRemoveReplyComment()}
          />
        ) : (
          ''
        )}

        <div className="optionsPop">
          <h4>Reply Options</h4>
          <div className="item">
            <button
              className="linkonly"
              onClick={() => this.showConfirmMsgBox()}
            >
              Remove Reply
            </button>
          </div>
          {/* <div className="item">
            <button
              className="linkonly"
              onClick={() => this.props.onEdit(true)}
            >
              Edit Reply{' '}
            </button>
          </div> */}
        </div>
      </>
    );
  }
}

ReplyOptions.propTypes = {
  id: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default React.memo(ReplyOptions);
