import React, { PureComponent } from 'react';
class AlterPostFooter extends PureComponent {
  constructor(props) {
    super(props);
    this.imageFile = React.createRef();
    this.videoFile = React.createRef();
    this.attachmentFile = React.createRef();

    this.state = {
      mode: null,
      isSchedule: false,
      title: null,
      publishLaterDate: null,
      hasPoll: false,
    };
  }

  addVideoFile() {
    try {
      this.videoFile.current.click();
    } catch (error) {
      console.log(error);
    }
  }

  addAttachmentFile() {
    try {
      this.attachmentFile.current.click();
    } catch (error) {
      console.log(error);
    }
  }

  addImageInput = () => {};
  convertTwelveHours(val) {
    let hrs = parseInt(val);
    if (hrs == 0 || hrs == 12) {
      hrs = 12;
    } else if (hrs > 12) {
      hrs = hrs - 12;
    } else {
      hrs = hrs;
    }
    return hrs;
  }
  setAMPM(val) {
    let ampm = null;
    if (val == 0) {
      ampm = 'PM';
    } else if (val == 12) {
      ampm = 'AM';
    } else if (val > 0 && val < 12) {
      ampm = 'AM';
    } else if (val > 12) {
      ampm = 'PM';
    }
    return ampm;
  }
  showMinutes(val) {
    try {
      if (val < 10) {
        return '0' + val;
      } else if (val === 0) {
        return '00';
      } else {
        return val;
      }
    } catch (error) {
      console.error(error);
    }
  }
  showLocalDate(mdate) {
    try {
      console.log(mdate);
      let hours = mdate.getHours();

      let minutes = null;

      let da = mdate;
      let localMinute = da.getMinutes();
      let utcMinute = da.getUTCMinutes();
      minutes = Math.abs((localMinute - utcMinute + 30) % 60);

      let val = mdate.toString();

      var splitDate = val.split(' ');
      return (
        <span className="datedisplay">
          <span>Your users will start seeing the news feed from </span>
          <span>{splitDate[0]} ,</span> <span> {splitDate[1]} </span>{' '}
          <span> {splitDate[2]} </span>
          <span> </span>
          <span> at</span> {this.convertTwelveHours(hours)} :{' '}
          {this.showMinutes(mdate.getMinutes())}
          {
            <>
              <span> {this.setAMPM(hours)}</span>
            </>
          }
        </span>
      );
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate() {
    this.setState({
      title: this.props.title ? this.props.title : null,
      publishLaterDate: this.props.publishLaterDate
        ? this.props.publishLaterDate
        : null,
    });
  }
  componentDidMount() {
    this.setState({
      mode: this.props.mode ? this.props.mode : null,
      isSchedule: this.props.isSchedule ? this.props.isSchedule : false,
      title: this.props.title ? this.props.title : null,
      hasPoll: this.props.hasPoll ? this.props.hasPoll : false,
      publishLaterDate: this.props.publishLaterDate
        ? this.props.publishLaterDate
        : null,
    });
  }
  addImageFile() {
    try {
      this.imageFile.current.click();
    } catch (error) {
      console.log(error);
    }
  }
  addVideoFile() {
    try {
      this.videoFile.current.click();
    } catch (error) {
      console.log(error);
    }
  }

  addAttachmentFile() {
    try {
      this.attachmentFile.current.click();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { mode, isSchedule, title, publishLaterDate, hasPoll } = this.state;
    return (
      <footer className="footer">
        <ul className="leftNav">
          {!this.props.showImage && (
            <li>
              <button
                className="link"
                onClick={() => {
                  this.addImageFile();
                }}
              >
                <img
                  src="/img/image1.svg"
                  alt="Upload files"
                  title="Upload files"
                />
              </button>
              <input
                accept="image/*"
                className="hideMe"
                onChange={() => this.props.onAddToCarousel(this.imageFile)}
                type="file"
                data-type="image"
                ref={this.imageFile}
                multiple
              />
            </li>
          )}
          {!this.props.showVideo && (
            <li>
              <button
                className="link"
                onClick={() => {
                  this.addVideoFile();
                }}
              >
                <img
                  src="/img/play-dark.svg"
                  alt="Upload Video files"
                  title="Upload Video files"
                />
              </button>
              <input
                accept="video/mp4,video/webm,video/ogg,audio/*"
                type="file"
                ref={this.videoFile}
                data-type="video"
                multiple
                onChange={() => this.props.onAddToCarousel(this.videoFile)}
              />
            </li>
          )}

          <li>
            <button
              className="link"
              onClick={() => {
                this.addAttachmentFile();
              }}
            >
              <img
                src="/img/attachment.svg"
                alt="Attached File"
                title="Attached File"
                width="25"
                height="25"
              />
            </button>
            <input
              accept="application/pdf"
              type="file"
              ref={this.attachmentFile}
              data-type="attachment"
              multiple
              onChange={() => this.props.onAddToCarousel(this.attachmentFile)}
            />
          </li>
          {mode === 'create' ? (
            <li className={mode}>
              <button
                className="link"
                onClick={() => {
                  this.props.onSetPollFormStatus(true);
                }}
              >
                <img
                  src="/img/poll.svg"
                  alt="Poll"
                  title="Poll "
                  width="25"
                  height="25"
                />
              </button>
            </li>
          ) : mode === 'edit' && hasPoll ? (
            <li className="editSchedule">
              <button
                className="link"
                onClick={() => {
                  this.props.onSetPollFormStatus(true);
                }}
              >
                <img
                  src="/img/poll.svg"
                  alt="Poll"
                  title="Poll "
                  width="25"
                  height="25"
                />
              </button>
            </li>
          ) : (
            ''
          )}

          {/*
                   <li>
                    <button className="link">
                      <img
                        src="/img/tag.svg"
                        alt="Share to selected Users"showLocalDate
                        title="Share to selected Users"
                      />
                    </button>
                  </li>
                  */}
        </ul>

        <ul className="rightNav">
          {mode === 'create' || isSchedule === true ? (
            <li>
              {this.props.publishLaterDate
                ? this.showLocalDate(this.props.publishLaterDate)
                : ''}

              <button
                className="link"
                onClick={() => {
                  this.props.onShowSchedulePanel();
                }}
              >
                <img
                  src="/img/time.svg"
                  alt="Make a schedule"
                  title="Make a schedule"
                  width="28"
                  height="28"
                />
              </button>
            </li>
          ) : (
            ''
          )}

          <li>
            {mode === 'create' ? (
              <button
                className="postNow"
                onClick={() => this.props.onCreatePost()}
                disabled={title == null || title.length == 0 ? 'disabled' : ''}
              >
                {publishLaterDate ? 'Schedule Post' : 'Post Now'}
              </button>
            ) : (
              <>
                <button
                  className="btn btn-primary"
                  onClick={() => this.props.onUpdatePost()}
                >
                  Update Post
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => this.props.onChangeMode(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </li>
        </ul>
      </footer>
    );
  }
}
export default React.memo(AlterPostFooter);
