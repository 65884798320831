import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PropTypes from 'prop-types';

class EditReplyForm extends PureComponent {
  constructor(props) {
    super(props);
    this.replyBox = React.createRef();
    this.sendBtn = React.createRef();
    this.state = {
      id: null,
      msg: null,
    };
  }
  componentDidMount() {
    try {
      this.setState({
        id: this.props.id,
        msg: this.props.msg,
      });
    } catch (error) {
      console.log(error);
    }
  }

  setMessage() {
    try {
      this.setState({
        msg: this.replyBox.current.innerText,
      });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { id, msg } = this.state;
    return (
      <div className="commentForm">
        <div className="leftSide">
          <div className="img"></div>
          <div
            ref={this.replyBox}
            className="comment"
            placeholder="Write your reply here"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onInput={() => this.setMessage()}
          >
            {this.props.msg}
          </div>
        </div>
        <div className="rightSide">
          <button
            onClick={() => this.props.onEditReply({ id: id, msg: msg })}
            className="sendComment btn btn-dark"
            disabled={msg && msg.length > 0 ? '' : 'disabled'}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
}

export default React.memo(EditReplyForm);
