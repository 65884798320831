import React, { PureComponent } from 'react';
import { Global } from '../api/Global';
import { API } from '../api/API';
import PropTypes from 'prop-types';
class CommentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.commentBox = React.createRef();
    this.sendBtn = React.createRef();
    this.state = {
      newsFeedClientID: '',
      message: '',
      userInfo: null,
    };
  }
  componentDidMount() {
    /* User Info API */
    API.userInfo()
      .then((response) => {
        console.log('Checking user info');
        console.log(response);
        let userInfo = {
          name: response.name ? response.name : 'Olive',
          profilePic: response.profilePic
            ? response.profilePic
            : '/img/logo.png',
          orgIcon: response.orgIcon ? response.orgIcon : '/img/logo.png',
          orgName: response.orgName ? response.orgName : 'Olive',
        };
        this.setState({
          userInfo: userInfo,
        });
      })
      .catch((error) => console.log(error));

    this.commentBox.current.addEventListener('paste', function (e) {
      e.preventDefault();
    });

    this.commentBox.current.addEventListener('keyup', (e) => {
      this.sendBtn.current.disabled = !e.target.innerText.length > 0;
    });

    this.setState({
      newsFeedClientID: this.props.newsFeedClientID,
    });
  }
  setMessage() {
    this.setState({
      message: this.commentBox.current.innerText,
    });
  }
  postComment() {
    this.setState({
      message: '',
    });
    this.commentBox.current.innerText = '';
    let obj = {
      newsFeedClientId: this.state.newsFeedClientID,
      commentDescription: this.state.message.trim(),
      urlParams: window.location.href.split('?')[1],
    };
    this.props.onLoading(true);
    if (this.state.message.trim().length == 0) {
      this.props.onAlert('Message is empty');
      this.props.onLoading(false);
    } else {
      if (Global.socket) {
        Global.socket.emit('comment', obj);
      }
    }
  }
  render() {
    const { userInfo, message } = this.state;
    return (
      <div className="commentForm">
        <div className="leftSide">
          <div className="img">
            {this.state.userInfo ? (
              <img
                src={userInfo.profilePic}
                width="30"
                height="30"
                alt={userInfo.organisationName || ''}
              />
            ) : (
              <img src="/img/logo.png" width="30" height="30" alt="Olive" />
            )}
          </div>
          <div
            ref={this.commentBox}
            onInput={() => this.setMessage()}
            className="comment"
            placeholder="Comment here..."
            contentEditable="true"
            suppressContentEditableWarning={true}
          ></div>
        </div>
        <div className="rightSide">
          <button
            ref={this.sendBtn}
            className="sendComment btn btn-primary"
            onClick={() => this.postComment()}
            disabled={message.length > 0 ? '' : 'disabled'}
          >
            <img className="xs-only" src="/img/enter.svg" alt="Comment" />
            <span>Comment</span>
          </button>
        </div>
      </div>
    );
  }
}

CommentForm.propTypes = {
  newsFeedClientID: PropTypes.string.isRequired,
  onAddComment: PropTypes.func,
  onAlert: PropTypes.func,
  onLoading: PropTypes.func,
};

export default React.memo(CommentForm);
