import React, { PureComponent } from 'react';
import { BlockMapBuilder } from 'draft-js';

class PollResult extends PureComponent {
  constructor(props) {
    try {
      super(props);

      this.state = {
        pollQuestion: '',
        options: [],
        totalParticipations: 0,
      };
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    try {
      this.setState({
        pollQuestion: this.props.pollQuestion,
        options: this.props.options,
        totalParticipations: this.props.totalParticipations,
      });
    } catch (error) {
      console.log(error);
    }
  }

  setPercentage(numberOfVotes, totalParticipations) {
    try {
      return {
        width:
          Number((numberOfVotes * 100) / totalParticipations)
            .toFixed(2)
            .toString() + '%',
        display: 'block',
      };
    } catch (error) {
      console(error);
    }
  }
  render() {
    const { pollQuestion, options, totalParticipations } = this.state;
    return (
      <div className="pollResult displayPoll">
        <div className="cont">
          <div className="pollQuestion">
            <div className="header">
              <h4 className="title">Poll Result</h4>

              <div className="rightSide">
                <div className="totalNo">
                  <img src="/img/tag.svg" width="15" height="15" alt="tag" />
                  <strong>{totalParticipations}</strong>
                </div>
              </div>
            </div>

            <h4 className="ques">{pollQuestion}</h4>

            <div className="pollOptions">
              {options && options.length > 0
                ? options.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="graphHolder">
                          <div
                            className="graphBar"
                            style={this.setPercentage(
                              item.numberOfVotes,
                              totalParticipations,
                            )}
                          ></div>
                        </div>
                        <div className="option" key={item['_id']}>
                          {' '}
                          <div className="leftSide">
                            <div className="tot">{item.numberOfVotes}</div>
                            <div className="name">{item.optionName}</div>
                          </div>
                          <div className="userImg">
                            {item.optionThumbnail ? (
                              <img
                                src={item.optionThumbnail}
                                width="100"
                                height="100"
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(PollResult);
