import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React from 'react';
export default function ConfirmBox(props) {
  const { onCancel, onOK, msg,title } = props;
  return (
    <Modal isOpen={true} className="modal">
      {
        title && <ModalHeader>{title ? title : ''}</ModalHeader>
      }
      

      <ModalBody>
        <div className="text-center">
          {msg ? msg : 'Are you sure you want to delete ?'}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="form-group">
          <Button color="primary" onClick={() => onOK()}>
            <i className="fa fa-check" aria-hidden="true"></i>Ok
          </Button>
          <Button color="secondary" onClick={() => onCancel()}>
            <i class="fa fa-close" aria-hidden="true"></i>Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
