import React, { PureComponent } from 'react';
import styles from '../cssmodules/adminslider.module.scss';
class AdminSlider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      medias: [],
      mediaType: null,
    };
  }

  componentDidMount() {
    try {
      this.setState(
        {
          index: this.props.index ? this.props.index : 0,
          medias: this.props.medias ? this.props.medias : [],
          mediaType: this.props.medias[this.props.index].imageUrl
            ? 'image'
            : this.props.medias[this.props.index].videoUrl
            ? 'video'
            : this.props.medias[this.props.index].attachmentUrl
            ? 'attachment'
            : '',
        },
        () => {
          console.log(this.state.medias[0]);
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  increment() {
    console.log('Incfement');
    try {
      this.setState((prevState) => {
        return {
          index: prevState.index + 1,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }
  decrement() {
    console.log('Incfement');
    try {
      this.setState((prevState) => {
        return {
          index: prevState.index - 1,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { index, medias, mediaType } = this.state;
    return (
      <div className={styles.slider}>
        {index != 0 ? (
          <button className={styles.leftBtn} onClick={() => this.decrement()}>
            <img src="/img/white-down-arrow.svg" />
          </button>
        ) : (
          ''
        )}
        {index < medias.length - 1 ? (
          <button className={styles.rightBtn} onClick={() => this.increment()}>
            <img src="/img/white-down-arrow.svg" />
          </button>
        ) : (
          ''
        )}

        {medias.length > 0 && medias[index].imageUrl ? (
          <>
            <div className={styles.top}>
              <button
                className={styles.closeBtn}
                onClick={() => this.props.onClose()}
              >
                <img src="/img/closewhite.svg" width="35" height="35" />
              </button>
            </div>
            <img
              className={styles.object}
              src={medias[index].imageUrl}
              alt={medias[index].name}
            />
          </>
        ) : (
          ''
        )}
        {medias.length > 0 && medias[index].videoUrl ? (
          <>
            <button className={styles.closeBtn}>
              <img src="/img/closewhite.svg" width="35" height="35" />
            </button>
            <video
              className={styles.videoobject}
              width="320"
              height="240"
              controls
            >
              <source src={medias[index].videoUrl} type="video/mp4" />
            </video>
          </>
        ) : (
          ''
        )}
        {medias.length > 0 && medias[index].attachmentUrl ? (
          <>
            <div className={styles.top}>
              <button
                className={styles.closeBtn}
                onClick={() => this.props.onClose()}
              >
                <img src="/img/closewhite.svg" width="35" height="35" />
              </button>
            </div>
            {medias[index].attachmentUrl.slice(-3) === 'pdf' ? (
              <object
                className={styles.attachmentObject}
                data={medias[index].attachmentUrl + '#view=FitH'}
                type="application/pdf"
                width="300"
                height="200"
              ></object>
            ) : (
              <i className="fa fa-5x fa-file-o"></i>
            )}

            <button className={styles.downloadBtn}>Download</button>
          </>
        ) : (
          ''
        )}

        {medias.length > 0 ? (
          <div className={styles.bottom}>{medias[index].name}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default React.memo(AdminSlider);
