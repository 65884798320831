import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/*

Props
=======
msg : String | required

Events
=======
onOK : Clicked on OK Button



*/
class AlertBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      msg: 'Default Confirm Title',
    };
  }

  componentDidMount() {
    try {
      this.setState({
        msg: this.props.msg,
      });
     
      document.body.style.overflow="hidden";
    } catch (error) {
      console.log(error);
    }
  }
  ok(){
    document.body.style.overflow="auto";
    this.props.onOK()
  }
  render() {
    return (
      <section className="confirmBox">
        <div className="box">
          <header>
            <h2>{this.state.msg}</h2>
          </header>
          <div className="actionBtn">
            <button className="btn btn-dark" onClick={()=>this.ok()}>
              OK
            </button>
          </div>
        </div>
      </section>
    );
  }
}

AlertBox.propTypes = {
  msg: PropTypes.string.isRequired,
  onOK: PropTypes.func.isRequired,
};

export default React.memo(AlertBox);
