import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class TenantService {
  getAll(clientId) {
    return axios
      .get(API_URL + '/clients/' + clientId + '/tenants')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  create(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('icon', payload.icon);
    bodyFormData.append('name', payload.name);
    return axios({
      method: 'post',
      url: API_URL + '/clients/' + payload.clientId + '/tenant/create',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: bodyFormData,
    }).then((response) => {
      return response.data.data;
    });
    /*

    return axios
      .post(API_URL + '/clients/' + clientId + '/tenant/create', {
        name,
      })
      .then((response) => {
        return response.data.data;
      });
      */
  }

  update(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('icon', payload.icon);
    bodyFormData.append('name', payload.name);
    bodyFormData.append('id', payload.id);
    return axios({
      method: 'post',
      url: API_URL + '/tenant/update',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: bodyFormData,
    }).then((response) => {
      return response.data.data;
    });

    /*
    return axios
      .post(API_URL + '/tenant/update', {
        id,
        name,
      })
      .then((response) => {
        return response.data.data;
      });
*/
  }

  delete(id) {
    return axios
      .get(API_URL + '/tenants/' + id + '/delete')
      .then((response) => {
        return response.data.data;
      });
  }

  undelete(id) {
    return axios
      .get(API_URL + '/tenants/' + id + '/undelete')
      .then((response) => {
        return response.data.data;
      });
  }

  getAllDeleted(clientId) {
    return axios
      .get(API_URL + '/client/' + clientId + '/deleted-tenants')
      .then((response) => {
        return response.data;
      });
  }

  getAllValid(clientId) {
    return axios
      .get(API_URL + '/clients/' + clientId + '/tenants/active-non-deleted')
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getAllMyKademyValid(keyword){
    return axios
      .get(`https://accounts.uk.mykademy.com/api/v1/accounts?page_limit=10&page=1&search_keyword=${keyword}&status=1`,{headers: {
        Authorization: `Bearer 77f718c324c637f2165d5c6811bc78ab1a32fd65`,
      }})
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default new TenantService();
