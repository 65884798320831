import React from 'react';
export default function ClientList(props) {
  const { clients, onEdit, onRemove } = props;
  const editHandler = (data) => {
    console.log(data);
    props.onEdit(data);
  };
  return (
    <div className="holder">
      <div className="header">
        <h3>Client List </h3>
        {clients.length > 10 ? (
          <button className="viewAllBtn"> View All</button>
        ) : (
          ''
        )}
      </div>
      <ul className="listgroup">
        {clients.map((client, index) => (
          <li className={`listgroupitem`} key={client._id}>
            <div className="clienticon">
              <img src={require('../user.svg')} alt="Client" />
            </div>

            <div className="clientinfo">
              <h5>
                {client.client.name} <span>ID : {client.client._id}</span>
              </h5>
              <div className="actionBtns">
                <button
                  className="iconBtn"
                  onClick={() =>
                    editHandler({
                      index,
                      id: client.client._id,
                      clientId: client._id,
                      clientName: client.client.name,
                    })
                  }
                >
                 
                  <img src="/img/pencil.svg" width="16" height="16" />
                </button>
                <button
                  className="iconBtn"
                  onClick={() =>
                    onRemove({id:client.client._id, name:client.client.name})
                  }
                >
                  {/*
                 onClick={() =>
                  this.toggleModal(
                    client.client._id,
                    client.client.name,
                  )
                }
                */}
                  <img src="/img/grayclose.svg" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
