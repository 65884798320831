import React, { PureComponent } from 'react';

class ShortInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalLikes: 0,
      likedByUser: null,
    };
  }
  async componentDidUpdate() {
    try {
      await this.setState({
        likedByUser: this.props.likedByUser,
        totalLikes: this.props.totalLikes,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    try {
      await this.setState({
        likedByUser: this.props.likedByUser,
        totalLikes: this.props.totalLikes,
      });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { totalLikes, likedByUser } = this.props;

    return (
      <>
        {totalLikes > 0 ? (
          <div className="shortInfo">
            <div className="icons">
              <img src="/img/thumbs-active-small.svg" width="18" height="18" />
            </div>
            <div className="tinyinfo">
              {totalLikes > 1 ? (
                <>
                  {likedByUser ? <span> You and </span> : ''}
                  <strong>{likedByUser ? totalLikes - 1 : totalLikes}</strong>
                  {likedByUser ? ' other ' : ' others '}
                </>
              ) : likedByUser ? (
                ' You '
              ) : (
                ' 1 '
              )}
              reacted to this
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default React.memo(ShortInfo);
