import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PropTypes from 'prop-types';
class EachOption extends PureComponent {
  constructor(props) {
    super(props);

    this.firstFileUpload = React.createRef();
    this.firstInput = React.createRef();

    this.state = {
      item: {},
    };
  }
  componentDidMount() {
    this.setState({
      item: this.props.item,
    });
  }
  chooseFirstFile() {
    try {
      this.firstFileUpload.current.click();
    } catch (error) {
      console.log(error);
    }
  }
  async uploadFile() {
    try {
      let payload = {
        file: this.firstFileUpload.current.files[0],
      };
      this.props.onLoading(true);
      await API.fileUpload(payload)
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          this.props.onLoading(false);
          let item = Object.assign({}, this.state.item);
          item.url = response.data.data.imageThumbnail[0].url;

          this.setState(
            {
              item: item,
            },
            () => {
              this.props.onEdit(this.state.item);
            },
          );
        });
    } catch (error) {
      this.props.onLoading(false);
      console.log(error);
    }
  }
  addImg() {
    console.log('Adding Image ....');
  }
  render() {
    const { item } = this.state;
    return (
      <div className=" option" key={item.id}>
        <div className="ball"></div>

        <div className="centerBox">
          <div className="editableBox on">{item.name}</div>
        </div>
        {item.url && item.url.length > 0 ? (
          <div className="userImg">
            <img
              src={item.url}
              alt="user"
              title="user"
              width="69"
              height="69"
            />
          </div>
        ) : (
          ''
        )}

        <div className="btns">
          {item.url && item.url.length > 0 ? (
            <button
              className="removeImg"
              onClick={() => this.props.onRemoveImg(item)}
            >
              <img
                src="/img/image-remove.svg"
                alt="user"
                title="user"
                width="17"
                height="17"
              />
            </button>
          ) : (
            <>
              <button className="addImg" onClick={() => this.chooseFirstFile()}>
                <img
                  src="/img/image.svg"
                  alt="user"
                  title="user"
                  width="17"
                  height="17"
                />
              </button>
              <input
                type="file"
                ref={this.firstFileUpload}
                accept="image/*"
                onChange={() => this.uploadFile()}
              />
            </>
          )}
          <button onClick={() => this.props.onRemoveOption(item)}>
            <img src="/img/trash.svg" alt="Close" title="Close" />
          </button>
        </div>
      </div>
    );
  }
}
export default React.memo(EachOption);
