import React, { Component } from 'react';

import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import ClientService from '../services/client.service';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';

import pageHeader from '../cssmodules/pageheader.module.scss';

export default class DeletedClient extends Component {
  constructor(props) {
    super(props);

    this.getParticularClient = this.getParticularClient.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.restoreClient = this.restoreClient.bind(this);

    this.changeStatus = this.changeStatus.bind(this);
    this.state = {
      clients: [],
      activeIndex: 0,
      particularClient: {},
      loading: true,

      deleteLoading: false,
      showModal: false,

      errmessage: '',
      successmessage: '',
    };
  }

  async componentDidMount() {
    let allCLients = await ClientService.getAllDeleted();

    this.setState({
      clients: allCLients,
      particularClient: allCLients[0] ? allCLients[0] : {},

      loading: false,
    });
  }

  getParticularClient(index) {
    this.setState({
      activeIndex: index,
      particularClient: this.state.clients[index],
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
      deleteLoading: false,

      errmessage: '',
    });
  }

  fadeOut() {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      2500,
    );
  }

  changeStatus(e) {
    if (e.target.value === 'active') {
      this.props.history.push('/clients');
    }
  }

  restoreClient() {
    this.setState({
      deleteLoading: true,
    });

    ClientService.undelete(this.state.particularClient._id).then(
      (response) => {
        let client = [...this.state.clients];
        let clientFiltered = client.filter(
          (client) => client._id !== this.state.particularClient._id,
        );
        this.setState({
          successmessage: `client "${this.state.particularClient.name}" restored successfully`,
          deleteLoading: false,
          clients: clientFiltered,
          showModal: !this.state.showModal,
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          createLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  render() {
    const { clients, activeIndex, particularClient, loading } = this.state;

    return (
      <div>
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>Deleted Clients</h1>
                <div className={pageHeader.rightSide}>
                  <select
                    name="status"
                    className="form-control status"
                    onChange={this.changeStatus}
                  >
                    <option value="active">Active</option>
                    <option value="deleted" selected>
                      Deleted
                    </option>
                  </select>
                </div>
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className=" row">
                {clients.length > 0 ? (
                  clients.map((client, index) => (
                    <>
                      <div
                        className="col-sm-4 col-md-3 col-xs-12 "
                        onClick={() => this.getParticularClient(index)}
                      >
                        <div className="listbox">
                          <div className="clienticon">
                            <img src={require('../user.svg')} alt="Client" />
                          </div>
                          <div className="clientinfo">
                            <h5> {client.name}</h5>
                            <div className="actionBtns">
                              {' '}
                              <button
                                className="iconBtn"
                                onClick={() => this.toggleModal()}
                              >
                                <i className="fa fa-undo"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div className="col-sm-12">
                    <div class="alert alert-warning" role="alert">
                      No any client is deleted
                    </div>
                  </div>
                )}
              </div>

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Restore Client </ModalHeader>

                <ModalBody>
                  Are you sure to restore the client "
                  {this.state.particularClient.name}"
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.deleteLoading}
                      onClick={this.restoreClient}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.deleteLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleModal}>
                      <i class="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
