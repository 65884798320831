import React, { useEffect, useState } from 'react';
import { Loader } from 'react-bootstrap-typeahead';
import ConfirmBox from './ConfirmBox';
export default function GroupNameList(props) {
  const { data, loading, onLoadGroup, onDelete } = props;
  const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);
  const [id, setId] = useState(null);
  const [selectedId,setSelectedId] = useState(null);
  const confirmBoxHandler = (obj) => {
    setConfirmBoxStatus(true);    
    setId(obj._id);
  };
  const loadGroupHandler = (e)=>{  
    let myid = e.currentTarget.dataset.id;
    setSelectedId(myid);
    onLoadGroup(e)   
  }
  const deleteHandler = () => {
    setConfirmBoxStatus(false);
    onDelete(id);
  };
  // Initialize selected ID
  useEffect(()=>{   
    setSelectedId(data[0]._id);
  },[])
  return (
    <>
      <div className="holder">
        <div className="header">
          <h3>Group Name List</h3>
        </div>
       
        {loading && <div className="ml-3"><Loader /></div>}
        {data.length > 0 && loading === false && (
          <ul className="list-group">
            {data.map((item, index) => (
              <li
                className={`listgroupitem ${item._id === selectedId ? 'active' :''}`}
                key={item._id}
                data-index={index}
                data-id={item._id}
                onClick={loadGroupHandler}

              >
                <div className="clientinfo">
                  <h5>{item.name}</h5>
                  <button
                    className="iconBtn"
                    onClick={() => confirmBoxHandler(item)}
                  >
                    <img src="/img/grayclose.svg" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {confirmBoxStatus && (
        <ConfirmBox
          title="Delete Group"
          msg="Are you sure to delete group ?"
          onCancel={() => setConfirmBoxStatus(false)}
          onOK={deleteHandler}
        />
      )}
    </>
  );
}
