import React, { Component } from 'react';
import {Route, Redirect} from 'react-router-dom'
import AuthService from './services/auth.service.js'
const ProtectedRoute = ({component: Component, ...rest}) => {

     return (
         <Route {...rest} render={
             (props) => {
                
                if(AuthService.validToken())   {
                    return <Component {...props}/>
                } else {
                   return <Redirect to={{
                       pathname: "/",
                       state: {
                           from: props.location
                       }
                       
                   }
                   }/>
                }
                
             }
         }/>
     )

}

export default ProtectedRoute;