import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import ReplyForm from './ReplyForm';
import { API } from '../api/API';
import { Global } from '../api/Global';
import CommentOptions from './CommentOptions';
import EditCommentForm from './EditCommentForm';
import EachReply from './EachReply';

class EachComment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showReplayForm: false,
      showEditReplayForm: false,
      showCommentForm: false,

      newsFeedClientID: 0,
      commentID: 0,
      updatedAt: null,
      commentDescription: '',
      replyComments: [],
      creator: {},
      showOptions: false,
      totalLiked: 0,
      likedByUser: false,
      totalReplies: 0,
    };
  }
  componentDidUpdate() {}

  addReplayComment(obj) {
    let replyComments = [...this.state.replyComments];
    replyComments.unshift(obj);

    this.setState((prevState) => {
      return {
        replyComments: replyComments,
        totalReplies: prevState.totalReplies + 1,
      };
    });
  }
  onUpdateComment() {
    Global.socket.on('updateComment', async (response) => {
      let socketResponse = Object.assign({}, response);

      if (
        socketResponse.data.newsFeedClient === this.state.newsFeedClientID &&
        this.state.commentID === socketResponse.data._id
      ) {
        this.setState({
          commentDescription: socketResponse.data.commentDescription,
          updatedAt: socketResponse.data.updatedAt,
          showCommentForm: false,
        });
      }
    });
  }
  onReply() {
    try {
      Global.socket.on('reply', async (response) => {
        var replyData = Object.assign({}, response.data);
        delete replyData.totalReplies;

        if (
          replyData.newsFeedClient === this.state.newsFeedClientID &&
          replyData.newsFeedComment === this.state.commentID
        ) {
          let replyComments = [...this.state.replyComments];
          replyComments.unshift(replyData);

          this.setState({
            replyComments: replyComments,
            totalReplies: response.data.totalReplies,
          });
        }
        this.props.onLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    let creator = null;
    if (this.props.data.creator) {
      creator = {
        name: this.props.data.creator.name,
        profilePic: this.props.data.creator.profilePic,
      };
    }

    this.setState({
      newsFeedClientID: this.props.newsFeedClientID,
      commentID: this.props.commentID,
      commentDescription: this.props.data.commentDescription,
      updatedAt: this.props.data.updatedAt,
      creator: creator,
      totalLiked: this.props.totalLiked || 0,
      likedByUser: this.props.likedByUser || false,
      totalReplies: this.props.totalReplies || 0,
    });

    if (Global.socket) {
      this.onReply();
      this.onUpdateComment();
    }
  }
  showReplyComments() {
    try {
      API.showReplyComemnts({ commentID: this.state.commentID })
        .catch((error) => console.log(error))
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.setState({
              replyComments: response.data.data,
              showReplayForm: true,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  setOptionsStatus = (val) => {
    try {
      this.setState({
        showOptions: val,
      });
    } catch (error) {
      console.log(error);
    }
  };

  likeOnComment = () => {
    try {
      let obj = {
        commentID: this.state.commentID,
        newsFeedClientID: this.state.newsFeedClientID,
      };
      API.likeOnComment(obj)
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          try {
            let isRevoked = response.data.data.isRevoked === false;
            this.setState((prevState) => {
              let totalLiked = isRevoked
                ? prevState.totalLiked + 1
                : prevState.totalLiked - 1;
              return {
                likedByUser: isRevoked,
                totalLiked: totalLiked,
              };
            });
          } catch (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  editCommentStatus = (val) => {
    try {
      this.setState({
        showCommentForm: val,
      });
    } catch (error) {
      console.log(error);
    }
  };

  removeComment = (val) => {
    try {
      this.props.onConfirm('Are you sure you want to remove comment ? ');
    } catch (error) {
      console.log(error);
    }
  };
  removeReply = (val) => {
    try {
      let payload = {
        id: val,
      };
      this.props.onLoading(true);
      API.comment
        .removeReply(payload)
        .then((response) => {
          this.props.onLoading(false);
          if (response.status && response.status === 200) {
            let replyComments = [...this.state.replyComments];
            let obj = replyComments.find((item) => item._id === val);
            let index = replyComments.indexOf(obj);
            replyComments.splice(index, 1);
            this.setState(
              {
                replyComments: replyComments,
                showReplayForm: true,
                totalReplies: replyComments.length,
              },
              () => {
                this.props.onAlert('Reply Comment is successfully deleted ');
              },
            );
          } else {
            console.log();
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.onLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  editComment = (obj) => {
    try {
      API.comment
        .edit(obj)
        .catch((error) => console.log(error))
        .then((response) => {
          if (response.status && response.status === 200) {
            this.props.onAlert('Comment is successfully edited ');
            this.setState({
              showCommentForm: false,
              commentDescription: obj.msg,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const timeAgo = new TimeAgo('en-US');
    const {
      creator,
      likedByUser,
      totalLiked,
      totalReplies,
      commentDescription,
      replyComments,
      showReplayForm,
      newsFeedClientID,
      commentID,
      showCommentForm,
    } = this.state;
    return (
      <div className="commentBox">
        {
          <div className="commentsMsg">
            <div className="userComment">
              <div className="img">
                <img
                  src={
                    creator &&
                    creator.profilePic &&
                    creator.profilePic.length > 0
                      ? creator.profilePic
                      : '/img/logo.png'
                  }
                  width="25"
                  height="25"
                  alt="user"
                  title="user"
                />
              </div>
              <div className="rightSide">
                <div className="rightTop">
                  <div className="userInfo">
                    <h4>
                      {creator && creator.name ? creator.name : 'No Name'}
                    </h4>
                    <span className="timeago">
                      {timeAgo.format(new Date(this.state.updatedAt))}
                    </span>
                  </div>
                  <div className="actionBtns">
                    <button
                      className="like"
                      onClick={() => this.likeOnComment()}
                    >
                      <img
                        src={
                          likedByUser
                            ? 'img/thumbs-active-small.svg'
                            : '/img/thumbs.svg'
                        }
                        height="15"
                        width="15"
                        alt="Reply"
                        title="Reply"
                      />
                      <span>{totalLiked}</span>
                    </button>
                    <button
                      className="reply"
                      onClick={() => this.showReplyComments()}
                    >
                      <img
                        src="/img/reply.svg"
                        height="15"
                        width="15"
                        alt="Reply"
                        title="Reply"
                      />

                      <span>{totalReplies}</span>
                    </button>
                    {Global.user() === 'admin' ? (
                      <div className="link options">
                        <button
                          className="link"
                          onClick={() => this.setOptionsStatus(true)}
                        ></button>
                        <img
                          src="/img/options.svg"
                          height="15"
                          width="15"
                          alt="Options"
                          title="Options"
                        />
                        {commentID ? (
                          <CommentOptions
                            id={commentID}
                            onRemoveComment={(val) =>
                              this.props.onRemoveComment(val)
                            }
                            onEditCommentStatus={(val) =>
                              this.editCommentStatus(val)
                            }
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {!showCommentForm ? (
                  <div className="userCont"> {commentDescription}</div>
                ) : commentID && commentDescription ? (
                  <EditCommentForm id={commentID} msg={commentDescription} />
                ) : (
                  ''
                )}
                {/*
  ******************************************************************************
                        Reply Comments Display
  ******************************************************************************
*/}
                {replyComments && replyComments.length > 0
                  ? replyComments.map((obj) => (
                      <div key={obj._id}>
                        <EachReply
                          key={obj._id}
                          item={obj}
                          onAlert={(val) => this.props.onAlert(val)}
                          onLoading={(val) => this.props.onLoading(val)}
                          onRemove={(val) => this.removeReply(val)}
                        />
                      </div>
                    ))
                  : ''}
                {/*
  ******************************************************************************
                        Reply Comments Form Box
  ******************************************************************************
*/}
                {showReplayForm ? (
                  <ReplyForm
                    newsFeedClientID={newsFeedClientID}
                    onLoading={(val) => this.props.onLoading(val)}
                    onAlert={(msg) => this.props.onAlert(msg)}
                    commentID={commentID}
                    onAddReplyComment={(val) => this.addReplayComment(val)}
                  />
                ) : (
                  ''
                )}{' '}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

EachComment.propTypes = {
  data: PropTypes.object,
  commentID: PropTypes.string.isRequired,
  totalLiked: PropTypes.number,
  likedByUser: PropTypes.bool,
  totalReplies: PropTypes.number,
  onAddComment: PropTypes.func,
};
export default React.memo(EachComment);
