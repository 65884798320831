import React, { PureComponent } from 'react';
import { API } from '../api/API';
import SchedulePost from '../SchedulePost';
import Moment from 'react-moment';
import AddOption from './AddOption';
import 'moment-timezone';
import EachOption from './EachOption';
import Select from 'react-dropdown-select';

class Poll extends PureComponent {
  constructor(props) {
    console.log(props);
    super(props);
    this.pollQuestion = React.createRef();

    this.state = {
      title: '',
      id: '',
      type: 'single',
      schedulePostStatus: false,
      schedulePostTitle: null,
      closesAt: null,
      startsAt: null,
      activeDate: null,

      pollResponses: [
        {
          id: 0,
          title: 'Users can select only one response',
          value: 'single',
        },
        {
          id: 1,
          title: 'Users can select multiple response',
          value: 'multiple',
        },
      ],
      selectedPollResponse: [
        {
          id: 1,
          title: 'Users can select multiple response',
          value: 'multiple',
        },
      ],
      options: [],
    };
  }
  setPollResponse(obj) {
    this.props.onChangeType(obj[0].value);
  }
  componentDidMount() {
    this.pollQuestion.current.addEventListener('paste', function (e) {
      e.preventDefault();
    });

    let allRepsTxt = document.querySelectorAll('.pollOptions .editableBox');

    let i = 0;
    while (i < allRepsTxt.length) {
      allRepsTxt[i].addEventListener('keyup', (e) => {
        e.target.innerText.length > 0
          ? e.target.classList.add('on')
          : e.target.classList.remove('on');
      });
      i++;
    }

    this.setState(
      {
        options: this.props.options,
        closesAt: this.props.closesAt ? this.props.closesAt : null,
        startsAt: this.props.startsAt ? this.props.startsAt : null,
      },
      () => {
       // this.props.onSchedulePoll(new Date());
      },
    );
    
  }
  changeTitle() {
    try {
      this.props.onChangeTitle(this.pollQuestion.current.textContent);
    } catch (error) {
      console.log(error);
    }
  }

  schedulePostStatus(val, title) {
    try {
      console.log(val,title);
      this.setState({
        activeDate:title === "Start Schedule" ? this.state.startsAt : this.state.closesAt,
        schedulePostStatus: val,
        schedulePostTitle: title,
        
      });
    } catch (error) {
      console.log(error);
    }
  }
  async setSchedulePollDate(val, trigger) {
    try {
      console.log('setSchedulePollDate', val, trigger);
      await this.setState(
        (prevState) => {
          let startPollDate = trigger === 'start' ? val : prevState.startsAt;
          let closePollDate = trigger === 'close' ? val : prevState.closesAt;
          return {
            schedulePostStatus: !prevState.schedulePostStatus,
            startsAt: startPollDate,
            closesAt: closePollDate           
          };
        },
        () => {
          if (
            this.state.startsAt &&
            this.state.closesAt &&
            this.state.startsAt > this.state.closesAt
          ) {
            this.props.onAlert(
              'Start Date must be not be greater than close date',
            );
          }
          this.props.onSchedulePoll(val, trigger);
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  setSchedulePoll(val) {
    try {
      this.setState(
        {
          schedulePostStatus: val,
        },
        () => {
          this.props.onSchedulePoll(val);
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  clearSchedule (){
    try {
      this.setState(
        {
          schedulePostStatus: false,
      schedulePostTitle: null,
      closesAt: null,
      startsAt: null,
        }
        
      );
    } catch (error) {
      console.log(error);
    }
  }
  removeImg(item) {
    try {
      let options = [...this.state.options];
      let index = options.indexOf(item);
      delete options[index].url;

      this.setState({
        options: options,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { options, startsAt, closesAt } = this.state;

    return (
      <>
        <section id="poll">
          <div className="startendinfo">
            <div className="startAt">
              <span className="lbl">Start at :</span>
              <button
                className="time"
                onClick={() => this.schedulePostStatus(true, 'Start Schedule')}
                disabled={this.state.schedulePostStatus}
              >
                <img
                  src="/img/time.svg"
                  alt="Make a schedule"
                  title="Make a schedule"
                />
              </button>
              {startsAt ? (
                <Moment format="MMM Do YYYY, h:mm:ss a">{startsAt}</Moment>
              ) : (
                ''
              )}
            </div>

            <div className="endAt">
              <span className="lbl">End at :</span>
              <button
                className="time"
                disabled={this.state.schedulePostStatus}
                onClick={() => this.schedulePostStatus(true, 'End Schedule')}
              >
                <img
                  src="/img/time.svg"
                  alt="Make a schedule"
                  title="Make a schedule"
                />
              </button>
              {closesAt ? (
                <Moment format="MMM Do YYYY, h:mm:ss a">{closesAt}</Moment>
              ) : (
                ''
              )}
            </div>
          </div>

          <button className="closePoll" onClick={() => this.props.onHidePoll()}>
            <img src="/img/close.svg" />
          </button>

          {this.state.schedulePostStatus ? (
            <SchedulePost
              onScheduled={(val, trigger) =>
                this.setSchedulePollDate(val, trigger)
              }
              onAlert={(val) => this.props.onAlert(val)}
              btnTitle="Schedule Poll"
              onStatus={() => this.setSchedulePoll(false)}
              title={this.state.schedulePostTitle}
              onRemove={()=>this.clearSchedule()}
              value={this.state.activeDate}
            />
          ) : (
            ''
          )}
          <div className="cont">
            <div
              className="pollQuestion"
              ref={this.pollQuestion}
              placeholder="Ask your question"
              contentEditable="true"
              suppressContentEditableWarning={true}
              onInput={() => this.changeTitle()}
            ></div>

            <div className="pollOptions">
              {options.length > 0
                ? options.map((item) => {
                    return (
                      <EachOption
                        key={item.id}
                        item={item}
                        onRemoveImg={(item) => this.removeImg(item)}
                        onLoading={(val) => this.props.onLoading(val)}
                        onEdit={(item) => this.props.onEdit(item)}
                        onRemoveOption={(item) =>
                          this.props.onRemoveOption(item)
                        }
                      />
                    );
                  })
                : ''}

              <AddOption
                onLoading={(val) => this.props.onLoading(val)}
                onAddOption={(obj) => this.props.onAddOption(obj)}
              />
            </div>
            <div className="pollResponse">
              <div className="label">Poll Responses:</div>
              <Select
                values={this.state.selectedPollResponse}
                onChange={(value) => this.setPollResponse(value)}
                options={this.state.pollResponses}
                labelField="title"
                valueField="id"
                className="selectbox "
              />
            </div>
          </div>
          <footer></footer>
        </section>
      </>
    );
  }
}

export default React.memo(Poll);
