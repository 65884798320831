import React, { useEffect, useState,useRef } from 'react';
import ClientService from '../../services/client.service';
import LambdaService from '../../services/lambda.service';
import MyKademyService from '../../services/mykademy.service';
import GroupsService from '../../services/group.service';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import styles from '../../cssmodules/addorg.module.scss';
export default function AddGroup(props) {
  const { id, onCreate,onCancel } = props;
  const [data, setData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [clientName, setClientName] = useState(null);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [groupName, setGroupName] = useState('');
  const selectOrg =  useRef(null);
  useEffect(() => {
    getAllClients();
  }, []);
  const getAllClients = async () => {
    try {
      let result = await ClientService.getAll();
      setData(result);
    } catch (error) {
      console.log(error);
    }
  };
  const selectClientHandler = (e) => {
    if (e.target.value) {
      setSelectedClientId(e.target.value);
      setClientName(data.find((item) => item.id === e.target.value).name);
    }
  };
  const handleSearch = async (query) => {
    try {
      console.log('handleSearch');
      setIsLoading(true);

      let options =
        clientName === 'Mykademy'
          ? await MyKademyService.getOrg(query)
          : await LambdaService.organisation({ keyword: query });
      console.log(options);
      setOptions(options);
      setIsLoading(false);
     
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const selectOrganisation = (data) => {
    console.log(data);
    if (data.length > 0) {
      let total = selectedOrgs.filter((item) => item.id === data[0].id);
      console.log(total);
      if (total.length === 0) {
        let orgs = selectedOrgs.filter((item) => item !== null);
        orgs.push(data[0]);
        setSelectedOrgs(orgs);
        selectOrg.current.clear();
      }
    }
  };
  const removeOrgHandler = (e) => {
    console.log(e.currentTarget.dataset.id);
    let obj = selectedOrgs.find(
      (item) => item.id === e.currentTarget.dataset.id,
    );
    console.log(obj);
    let index = selectedOrgs.indexOf(obj);
    console.log(index);
    e.preventDefault();
    let clone = [...selectedOrgs];
    clone.splice(index, 1);

    console.log(clone);
    setSelectedOrgs(clone);
  };
  const filterOrganization = (data) => {
   
    return data.map((item) => {
      
      /* Just find domain only which is in bracket */
      let startBracket = item.name.search(/\(/);
      let endBracket = item.name.search(/\)/);
      let domain;
      if(startBracket>=0 && endBracket>=0){
        startBracket=startBracket+1;
       domain = item.name.slice(startBracket,endBracket);
      }
      /* Just find group name only */
      let name = item.name;
      let bracketSplit = name.split('(');
      if (bracketSplit.length > 1) {
        name = bracketSplit[0];
      }

      return {
        id: item.id,
        name,
        domain
      };
    });
  };
  const saveOrgs = async () => {
    try {
      let payload = {
        clientId: selectedClientId,
        organisation: filterOrganization(selectedOrgs),
        name: groupName,

      };
      console.log(payload);

      let result = await GroupsService.createGroup(payload);
      onCreate();
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };
  const cancelHandler = ()=>{
    onCancel();
  }
  return (
    <div className="bp-form">
      <h2>Add Group</h2>
      <div className="form-group">
        <label>Name</label>
      </div>
      <div>
        <input
          type="text"
          className="form-control"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Client</label>
      </div>

      <div className="form-group">
        <div>
          <select
            onChange={selectClientHandler}
            className="selectBox form-control"
          >
            <option value="">Select Client</option>
            {data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
     
      <div className="form-group">
        {clientName && (
          <AsyncTypeahead
            labelKey={(option) => `${option.name}`}
            isLoading={isLoading}
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Search for an organisation"
            onChange={(obj) => selectOrganisation(obj)}
            id="select-org"
            ref={selectOrg}
          />
        )}
      </div>

      <div className="form-group" style={{ marginTop: '50px' }}>
        {selectedOrgs && selectedOrgs.length > 0 && (
          <>
            <h4>Selected Organisations</h4>
            <ul className={styles.orgList}>
              {selectedOrgs.map((item) => (
                <li key={item.id}>
                  {item.name}

                  <img
                    src="/img/grayclose.svg"
                    type="button"
                    data-id={item.id}
                    className="iconBtn"
                    width="12"
                    onClick={removeOrgHandler}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      <div className="form-group cardfooter">
        <button
          onClick={saveOrgs}
          className="btn btn-primary"
          disabled={selectedOrgs?.length === 0 || groupName.length ===0}
        >
          Save
        </button>
        <button
          onClick={cancelHandler}
          className="btn btn-secondary"
         
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
