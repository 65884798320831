import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import ClientService from '../services/client.service';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import pageHeader from '../cssmodules/pageheader.module.scss';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Client extends Component {
  constructor(props) {
    super(props);
    this.uploadIcon = React.createRef();
    this.editIcon = React.createRef();
    this.getParticularClient = this.getParticularClient.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.createClient = this.createClient.bind(this);
    this.updateClient = this.updateClient.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.onChangeNameToEdit = this.onChangeNameToEdit.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.state = {
      clients: [],
      activeIndex: 0,
      particularClient: {},
      loading: true,
      createLoading: false,
      deleteLoading: false,
      showModal: false,
      showDeleteModal: false,
      errmessage: '',
      successmessage: '',
      nameToEdit: '',
    };
  }

  async componentDidMount() {
    let allCLients = await ClientService.getAll();

    this.setState(
      {
        clients: allCLients,
        particularClient: allCLients[0] ? allCLients[0] : {},
        nameToEdit: allCLients[0] ? allCLients[0].name : '',
        loading: false,
        name: '',
      },
      () => {
        console.log(this.state.clients);
      },
    );
  }

  getParticularClient(index) {
    this.setState({
      activeIndex: index,
      particularClient: this.state.clients[index],
      nameToEdit: this.state.clients[index].name,
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
      createLoading: false,
      name: '',
      errmessage: '',
    });
  }

  toggleDeleteModal() {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      deleteLoading: false,

      errmessage: '',
    });
  }

  fadeOut() {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      2500,
    );
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeNameToEdit(e) {
    this.setState({
      nameToEdit: e.target.value,
    });
  }

  changeStatus(e) {
    if (e.target.value === 'deleted') {
      this.props.history.push('/clients/deleted');
    }
  }

  deleteClient() {
    this.setState({
      deleteLoading: true,
    });

    ClientService.delete(this.state.particularClient._id).then(
      (response) => {
        let client = [...this.state.clients];
        let clientFiltered = client.filter(
          (client) => client._id !== this.state.particularClient._id,
        );
        this.setState({
          successmessage: `client "${this.state.particularClient.name}" deleted successfully`,
          deleteLoading: false,
          clients: clientFiltered,
          showDeleteModal: !this.state.showDeleteModal,
          particularClient: clientFiltered[0] ? clientFiltered[0] : {},
          activeIndex: 0,
          nameToEdit: clientFiltered[0] ? clientFiltered[0].name : '',
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          deleteLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  updateClient(e) {
    e.preventDefault();

    this.setState({
      errmessage: '',
      editLoading: true,
    });

    ClientService.update({
      id: this.state.particularClient._id,
      name: this.state.nameToEdit,
      icon: this.editIcon.current.files[0],
    }).then(
      (response) => {
        //Create a new array based on current state:
        let clients = [...this.state.clients];

        clients[this.state.activeIndex].name = response.name;
        clients[this.state.activeIndex].icon = response.icon;
        let particularClient = Object.assign({}, this.state.particularClient);
        particularClient.icon = response.icon;
        // Set state
        this.setState({
          clients: clients,
          successmessage: `Client  edited successfully`,
          editLoading: false,
          particularClient: particularClient,
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          editLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }
  triggerUploadIcon() {
    this.uploadIcon.current.click();
  }
  triggerUploadEditIcon() {
    this.editIcon.current.click();
  }
  createClient(e) {
    console.log('Create Client Trigger');
    e.preventDefault();

    this.setState({
      errmessage: '',
      createLoading: true,
    });

    this.form.validateAll();
    if (this.checkBtn.context._errors.length === 0) {
      // this.state.name, this.uploadIcon.current
      ClientService.create({
        name: this.state.name,
        icon: this.uploadIcon.current.files[0],
      }).then(
        (response) => {
          this.toggleModal();
          // Create a new array based on current state:
          let clients = [...this.state.clients];

          // Add item to it
          clients.push(response);

          // Set state
          this.setState({
            clients: clients,
            successmessage: `Client "${response.name}" created successfully`,
          });

          this.fadeOut();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            createLoading: false,
            errmessage: resMessage,
          });
        },
      );
    } else {
      this.setState({
        createLoading: false,
      });
    }
  }

  render() {
    const { clients, activeIndex, particularClient, loading } = this.state;

    return (
      <div id="clientList">
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>Clients </h1>
                <div className={pageHeader.rightSide}>
                  <select
                    name="status"
                    className="form-control status"
                    onChange={this.changeStatus}
                  >
                    <option value="active">Active</option>
                    <option value="deleted">Deleted</option>
                  </select>
                  <button
                    className="right btn btn-dark rounded-0"
                    onClick={this.toggleModal}
                  >
                    Create Client
                  </button>
                </div>
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row contHolder">
                <div className="col-md-4 contLeftSide">
                  <div className="holder">
                    <div className="header">
                      <h3>Client List</h3>
                      {clients.length > 10 ? (
                        <button className="viewAllBtn"> View All</button>
                      ) : (
                        ''
                      )}
                    </div>
                    <ul className="list-group">
                      {clients.map((client, index) => (
                        <li
                          key={client._id}
                          className={`listgroupitem ${
                            index === activeIndex ? `active` : ''
                          }`}
                          onClick={() => this.getParticularClient(index)}
                        >
                          <div className="clienticon">
                            {client.icon ? (
                              <img
                                className="icon"
                                src={client.icon}
                                alt="Client"
                              />
                            ) : (
                              <img src={require('../user.svg')} alt="Client" />
                            )}
                          </div>
                          <div className="clientinfo">
                            <h5>{client.name}</h5>

                            <button
                              className="iconBtn"
                              onClick={() => this.toggleDeleteModal()}
                            >
                              <img src="/img/grayclose.svg" />
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {this.state.particularClient.name && (
                  <div className="col-md-8  contRightSide">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-12">
                            <h4>Client Information</h4>

                            <Link
                              to={`/clients/${this.state.particularClient._id}/tenants`}
                              className="right btn btn-secondary "
                            >
                              Tenants
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="col-sm-2 d-flex justify-content-center">
                          {this.state.particularClient.icon ? (
                            <img
                              src={this.state.particularClient.icon}
                              width="100"
                              height="100"
                            />
                          ) : (
                            <img
                              src={require('../user.svg')}
                              width="100"
                              height="100"
                            />
                          )}
                        </div>
                        <Form className="bp-form col-sm-10">
                          <div className="row">
                            <div className="col-md-12 ">
                              <div className="form-group">
                                <label>Name</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="nameToEdit"
                                  value={this.state.nameToEdit}
                                  onChange={this.onChangeNameToEdit}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Icon</label>

                                <input
                                  style={{ display: 'block' }}
                                  type="file"
                                  className="form-control"
                                  name="nameToEdit"
                                  ref={this.editIcon}
                                />
                                {/*
                                   <Button
                                  color="secondary"
                                  onClick={() => this.triggerUploadEditIcon()}
                                >
                                  Update Icon
                                </Button>
                                   */}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Client ID</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  value={particularClient.clientId}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Client secret key</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  value={particularClient.clientSecret}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                      <div className="card-footer">
                        <Button
                          color="primary"
                          disabled={this.state.editLoading}
                          onClick={this.updateClient}
                        >
                          Save
                          {this.state.editLoading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Create Client</ModalHeader>
                <Form
                  onSubmit={this.createClient}
                  ref={(c) => {
                    this.form = c;
                  }}
                  className="bp-form"
                >
                  <ModalBody>
                    <div className="form-group">
                      <label>Client name</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        value={this.state.name}
                        validations={[required]}
                        onChange={this.onChangeName}
                        placeholder="name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Icon</label>
                      {/*
                       <Button
                        color="secondary"
                        onClick={() => this.triggerUploadIcon()}
                      >
                        <i className="fa fa-image" aria-hidden="true"></i>{' '}
                        Upload Image
                      </Button>
                       */}

                      <input
                        style={{ display: 'block' }}
                        type="file"
                        ref={this.uploadIcon}
                      />
                    </div>
                    {this.state.errmessage && (
                      <div className="form-group">
                        <div
                          className="alert alert-danger error-flash-message"
                          role="alert"
                        >
                          {this.state.errmessage}
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <div className="form-group">
                      <Button
                        color="primary"
                        disabled={this.state.createLoading}
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>Save
                        {this.state.createLoading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </Button>
                      <Button color="secondary" onClick={this.toggleModal}>
                        <i className="fa fa-close" aria-hidden="true"></i>Cancel
                      </Button>

                      <CheckButton
                        style={{ display: 'none' }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />
                    </div>
                  </ModalFooter>
                </Form>
              </Modal>

              <Modal
                isOpen={this.state.showDeleteModal}
                toggle={this.toggleDeleteModal}
                className="modal"
              >
                <ModalHeader>Delete Client </ModalHeader>

                <ModalBody>
                  <div class="text-center">
                    Are you sure to delete the client "
                    {this.state.particularClient.name}"{' '}
                  </div>
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.deleteLoading}
                      onClick={this.deleteClient}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.deleteLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleDeleteModal}>
                      <i class="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
