import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
const API_URL = process.env.REACT_APP_API_URL;

export default class Paginate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      currentPage: this.props.currentPage,
      pages: this.props.pages,
    };
  }

  render() {
    return (
      <Pagination aria-label="Page navigation example">
        <PaginationItem
          disabled={this.props.pages === 1 || this.props.currentPage == 1}
        >
          <PaginationLink
            first
            href="#"
            onClick={() => this.props.handlePagination(1)}
            title="First"
          />
        </PaginationItem>
        <PaginationItem
          disabled={this.props.pages === 1 || this.props.currentPage == 1}
        >
          <PaginationLink
            previous
            href="#"
            onClick={() =>
              this.props.handlePagination(this.props.currentPage - 1)
            }
            title="Previous"
          />
        </PaginationItem>
        {[...Array(this.props.pages)].map(
          (page, i) =>
            i + 1 >= this.props.currentPage - 4 &&
            i + 1 <= this.props.currentPage + 4 && (
              <PaginationItem key={i} active={i + 1 === this.props.currentPage}>
                <PaginationLink
                  href="#"
                  onClick={() => this.props.handlePagination(i + 1)}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ),
        )}

        <PaginationItem disabled={this.props.pages === this.props.currentPage}>
          <PaginationLink
            next
            href="#"
            onClick={() =>
              this.props.handlePagination(this.props.currentPage + 1)
            }
            title="Next"
          />
        </PaginationItem>
        <PaginationItem disabled={this.props.pages === this.props.currentPage}>
          <PaginationLink
            last
            href="#"
            title="Last"
            onClick={() => this.props.handlePagination(this.props.pages)}
          />
        </PaginationItem>
      </Pagination>
    );
  }
}
