import React, { PureComponent } from 'react';

class AttachmentList extends PureComponent {
  constructor(props) {
    try {
      super(props);
      this.state = {
        showAllFiles: false,
        totalShow: 5,
        remainder: 0,
      };
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    try {
      this.setState({
        remainder: this.props.attachments.length - 5,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showAllAttachmentFiles() {
    try {
      this.setState({
        totalShow: this.props.attachments.length,
        showAllFiles: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  viewAllAttachments() {
    try {
      return this.props.attachments.length > 5 ? (
        this.state.showAllFiles === false ? (
          <button
            className="viewAllFiles"
            onClick={() => this.showAllAttachmentFiles()}
          >
            {' '}
            View +{this.state.remainder} Attachments{' '}
          </button>
        ) : (
          ''
        )
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <>
        {this.props.attachments && this.props.attachments.length > 0 ? (
          <>
            <div className="fileList">
              {this.props.attachments.map((item, index) => {
                return index < this.state.totalShow ? (
                  <div className="item" key={item._id}>
                    <div className="icon">
                      <a href={item.attachmentUrl} target="_blank">
                        <img src="/img/document.svg" alt="Download Document" />
                      </a>
                    </div>
                    <div className="desc">
                      <a href={item.attachmentUrl} target="_blank">
                        {item.name}
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                );
              })}
            </div>
            {this.viewAllAttachments()}
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}
export default React.memo(AttachmentList);
