import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import styles from '../cssmodules/news.module.scss';
import AdminSlider from '../components/AdminSlider';
import NewsService from "../../src/services/news.service"
export default class EachNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: null,
      title: null,
      createdAt: null,
      description: null,
      expand: false,
      isEven: false,
      restoreBtnStatus: false,
      publishBtnStatus: false,
      editBtnStatus: false,
      deleteBtnStatus: false,
      medias: [],
      sliderStatus: false,
      sliderIndex: 0,
      isPinned:null
    };
  }
  componentDidUpdate() {
    try {
  
    } catch (error) {
      console.log(error);
    }
  }
  async toggleExpand() {
    try {
      await this.setState((prevState) => {
        return {
          expand: !prevState.expand,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    try {

      await this.setState({
        _id: this.props._id ? this.props._id : null,
        title: this.props.title ? this.props.title : null,
        createdAt: this.props.createdAt ? this.props.createdAt : null,
        description: this.props.description ? this.props.description : null,
        isEven: this.props.isEven ? this.props.isEven : false,
        creator: this.props.creator ? this.props.creator : '',
        restoreBtnStatus: this.props.restoreBtnStatus
          ? this.props.restoreBtnStatus
          : false,
        publishBtnStatus: this.props.publishBtnStatus
          ? this.props.publishBtnStatus
          : false,
        editBtnStatus: this.props.editBtnStatus
          ? this.props.editBtnStatus
          : false,
        deleteBtnStatus: this.props.deleteBtnStatus,
        medias: this.props.medias ? this.props.medias : [],
        isPinned : this.props.isPinned ? this.props.isPinned : null
      });
    } catch (error) {
      console.log(error);
    }
  }
  showSlider(index) {
    try {
      this.setState({
        sliderStatus: true,
        sliderIndex: index,
      });
      console.log(index);
    } catch (error) {
      console.log(error);
    }
  }
  hideSlider() {
    try {
      this.setState({
        sliderStatus: false,
        sliderIndex: 0,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async pinHandler(obj){
    try{
      console.log("Pinn",obj);  
      let result = await NewsService.pinToogleNews(obj);
      if(result){
        this.props.onReLoadNews();
      }
    }catch(error){
      console.error(error)
    }
    

    
  }
  render() {
    const {
      _id,
      title,
      createdAt,
      description,
      expand,
      isEven,
      restoreBtnStatus,
      deleteBtnStatus,
      editBtnStatus,
      publishBtnStatus,
      medias,
      creator,
      sliderIndex,
      sliderStatus,
    } = this.state;
    return (
      <>
        {sliderStatus ? (
          <AdminSlider
            index={sliderIndex}
            medias={medias}
            onClose={() => this.hideSlider()}
          />
        ) : (
          ''
        )}

        <div className={`${styles.card} ${isEven ? styles.even : styles.odd} ${this.props.isPinned ? styles.bordered : ''}`}>
          <div className={styles.cardheader}>
            <div className={styles.headerLeft}>
              <h4 className={styles.title} onClick={() => this.toggleExpand()}>
                {title}
              </h4>
              {expand ? (
                <>
                  {' '}
                  <div className={styles.headerInfo}>
                    <div className={styles.createdAt}>
                      <i className="fa fa-clock-o"></i>
                      {new Date(createdAt).toDateString()}
                    </div>
                    {/* <div className={styles.postedBy}>
                      {' '}
                      <i className="fa fa-user-o"></i>
                      {creator.hasOwnProperty('name')
                        ? creator.name
                        : 'Super Admin'}
                    </div> */}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className={styles.headerRight}>
              {publishBtnStatus ? (
                <Link className={styles.btn} to={`/news/${_id}/clients`}>
                  <i className="fa fa-newspaper-o" title="Publish"></i>
                </Link>
              ) : (
                ''
              )}
              {editBtnStatus ? (
                <Link className={styles.btn} to={`/news/edit/${_id}`}>
                  <i className="fa fa-pencil" title="Edit"></i>
                </Link>
              ) : (
                ''
              )}

              {deleteBtnStatus ? (
                <button
                  className={styles.btn}
                  onClick={() => this.props.toggleModal(_id, title)}
                >
                  <i className="fa fa-trash" title="Delete"></i>
                </button>
              ) : (
                ''
              )}
              {
                deleteBtnStatus && <button
                className={this.props.isPinned ? styles.activeBtn: styles.btn}
                onClick={()=>this.pinHandler({id:this.state._id,status:!this.props.isPinned})}
               
                >
                {' '}
                <i className="fa fa-thumb-tack" title="Pin"></i>
                
                </button>
              }
              
              {restoreBtnStatus ? (
                <button
                  className={styles.btn}
                  onClick={() => this.props.toggleModal(_id, title)}
                >
                  <i
                    className="fa fa-undo"
                    aria-hidden="true"
                    title="Restore"
                  ></i>
                </button>
              ) : (
                ''
              )}

              <button
                className={styles.btn}
                onClick={() => this.toggleExpand()}
              >
                <i
                  className={`fa ${
                    expand === false ? `fa-eye` : `fa-eye-slash`
                  }`}
                  title={expand === false ? 'Show News' : 'Hide News'}
                ></i>
              </button>
            </div>
          </div>
          {expand ? (
            <div className={styles.cardbody}>
              {description != null ? (
                <>
                  {renderHTML(description)}{' '}
                  <div className="fileList box">
                    {medias.length > 0
                      ? medias.map((item, index) => {
                          return item.imageThumbnailUrl ? (
                            <div
                              key={item._id}
                              className="imageThumbnail thumbnail fileItem"
                            >
                              <button
                                onClick={() => this.showSlider(index)}
                                className="triggerBtn"
                              >
                                <img
                                  src={item.imageThumbnailUrl}
                                  width="200"
                                  height="200"
                                />
                              </button>
                            </div>
                          ) : item.videoThumbnailUrl ? (
                            <div
                              key={item._id}
                              className="videoThumbnailUrl thumbnail fileItem"
                            >
                              <i></i>
                              <button
                                onClick={() => this.showSlider(index)}
                                className="triggerBtn"
                              >
                                <img
                                  src={item.videoThumbnailUrl}
                                  width="200"
                                  height="200"
                                />
                              </button>
                            </div>
                          ) : item.attachmentUrl ? (
                            <div
                              key={item._id}
                              className="attachment-thumbnail thumbnail fileItem"
                            >
                              <button
                                onClick={() => this.showSlider(index)}
                                className="triggerBtn"
                              >
                                <i className="fa fa-file-o"></i>
                                <span class="filename"> {item.name}</span>
                              </button>
                            </div>
                          ) : (
                            ''
                          );
                        })
                      : ''}
                  </div>
                  <div className={styles.btnHolder}>
                    {publishBtnStatus ? (
                      <Link
                        className={styles.primaryBtn}
                        to={`/news/${_id}/clients`}
                      >
                        {' '}
                        <i className="fa fa-newspaper-o" title="Publish"></i>
                        Publish
                      </Link>
                    ) : (
                      ''
                    )}
                    {editBtnStatus ? (
                      <Link
                        className={styles.primaryBtn}
                        to={`/news/edit/${_id}`}
                      >
                        {' '}
                        <i className="fa fa-pencil" title="Edit"></i>
                        Edit
                      </Link>
                    ) : (
                      ''
                    )}

                
                  </div>
                 
                
                 
                </>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}
