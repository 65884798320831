import React, { PureComponent } from 'react';
import Select from 'react-dropdown-select';

class SchedulePost extends PureComponent {
  constructor(props) {
    try {
      console.log(props);
      super(props);
      this.hrsRef = React.createRef();
      this.setHours = this.setHours.bind(this);
      this.setMonth = this.setMonth.bind(this);
      this.setMins = this.setMins.bind(this);

      this.state = {
        monthsList: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        dayList: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        days: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        dates: [],
        scheduleDate: null,
        choosedDate: 0,
        selectedMonth: 0,
        selectedYear: 2020,
        selectedDate: 0,
        selectedDay: '',
        selectedHour: null,
        selectedMinutes: 0,
        selectedAMPM: null,
        twelveHours: 0,
        title: null,
        hours: 1,
        btnTitle: 'OK',
        minutes: 1,

        ampmOptions: [
          {
            id: '0',
            title: 'AM',
            value: 'am',
          },
          {
            id: '1',
            title: 'PM',
            value: 'pm',
          },
        ],
        timeZoneValues: [
          {
            id: '0',
            title: 'PST',
            value: 'pst',
          },
        ],
        timeZoneOptions: [
          {
            id: '0',
            title: 'PST',
            value: 'pst',
          },
          {
            id: '1',
            title: 'GMT',
            value: 'gmt',
          },
        ],
        monthZoneOptions: [],
        monthValues: [],
        selectedMonthValue: [],
      };
    } catch (err) {
      console.log(err);
    }
  }
  setIfLeapYear() {
    let selectedYear = new Date().getFullYear();
    let days = [...this.state.days];
    if (selectedYear % 4 === 0) {
      days[1] = 28;
      this.setState({
        days: days,
      });
    }
  }
  convertMinutes(val) {
    try {
     
      let digit = parseInt(val);
      if (digit) {
        if (digit <= 59) {
          return val;
        } else {
          return val.charAt(0);
        }
      }else{
        console.log("con2")
        return val;
      }
    } catch (error) {
      console.log(error);
    }
  }
  convertTwelveHours(val) {
    try {
      if (val) {
        let hrs = parseInt(val);

        if (this.state.selectedAMPM[0].value === 'pm') {
          if (hrs === 12) {
            hrs = 12;
          } else if (hrs > 12) {
            hrs = hrs - 12;
          }
        }
        if (this.state.selectedAMPM[0].value === 'am') {
          if (hrs > 12) {
            hrs -= 12;
          }
        }

        return hrs;
      } else {
        return '';
      }
    } catch (error) {
      console.log(error);
    }
  }
  setCurrentDate() {
    let da = new Date();
    let localMinute = da.getMinutes();
    let utcMinute = da.getUTCMinutes();
    let mins = Math.abs((localMinute - utcMinute + 30) % 60);
    da.setMinutes(mins);
    return da;
  }
  getUTCMinutes() {
    let newDate = this.props.value ? new Date(this.props.value) : new Date();
    let da = newDate;
    let localMinute = da.getMinutes();
    let utcMinute = da.getUTCMinutes();
    return Math.abs((localMinute - utcMinute + 30) % 60);
  }
  componentDidUpdate() {
    this.setState({
      title: this.props.title ? this.props.title : null,
    });
  }
  async componentDidMount() {
    try {
      let newDate = this.props.value ? new Date(this.props.value) : new Date();

      let selectedYear = newDate.getFullYear();
      let selectedMonth = newDate.getMonth();
      let currentDate = newDate.getDate();
      let selectedHour = newDate.getHours();

      let selectedMinutes = this.state.selectedMinutes
        ? this.state.selectedMinutes
        : newDate.getMinutes();

      if (this.props.mode === 'createpost') {
        selectedMinutes = this.getUTCMinutes();
      }

      this.setIfLeapYear();

      let datesList = this.setDateList({
        year: selectedYear,
        month: selectedMonth,
        currentDate: currentDate,
      });

      let monthList = this.showMonthList();
      let ampmOptions = [...this.state.ampmOptions];
      let monthObj = monthList.find(
        (item) => item.month === this.state.monthsList[selectedMonth],
      );
      let monthIndex = monthList.indexOf(monthObj);

      let scheduleDate = this.props.value
        ? new Date(this.props.value)
        : new Date();
      scheduleDate.setFullYear(selectedYear);
      scheduleDate.setMonth(selectedMonth);
      scheduleDate.setDate(newDate.getDate());

      await this.setState({
        monthValues: monthList,
        selectedMonth: selectedMonth,
        selectedYear: selectedYear,
        selectedHour: selectedHour,
        selectedMinutes:
          selectedMinutes < 10 ? '0' + selectedMinutes : selectedMinutes,
        selectedDate: newDate.getDate(),
        selectedDay: this.state.dayList[newDate.getDay()].slice(0, 3),
        dates: datesList,
        btnTitle: this.props.btnTitle ? this.props.btnTitle : 'OK',
        scheduleDate: scheduleDate,
        title: this.props.title ? this.props.title : null,
        selectedAMPM:
          selectedHour > 12
            ? [ampmOptions[1]]
            : selectedHour === 0
            ? [ampmOptions[1]]
            : selectedHour === 12
            ? [ampmOptions[0]]
            : [ampmOptions[0]],
        selectedMonthValue: [monthList[monthIndex]],
        twelveHours:
          selectedHour > 12
            ? parseInt(selectedHour) - 12
            : selectedHour === 0
            ? 12
            : selectedHour === 12
            ? 0
            : parseInt(selectedHour),
      });
    } catch (error) {
      console.log(error);
    }
  }
  setDateList(obj) {
    try {
      let selectedYear = obj.year;
      let selectedMonth = obj.month;

      let firstDateDay = new Date(
        selectedYear + '-' + (selectedMonth + 1) + '-01',
      ).getDay();

      // Find dates of previous month
      let prevMonthRemainStart =
        this.state.days[selectedMonth - 1] - firstDateDay + 1;

      let j = 0;
      let date = 0;
      let datesList = [];
      let monthOf = null;
      let active = false;
      let isCurrentDate = false;
      let startedCountCurrentMonthDate = false;

      while (j < 42) {
        // Date Display of Previous Month

        if (j === 0 && prevMonthRemainStart > 0) {
          date = prevMonthRemainStart;
          monthOf = 'previous';
        }

        if (j === firstDateDay) {
          monthOf = 'current';
          date = 1;
          startedCountCurrentMonthDate = true;
        }

        if (
          date > this.state.days[selectedMonth] &&
          startedCountCurrentMonthDate
        ) {
          date = 1;
          monthOf = 'next';
        }

        if (date === this.state.selectedDate && monthOf === 'current') {
          isCurrentDate = true;
        } else {
          isCurrentDate = false;
        }

        let mydate = '0' + date;

        let todayDate = new Date();
        let eachDate = new Date(
          selectedYear + '-' + (selectedMonth + 1) + '-' + mydate.slice(-2),
        );

        if (eachDate >= todayDate) {
          active = true;
        } else {
          active = false;
          // Check exact year, month and date
          let matchEachDate =
            eachDate.getDate() === todayDate.getDate() ? true : false;
          let matchEachYear =
            eachDate.getFullYear() === todayDate.getFullYear() ? true : false;
          let matchEachMonth =
            eachDate.getMonth() === todayDate.getMonth() ? true : false;
          if (
            matchEachDate === true &&
            matchEachYear === true &&
            matchEachMonth === true
          ) {
            active = true;
          }
        }

        // If Previous Month's date need
        if (monthOf === 'previous') {
          active = false;
        }

        let selectedDay = new Date(
          selectedYear + '-' + (selectedMonth + 1) + '-' + date,
        ).getDay();

        let month = selectedMonth;
        if (monthOf === 'previous') {
          month = month - 1;
        }

        if (monthOf === 'next') {
          month = month + 1;
        }

        datesList.push({
          id: j,
          monthOf: monthOf,
          date: date,
          month: month,
          year: selectedYear,
          isCurrentDate: isCurrentDate,
          active: active,
          day: selectedDay,
        });
        date++;

        j++;
      }

      return datesList;
    } catch (error) {
      console.log(error);
    }
  }
  showMonthList() {
    try {
      let currentMonth = new Date().getMonth();
      let currentYear = new Date().getFullYear();
      let monthList = [];
      let i = 0;
      while (i < 12) {
        if (currentMonth === 12) {
          currentMonth = 0;
          currentYear++;
        }

        monthList.push({
          id: i,
          title: this.state.monthsList[currentMonth] + ' - ' + currentYear,
          month: this.state.monthsList[currentMonth],
          year: currentYear,
        });
        currentMonth++;
        i++;
      }
      return monthList;
    } catch (error) {
      console.log(error);
    }
  }
  setMins(e) {
    try {
      var val =
        !isNaN(parseInt(e.target.value)) &&
        parseInt(e.target.value) >= 0 &&
        parseInt(e.target.value) < 60
          ? parseInt(e.target.value)
          : '00';
      console.log('Selected mins :', e.target.value);
      let scheduleDate = new Date(this.state.scheduleDate);
      scheduleDate.setMinutes(e.target.value);
      this.setState({
        selectedMinutes: e.target.value,
        scheduleDate: scheduleDate,
      });
    } catch (error) {
      console.log(error);
    }
  }
  setHours(e) {
    try {
      var data = e.target.value;

      data.trim();

      if (data.length === 0) {
        this.setState({
          twelveHours: null,
          selectedHour: null,
        });
      } else if (data > 0 && data <= 12) {
        let hrs = 0;
        if (this.state.selectedAMPM[0].value === 'am') {
          hrs = data;
          if (parseInt(data) === 12) {
            hrs = 12;
          }
        }

        if (this.state.selectedAMPM[0].value === 'pm') {
          hrs = parseInt(data) + 12;
          if (parseInt(data) === 12) {
            hrs = 24;
          }
        }
        let scheduleDate = new Date(this.state.scheduleDate);
        scheduleDate.setHours(hrs);
        if (data.length > 0) {
          this.setState({
            twelveHours: data,
            selectedHour: hrs,
            scheduleDate: scheduleDate,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async setMonth(obj) {
    try {
      let selectedMonth = this.state.monthsList.indexOf(obj[0].month);
      let selectedYear = obj[0].year;
      let currentDate = this.state.selectedDate;

      let datesList = this.setDateList({
        year: selectedYear,
        month: selectedMonth,
        currentDate: currentDate,
      });
      let scheduleDate = new Date(this.state.scheduleDate);
      scheduleDate.setMonth(selectedMonth);
      scheduleDate.setFullYear(selectedYear);
      await this.setState({
        selectedMonth: selectedMonth,
        selectedYear: selectedYear,
        dates: datesList,
        scheduleDate: scheduleDate,
      });
    } catch (error) {
      console.log(error);
    }
  }
  setAMDefaultValue() {
    try {
      return this.state.selectedAMPM;
    } catch (error) {
      console.log(error);
    }
  }
  setMonthDefaultValue() {
    try {
      return this.showMonthList()[0];
    } catch (error) {
      console.log(error);
    }
  }
  set12Hours(val) {
    try {
      var hrsInput = this.state.selectedHour;
      var hours = 0;
      if (val[0].value === 'pm') {
        if (hrsInput === 12) {
          hours = 0;
        } else if (hrsInput < 12) {
          hrsInput = parseInt(hrsInput) + 12;
          hours = hrsInput;
        } else {
          hours = hrsInput;
        }
      } else if (val[0].value === 'am') {
        if (hrsInput === 12) {
          hours = 12;
        } else if (hrsInput > 12) {
          hrsInput = parseInt(hrsInput) - 12;
          hours = hrsInput;
        } else {
          hours = hrsInput;
        }
      }

      return hours;
    } catch (error) {
      console.log(error);
    }
  }
  setAMPM(val) {
    try {
      var convertedHour = this.set12Hours(val);
      this.setState({
        selectedAMPM: val,
        selectedHour: convertedHour,
      });
    } catch (error) {
      console.log(error);
    }
  }

  hidePanel = (value) => {
    try {
      this.props.onStatus(value);
    } catch (error) {
      console.log(error);
    }
  };
  setDateClass(item) {
    let todayDate = new Date();

    let itemDate = new Date();
    itemDate.setFullYear(item.year);
    itemDate.setMonth(item.month);
    itemDate.setDate(item.date);
    itemDate.setHours(this.state.selectedHour);
    itemDate.setMinutes(this.state.selectedMinutes);
    itemDate.setSeconds(0);

    let currentClass =
      todayDate.toString() === itemDate.toString() ? ' current ' : '';
    let activeClass;
    if (item.active && item.monthOf === 'current') {
      activeClass = 'active';
    } else {
      activeClass = 'disabled';
    }
    let selectedDate =
      itemDate.toString() === this.state.scheduleDate.toString()
        ? ' selected '
        : '';
    return currentClass + activeClass + selectedDate;
  }
  async selectDate(obj) {
    try {
      let scheduleDate = this.state.scheduleDate;
      scheduleDate.setDate(obj.date);
      scheduleDate.setHours(this.state.selectedHour);
      scheduleDate.setMinutes(this.state.selectedMinutes);
      scheduleDate.setSeconds(0);

      let day = this.state.dayList[obj.day].slice(0, 3);
      await this.setState({
        selectedDate: obj.date,
        selectedDay: day,
        choosedDate: obj.date,
        scheduleDate: scheduleDate,
      });
    } catch (error) {
      console.log(error);
    }
  }

  getSemiMonth(val) {
    try {
      return this.state.monthsList[val].slice(0, 3);
    } catch (err) {
      console.log(err);
    }
  }
  passMinute(val) {
    let min;
    if (val.length === 1) {
      min = val + '0';
    } else {
      min = val;
    }

    return min;
  }
  setSchedulePost() {
    try {
      let freshDate = new Date();
      let scheduleDate = new Date(this.state.scheduleDate);
      /*
      if (this.props.name === 'createpost') {
        scheduleDate.setMinutes(this.getUTCMinutes());
      }
      */

      scheduleDate.setSeconds(0);
      scheduleDate.setMilliseconds(0);

      let newDate = new Date();
      /*
      if (this.props.name === 'createpost') {
        newDate.setMinutes(this.getUTCMinutes());
      }
      */
      // newDate.setMinutes(this.getUTCMinutes());
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);

      if (this.state.selectedHour) {
        if (this.state.selectedHour.length === 0) {
          this.props.onAlert('Scheduled Hour is required to fill ');
          return false;
        }
      } else {
        this.props.onAlert('Scheduled Hour is required to fill ');
        return false;
      }

      if (scheduleDate < newDate) {
        this.props.onAlert('Schedule date must not be less than current date');
        return false;
      }

      scheduleDate.setHours(this.state.selectedHour);
      scheduleDate.setMinutes(this.passMinute(this.state.selectedMinutes));
      scheduleDate.setSeconds(0);

      this.props.onScheduled(
        scheduleDate,
        this.state.title === 'Start Schedule'
          ? 'start'
          : this.state.title === 'End Schedule'
          ? 'close'
          : null,
      );
    } catch (error) {
      console.log(error);
    }
  }
  showMinutes(data){
    try{     
      console.log("value :", data);
      var val = parseInt(data);
      console.log(val);
      if(val<10 && data.length==2){       
        return data;
      }else if(val<10 && data.length==1){       
        return val+"0";
      }else if(val === 0 || data.length===0){        
        return "00";
      } else{        
        return val;
      }
    }catch(error){
      console.error(error)
    }
  }
  setTwelveHours(val) {
    try {
      let hrs = val;
      if (this.state.selectedAMPM[0].value === 'pm' && hrs >= 12) {
        hrs = parseInt(val) - 12;
      }

      if (this.state.selectedAMPM[0].value === 'am' && hrs <= 12) {
        hrs = parseInt(val) + 12;
      }

      return hrs;
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { btnTitle, title } = this.state;
    return (
      <>
        <div id="schedulePicker">
          {title ? <h3>{title}</h3> : ''}
          <div className="calendarConfig">
            <div className="leftConfig">
              <input
                type="text"
                onChange={this.setHours}
                maxLength="2"
                value={this.convertTwelveHours(
                  this.state.selectedHour,
                ).toString()}
                className="hour"
                ref={this.hrsRef}
              />
              <span className="colon">:</span>
              {/*
                 value={
                  this.state.selectedMinutes ? this.state.selectedMinutes : '00'
                }
                */}
              <input
                type={title != null ? 'text' : 'text'}
                maxLength="2"
                // disabled={title == null}
                onChange={this.setMins}
                value={this.convertMinutes(this.state.selectedMinutes)}
                className="minute"
                ref={this.minsRef}
              />
              {/*  values={this.setAMDefaultValue()} */}
              {this.state.selectedAMPM ? (
                <Select
                  values={this.state.selectedAMPM}
                  onChange={(value) => this.setAMPM(value)}
                  options={this.state.ampmOptions}
                  labelField="title"
                  valueField="id"
                  className="selectbox am"
                />
              ) : (
                ''
              )}
            </div>

            <Select
              values={this.state.selectedMonthValue}
              onChange={(value) => this.setMonth(value)}
              options={this.state.monthValues}
              labelField="title"
              valueField="id"
              className="selectbox month"
            />
          </div>
          <div className="displayCalendar">
            <ul className="weeks">
              {this.state.dayList.map((item, index) => (
                <li key={index}>{item.charAt(0)}</li>
              ))}
            </ul>
            <ul className="days">
              {this.state.dates.map((item) => {
                return (
                  <li
                    onClick={() => this.selectDate(item)}
                    key={item.id}
                    className={this.setDateClass(item)}
                    data-month={item.month}
                    data-year={item.year}
                  >
                    <span>{item.date}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="actionControl">
            <div className="leftSide">
              <button
                className="btn btn-secondary"
                onClick={() => this.hidePanel(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-secondary"
                onClick={() =>
                  this.props.onRemove(
                    this.state.title === 'Start Schedule'
                      ? 'start'
                      : this.state.title === 'End Schedule'
                      ? 'close'
                      : null,
                  )
                }
              >
                Remove Schedule
              </button>
            </div>
            <div className="rightSide">
              <div className="selectedDate">
               
                {this.state.title != null && this.state.title.length > 0 ? (
                  <span>
                    {' '}
                    Your users will{' '}
                    {title === 'End Schedule' ? 'stop' : 'start'} seeing the
                    poll from{' '}
                  </span>
                ) : (
                  <span>
                    Your users will{' '}
                    {title === 'End Schedule' ? 'stop' : 'start'} seeing the
                    news feed from{' '}
                  </span>
                )}
            
                <span>{this.state.selectedDay}, </span>
                <span>{this.state.selectedDate} </span>
                {this.getSemiMonth(this.state.selectedMonth)} <span>at </span>
                {this.state.twelveHours ? this.state.twelveHours : 0}
                :
                {this.state.selectedMinutes ? this.showMinutes(this.state.selectedMinutes)
                  : '00'}
                {this.state.selectedAMPM ? (
                  <span> {this.state.selectedAMPM[0].title} </span>
                ) : (
                  ''
                )}
              </div>
              <button
                className="btn btn-dark"
                onClick={() => this.setSchedulePost()}
              >
                {btnTitle}
              </button>
              {/* Disabled State : <button disabled className="btn btn-primary">Schedule Post</button> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default React.memo(SchedulePost);
