import React, { PureComponent } from 'react';
import { API } from '../api/API';
import { Global } from '../api/Global';

class ActionButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBtns: false,
      likeStatus: false,
    };
  }
  showBtn = async () => {
    try {
      await this.setState({
        showBtns: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  hideBtn = async () => {
    try {
      await this.setState({
        showBtns: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  likePost = async () => {
    try {
      // console.log('Clicked btn');
      let obj = {
        newsFeedClientId: this.props.newsFeedClientID,
        urlParams: window.location.href.split('?')[1],
      };
      // Set Working in Progress

      if (Global.socket) {
        Global.socket.emit('like', obj);

        // if (this.props.likedByUser) {
        //   console.log('hello..', this.props.likedByUser);
        //   this.setState(
        //     (prevState) => {
        //       return {
        //         likeStatus: this.props.likedByUser,
        //       };
        //     },
        //     () => {
        //       this.props.onLikedByUser(this.state.likeStatus);
        //     },
        //   );
        // }
        // Global.socket.on('like', (message) => {
        //   console.log('message.', message);
        //   const { organisationId, isRevoked } = message?.data;
        //   let arr = window.location.href.split('?')[1].split('&');
        //   console.log({ arr });
        //   let organisationToken = null;
        //   arr.map((item) => {
        //     if (item.includes('organisationId')) {
        //       let splitToken = item.split('organisationId=');
        //       organisationToken = splitToken[1];
        //     }
        //   });
        //   if (organisationId === Global.decryptToken(organisationToken)) {
        //     this.setState(
        //       (prevState) => {
        //         return {
        //           likeStatus: !isRevoked,
        //         };
        //       },
        //       () => {
        //         this.props.onLikedByUser(this.state.likeStatus);
        //       },
        //     );
        //   }
        //   console.log('id', message.data.isRevoked);
        // });
      }

      // Create WebSocket connection.
      /*const socketIO = new WebSocket('ws://127.0.0.1:8081');

      // Connection opened
      socketIO.addEventListener('like', function (event) {
        socketIO.send('Hello Server!');
      });

      // Listen for messages
      socketIO.addEventListener('like', function (event) {
        console.log('Message from server ', event.data);
      });
      */
    } catch (error) {
      console.log(error);
    }
  };
  setLike() {
    try {
      this.setState({
        likeStatus: this.props.likedByUser,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    try {
      // console.log(this.props);
      this.setLike();
    } catch (error) {
      console.log(error);
    }
  }
  isAuthorizeUser() {
    try {
      let status = true;
      let user = Global.user();

      if (user === 'learner' && !this.props.isCommentable) {
        status = false;
      }

      return status;
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { totalComments } = this.props;
    return (
      <div className="actionButtons">
        {/*   <div className="leftSide"> <button className="link"><img src="/img/thumbs.svg" alt="Like" /> <span>Like</span></button> </div> */}
        {/*   <button className="link" onMouseEnter={() => this.showBtn()}>
              <img src="/img/thumbs.svg" alt="Like" /> <span>Like</span>
            </button> */}
        <div className="leftSide">
          {!this.state.showBtns ? (
            <button className="link" onClick={() => this.likePost()}>
              {this.props.likedByUser ? (
                <img src="/img/thumbs-active-small.svg" alt="Like" />
              ) : (
                <img src="/img/thumbs.svg" alt="Like" />
              )}

              <span>Like</span>
            </button>
          ) : (
            <div className="btns" onMouseLeave={() => this.hideBtn()}>
              <button className="likeme">
                <span>Like</span>
              </button>
              <button className="interest">
                <span>Interest</span>
              </button>
              <button className="laugh">
                <span>Laugh</span>
              </button>
            </div>
          )}
        </div>
        <div className="rightSide">
          {this.isAuthorizeUser() ? (
            <button
              className="link comments"
              onClick={() => this.props.onCommentStatus(true)}
            >
              <img src="/img/comment.svg" alt="Comment" />
              <span>
                {totalComments > 0 ? totalComments : '0'}
                {totalComments > 1 ? (
                  <span className="xs-hide">Comments</span>
                ) : (
                  <span className="xs-hide">Comment</span>
                )}
              </span>
            </button>
          ) : (
            ''
          )}
          {/* 
          <button className="link">
            <img src="/img/share.svg" alt="Share" title="Share" />
            <span>Share</span>
          </button>
          */}
        </div>
      </div>
    );
  }
}

export default React.memo(ActionButtons);
