import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { Redirect } from 'react-router-dom';
import AuthService from '../services/auth.service';
import styles from '../cssmodules/login.module.scss';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: '',
      password: '',
      loading: false,
      message: '',
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });

    console.log('Email:', this.state.email);
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.email, this.state.password).then(
        () => {
          this.props.history.push('/dashboard');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        },
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return AuthService.validToken() ? (
      <Redirect to="/dashboard" />
    ) : (
      <div className={styles.authpage}>
        <div className={styles.authcontainer}>
          <div className={styles.auth}>
            <div className={styles.logo}>
              <img src={require('../olive-group-logo.png')} alt="Logo" />
            </div>

            <h1>Sign In</h1>

            <Form
              onSubmit={this.handleLogin}
              ref={(c) => {
                this.form = c;
              }}
              className={styles.bpform}
            >
              <div className="with-icon username form-group">
                <label>
                  <i className="fa fa-user-circle"></i>Email
                </label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  validations={[required]}
                  placeholder="email"
                />
              </div>
              <div className="with-icon password form-group">
                <label>
                  <i className="fa fa-lock"></i>Password
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                  placeholder="password"
                />
              </div>
              <div className="form-group alert-danger" id="errorMessage"></div>

              <button
                type="submit"
                className="center btn btn-secondary"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Sign In
              </button>
              {this.state.message && (
                <div className="form-group">
                  <div
                    className="alert alert-danger error-flash-message"
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: 'none' }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
