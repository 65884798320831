import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class NewsService {
  getAll(params = null) {
    return axios
      .get(API_URL + '/news-feeds', { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getNewsByKeyword(params = null) {
    const cancelTokenSource = axios.CancelToken.source();
    return axios
      .get(API_URL + '/news-feeds?search=' + params.search, {
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getDeleted(params = null) {
    return axios
      .get(API_URL + '/news-feeds/deleted', { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  create(
    title,
    description,
    images,
    imageNameArray,
    videos,
    videoNameArray,
    attachments,
    attachmentNameArray,
  ) {
    const data = new FormData();

    //sending images to the server
    const imageNameLength = imageNameArray.length;
    let appendCount = 0;
    for (let i = 0; i < images.length; i++) {
      for (let j = 0; j < images[i].length; j++) {
        if (
          imageNameArray.includes(images[i][j].name) &&
          appendCount !== imageNameLength
        ) {
          data.append('images', images[i][j]);
          appendCount++;
        }
      }
    }

    //sending videos to the server
    const videoNameLength = videoNameArray.length;
    let videoappendCount = 0;

    for (let k = 0; k < videos.length; k++) {
      for (let l = 0; l < videos[k].length; l++) {
        if (
          videoNameArray.includes(videos[k][l].name) &&
          videoappendCount !== videoNameLength
        ) {
          data.append('videos', videos[k][l]);
          videoappendCount++;
        }
      }
    }

    //sending attachments to the server
    const attachmentNameLength = attachmentNameArray.length;
    let attachmentappendCount = 0;

    for (let m = 0; m < attachments.length; m++) {
      for (let n = 0; n < attachments[m].length; n++) {
        if (
          attachmentNameArray.includes(attachments[m][n].name) &&
          attachmentappendCount !== attachmentNameLength
        ) {
          data.append('attachments', attachments[m][n]);
          attachmentappendCount++;
        }
      }
    }

    data.append('title', title);
    data.append('description', description);

    return axios.post(API_URL + '/news-feed/create', data).then((response) => {
      return response.data.data;
    });
  }

  update(
    id,
    title,
    description,
    images,
    imageNameArray,
    videos,
    videoNameArray,
    attachments,
    attachmentNameArray,
    deletedMedia,
  ) {
    const data = new FormData();

    //sending images to the server
    const imageNameLength = imageNameArray.length;
    let appendCount = 0;
    for (let i = 0; i < images.length; i++) {
      for (let j = 0; j < images[i].length; j++) {
        if (
          imageNameArray.includes(images[i][j].name) &&
          appendCount !== imageNameLength
        ) {
          data.append('images', images[i][j]);
          appendCount++;
        }
      }
    }

    //sending videos to the server
    const videoNameLength = videoNameArray.length;
    let videoappendCount = 0;

    for (let k = 0; k < videos.length; k++) {
      for (let l = 0; l < videos[k].length; l++) {
        if (
          videoNameArray.includes(videos[k][l].name) &&
          videoappendCount !== videoNameLength
        ) {
          data.append('videos', videos[k][l]);
          videoappendCount++;
        }
      }
    }

    //sending attachments to the server
    const attachmentNameLength = attachmentNameArray.length;
    let attachmentappendCount = 0;

    for (let m = 0; m < attachments.length; m++) {
      for (let n = 0; n < attachments[m].length; n++) {
        if (
          attachmentNameArray.includes(attachments[m][n].name) &&
          attachmentappendCount !== attachmentNameLength
        ) {
          data.append('attachments', attachments[m][n]);
          attachmentappendCount++;
        }
      }
    }

    data.append('title', title);
    data.append('description', description);
    for (let p = 0; p < deletedMedia.length; p++) {
      data.append('deletedMedia', deletedMedia[p]);
    }

    return axios
      .post(API_URL + '/news-feed/' + id + '/update', data)
      .then((response) => {
        return response.data.data;
      });
  }

  readMore(id) {
    return axios
      .get(API_URL + '/news-feed/' + id)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getPublishedClientByNews(id) {
    return axios
      .get(API_URL + '/news-feed/' + id + '/published-clients')
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  publishNewsToClient(
    id,
    clientId,
    tenantId,
    assign_option,
    publish_option,
    assignDate = null,
    publishDate = null,
    selectedOrganisation = null,
    groups
  ) {
    let fields = {
      id,
      clientId,
      tenantId,
    };

    if (assign_option == 'assignNow') {
      fields.assignNow = 1;
    }
    if (groups) {
      fields.group = groups;
    }
    if (publish_option == 'publishNow') {
      fields.publishNow = 1;
    }

    if (assign_option == 'assignLater') {
      fields.assignLaterDate = assignDate;
    }

    if (publish_option == 'publishLater' && publishDate != '') {
      fields.publishLaterDate = publishDate;
    }

    if (selectedOrganisation) {
      let organisation = [];

      for (let i = 0; i < selectedOrganisation.length; i++) {
        delete selectedOrganisation[i].assign_option;
        delete selectedOrganisation[i].publish_option;
        organisation[i] = selectedOrganisation[i];
      }

      fields.organisation = organisation;
    }

    return axios
      .post(API_URL + '/news-feed/' + id + '/publish', fields)
      .then((response) => {
        return response.data.data;
      });
  }

  unpublishNewsToClient(id, clientId) {
    return axios
      .post(API_URL + '/news-feed/' + id + '/unpublish', {
        clientId,
      })
      .then((response) => {
        return response.data.data;
      });
  }

  restoreNewsToClient(id, clientId) {
    return axios
      .post(API_URL + '/news-feed/' + id + '/republish', {
        clientId,
      })
      .then((response) => {
        return response.data.data;
      });
  }

  getunPublishedClientsByNews(id) {
    return axios
      .get(API_URL + '/news-feed/' + id + '/un')
      .then((response) => {
        return response.data.data;
      });
  }

  publishNewsToClientEdit(payload) {
    return axios
      .post(
        API_URL + '/news-feed/' + payload.id + '/update-published-clients',
        payload,
      )
      .then((response) => {
        return response.data.data;
      });
  }

  deleteNews(id) {
    return axios
      .post(API_URL + '/news-feed/' + id + '/delete')
      .then((response) => {
        return response.data.data;
      });
  }

  restoreNews(id) {
    return axios
      .post(API_URL + '/news-feed/' + id + '/undelete')
      .then((response) => {
        return response.data.data;
      });
  }

  getNewsFeedFromUrl(params) {
    return axios
      .get(API_URL + '/v1/news-feeds', { params: params })
      .then((response) => {
        return response.data;
      });
  }
   pinToogleNews(payload){        
      console.log(payload);
      let pinStatus = payload.status === true ? "pin" :"unpin";
      return new Promise((resolve,reject)=>{
        return axios ({
          method:'get',
          url: process.env.REACT_APP_API_URL +
          '/news-feeds/'+payload.id+'/'+pinStatus+'?pinnedBy=system',        
          headers: { 'Content-Type': 'application/json' }
        }).then(result=>resolve(result)).catch(error=>reject(error))
      })
    
  }
}

export default new NewsService();
