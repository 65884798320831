import React, { PureComponent } from 'react';

class VerticalTileGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.image1Ref = React.createRef();
    this.image2Ref = React.createRef();
    this.state = {
      remainder: 0,
      imageAspectRatio: '',
      image1AspectRatio: '',
      image2AspectRatio: '',
    };
  }
  componentDidMount() {
    try {
      let remainder = this.props.items.length - 3;
      this.setState({
        remainder: remainder,
      });
      this.calculateImgAspectRatio();
      this.calculateImg1AspectRatio();
      this.calculateImg2AspectRatio();
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.calculateImgAspectRatio();
      this.calculateImg1AspectRatio();
      this.calculateImg2AspectRatio();
    }
  }

  showMask(item) {
    try {
      let obj = {
        type: item.type,
        src: item.src,
      };

      return this.state.remainder > 0 ? (
        <div
          className="mask higher"
          onClick={(e) => this.props.onShowBigImg(e, obj)}
        >
          +{this.state.remainder}
        </div>
      ) : (
        ''
      );
    } catch (error) {
      console.log(error);
    }
  }
  showItem(item) {
    try {
      let obj = {
        type: item.type,
        src: item.src,
      };
      return item.type === 'image' ? (
        <>
          <img
            src={item.src}
            className="pic"
            ref={this.imageRef}
            style={{ aspectRatio: this.state.imageAspectRatio }}
            alt={item.name}
            onClick={(e) => this.props.onShowBigImg(e, obj)}
          />
          <img
            src={item.src}
            className="bgImage"
            onClick={(e) => this.props.onShowBigImg(e, obj)}
            alt="bg pic"
            style={{ aspectRatio: this.state.imageAspectRatio }}
            ref={this.imageRef}
          />
        </>
      ) : (
        <>
          <div
            className="mask"
            onClick={(e) => this.props.onShowBigImg(e, obj)}
          >
            <img
              className="img"
              alt="Play"
              ref={this.image1Ref}
              style={{
                aspectRatio: this.state.image1AspectRatio,
              }}
              src="/img/play-light-2-pt.svg"
            />
          </div>
          <img
            className="pic"
            alt={item.name}
            ref={this.image2Ref}
            style={{ aspectRatio: this.state.image2AspectRatio }}
            src={item.videoThumbnailUrl}
          />
          <img
            className="bgImage"
            alt={item.name}
            ref={this.image2Ref}
            style={{ aspectRatio: this.state.image2AspectRatio }}
            src={item.videoThumbnailUrl}
          />
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }

  calculateImgAspectRatio = () => {
    if (this.imageRef.current) {
      const imageSrc = this.imageRef.current.src;

      const newImg = new Image();
      newImg.src = imageSrc;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ imageAspectRatio: aspectRatio });
      };
    }
  };

  calculateImg1AspectRatio = () => {
    if (this.image1Ref.current) {
      const img1Src = this.image1Ref.current.src;

      const newImg = new Image();
      newImg.src = img1Src;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ image1AspectRatio: aspectRatio });
      };
    }
  };
  calculateImg2AspectRatio = () => {
    if (this.image2Ref.current) {
      const img2Src = this.image2Ref.current.src;

      const newImg = new Image();
      newImg.src = img2Src;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ image2AspectRatio: aspectRatio });
      };
    }
  };
  render() {
    return (
      <>
        {this.props.items.length > 0 ? (
          <div className="gallery vtile">
            <div className="firstTile">
              {this.showItem(this.props.items[0])}
            </div>
            {this.props.items.length > 1 ? (
              <div className="tileRow">
                <div className="secondTile">
                  {this.showItem(this.props.items[1])}
                </div>
                {this.props.items.length > 2 ? (
                  <div className="thirdTile">
                    {this.showMask(this.props.items[2])}
                    {this.showItem(this.props.items[2])}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}
export default React.memo(VerticalTileGallery);
