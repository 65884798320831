import React from "react"
import Moment from 'react-moment';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
const today = Moment.now;
const disablePresentDt = (current) => {
    return current.isAfter(today);
  };
export default function OrgBlock (props){
    const {obj,onAssign,onPublish,onAssignDate,onPublishDate} = props;
    console.log(obj)
    return <div className={`form-group org-list  ${obj.status ? '' : 'disabled'}`}>
    <div className="row">
      <div className="col-md-12">
        <h5>{obj?.organisation ? obj?.organisation.name : ''}</h5>
      </div>
    </div>

    <div className="row elements">
      <div className="col-md-6">
        <label>Assign Option</label>
        <br></br>
        <select
          className="form-control"
          value={obj.organisation.isAssigned ? "assignNow" : "assignLater"}
          onChange={(e) => onAssign(e, obj.index)}
        >
          <option value="assignNow">Assign Now</option>
          <option value="assignLater">Assign Later</option>
        </select>
      </div>
    {/*  onChange={(e) => this.changeAssignOptionForOrg(e, index)} */}
      <div className="col-md-6">
        <label>Publish Option</label>
        <br></br>

        <select
          className="form-control"
         
          value={obj.organisation.isPublished ? "publishNow" : "publishLater"}
          onChange={(e) => onPublish(e, obj.index)}
        >

          {
            obj.organisation.isAssigned ? <> <option value="publishNow">Publish now</option>         
            <option value="publishLater">Publish Later</option></>  : <option value="publishLater">Publish Later</option>
          }
         
           
         
        </select>
      </div>
    </div>
    {obj.organisation?.assign_option == 'assignLater' && obj.status !== false && (
      <div className="row">
        <div className="col-md-12">
          <label>Assign Later date</label>
          <Datetime
            isValidDate={disablePresentDt}
            onChange={(e) => onAssignDate(e, obj.index)}
            placeholder="Select date and time"
            value={obj.organisation?.assignLaterDate ? obj.organisation?.assignLaterDate : '' }
          />
        </div>
      </div>
    )}
    {/*   onChange={(e) => this.changeAssignLaterDateForOrg(e, index)} */}

    {(obj.organisation?.publish_option == 'publishLater' || obj.organisation?.isPublished === false) && obj.status !== false &&(
      <div className="row">
        <div className="col-md-12">
          <label>Publish Later date</label>
          <Datetime
            isValidDate={disablePresentDt}
            onChange={(e) => onPublishDate(e, obj.index)}
            placeholder="Select date and time"
       
            value={obj.organisation?.publishLaterDate ? obj.organisation?.publishLaterDate :'' }
          />
        </div>
      </div>
    )}
    {/*  onChange={(e) => this.changePublishLaterDateForOrg(e, index)} */}
  </div>
}