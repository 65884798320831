import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class ClientService {
  getAll() {
    return axios
      .get(API_URL + '/clients')
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  create(payload) {
    let bodyFormData = new FormData();
    bodyFormData.append('icon', payload.icon);
    bodyFormData.append('name', payload.name);
    return axios({
      method: 'post',
      url: API_URL + '/clients/create',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: bodyFormData,
    }).then((response) => {
      return response.data.data;
    });
  }

  update(payload) {
    /*
    return axios
      .post(API_URL + '/client/update', {
        payload.id,
        payload.name,
      })
      .then((response) => {
        return response.data.data;
      });
      */
    let bodyFormData = new FormData();
    bodyFormData.append('icon', payload.icon);
    bodyFormData.append('name', payload.name);
    bodyFormData.append('id', payload.id);
    return axios({
      method: 'post',
      url: API_URL + '/client/update',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: bodyFormData,
    }).then((response) => {
      return response.data.data;
    });
  }

  delete(id) {
    return axios
      .get(API_URL + '/clients/' + id + '/delete')
      .then((response) => {
        return response.data.data;
      });
  }

  undelete(id) {
    return axios
      .get(API_URL + '/clients/' + id + '/undelete')
      .then((response) => {
        return response.data.data;
      });
  }

  getAllDeleted() {
    return axios.get(API_URL + '/client/deleted').then((response) => {
      return response.data.data;
    });
  }

  getAllValid() {
    return axios
      .get(API_URL + '/clients/active-non-deleted')
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default new ClientService();
