import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/*

Props
=======
msg : String | required

Events
=======
onOK : Clicked on OK Button
onCance; : Clicked on Cancled Button


*/
class ConfirmBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      msg: 'Default Confirm Title',
    };
  }

  componentDidMount() {
    try {
      this.setState({
        msg: this.props.msg,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <section className="confirmBox">
        <div className="box">
          <header>
            <h2>{this.state.msg}</h2>
          </header>
          <div className="actionBtn">
            <button className="btn btn-dark" onClick={() => this.props.onOK()}>
              OK{' '}
            </button>
            <button
              onClick={() => this.props.onCancel()}
              className="btn btn-secondary"
            >
              {' '}
              Cancel
            </button>
          </div>
        </div>
      </section>
    );
  }
}

ConfirmBox.propTypes = {
  msg: PropTypes.string.isRequired,
  onOK: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(ConfirmBox);
