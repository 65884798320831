import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "/auth/signin", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  validToken() {
    const user = this.getCurrentUser();
    if(!user) return false; 
    const token = user.accessToken;
    if(!token) return false;
    axios.defaults.headers.common['access-token'] = token;
    return axios
    .get(API_URL + "/user/profile")
    .then(response => {
       return true;
    }).catch(error => {
      this.logout();
      return false;
    });
  }
}

export default new AuthService();