import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
class RoleList extends PureComponent {
  state = {
    showSubmenu: false,
    selectedOption: null,
    options: [],
  };
  setSelectedOption = (val) => {
    try {
      let options = [...this.state.options];
      let obj = options.find((item) => item.id === val);
      let index = options.indexOf(obj);
      this.setState(
        (prevState) => {
          return {
            selectedOption: prevState.options[index],
          };
        },
        () => {
          this.props.onSelect(obj);
        },
      );
    } catch (err) {
      console.log(err);
    }
  };
  toggleSubmenu = () => {
    try {
      return this.state.showSubmenu
        ? this.setState({ showSubmenu: false })
        : this.setState({ showSubmenu: true });
    } catch (err) {
      console.log(err);
    }
  };
  showSubmenu() {
    try {
      return (
        <div className="dropList" onMouseLeave={() => this.toggleSubmenu()}>
          {this.state.options.map((item) => {
            return (
              <div
                className="item"
                key={item.id}
                onClick={() => this.setSelectedOption(item.id)}
              >
                <span className="alphabets">
                  {item.img ? <img src={item.img} alt="Alphabet" /> : item.tiny}
                </span>
                <span className="txt">{item.title}</span>
              </div>
            );
          })}
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    let selectedOption = this.props.hasOwnProperty('value')
      ? this.props.value
        ? this.props.value
        : this.props.options[0]
      : this.props.options[0];
    this.setState((prevState) => {
      return {
        options: this.props.options,
        selectedOption: selectedOption,
      };
    });
  }
  render() {
    const { options, selectedOption, showSubmenu } = this.state;
    return (
      <div className="dropDown" onClick={() => this.toggleSubmenu()}>
        {selectedOption != null ? (
          <div className="selectedItem">
            <span className="alphabets">
              {selectedOption.img ? (
                <img src={selectedOption.img} alt="Alphabet" />
              ) : (
                selectedOption.tiny
              )}
            </span>
            <span className="rightside">
              <span className="txt">{selectedOption.title}</span>{' '}
              <img src="/img/dropdown.svg" />
            </span>
          </div>
        ) : (
          ''
        )}
        {showSubmenu && options.length > 0 ? this.showSubmenu() : ''}
      </div>
    );
  }
}
RoleList.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default React.memo(RoleList);
