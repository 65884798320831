import React, { Component } from 'react';

import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';

import TenantService from '../services/tenant.service';
import Loader from '../components/Loader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import pageHeader from '../cssmodules/pageheader.module.scss';
export default class DeletedTenant extends Component {
  constructor(props) {
    super(props);

    this.getParticularTenant = this.getParticularTenant.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.restoreTenant = this.restoreTenant.bind(this);

    this.changeStatus = this.changeStatus.bind(this);
    this.state = {
      tenants: [],
      activeIndex: 0,
      particularTenant: {},
      loading: true,
      deleteLoading: false,
      showModal: false,
      errmessage: '',
      successmessage: '',
      clientName: '',
    };
  }

  async componentDidMount() {
    let allTenants = await TenantService.getAllDeleted(
      this.props.match.params.clientId,
    );
    console.log('All Tenants', allTenants);
    this.setState({
      tenants: allTenants.tenants,
      particularTenant: allTenants.tenants[0] ? allTenants.tenants[0] : {},

      loading: false,

      clientName: allTenants.clientname,
    });
  }

  getParticularTenant(index) {
    this.setState({
      activeIndex: index,
      particularTenant: this.state.tenants[index],
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
      deleteLoading: false,
      errmessage: '',
    });
  }

  fadeOut() {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      2500,
    );
  }

  changeStatus(e) {
    if (e.target.value === 'active') {
      this.props.history.push(
        '/clients/' + this.props.match.params.clientId + '/tenants',
      );
    }
  }

  restoreTenant() {
    this.setState({
      deleteLoading: true,
    });

    TenantService.undelete(this.state.particularTenant._id).then(
      (response) => {
        let tenant = [...this.state.tenants];
        let tenantFiltered = tenant.filter(
          (tenant) => tenant._id !== this.state.particularTenant._id,
        );
        this.setState({
          successmessage: `Tenant "${this.state.particularTenant.name}" restored successfully`,
          deleteLoading: false,
          tenants: tenantFiltered,
          showModal: !this.state.showModal,
          particularTenant: tenantFiltered[0] ? tenantFiltered[0] : {},
          activeIndex: 0,
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          deleteLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  render() {
    const {
      clientName,
      tenants,
      activeIndex,
      particularTenant,
      loading,
    } = this.state;

    return (
      <div>
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>Deleted Tenants</h1>
                <div className={pageHeader.rightSide}>
                  <select
                    name="status"
                    className="form-control status"
                    onChange={this.changeStatus}
                    value="deleted"
                  >
                    <option value="active">Active</option>
                    <option value="deleted">Deleted</option>
                  </select>
                </div>
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-4 col-md-3 col-xs-12 ">
                  <div className="list-group">
                    {tenants.map((tenant, index) => (
                      <>
                        <div
                          className="listbox"
                          onClick={() => this.getParticularTenant(index)}
                        >
                          <div className="clienticon">
                            <img src={require('../user.svg')} alt="Client" />
                          </div>
                          <div className="clientinfo">
                            <h5> {tenant.name}</h5>
                            <div className="actionBtns">
                              {' '}
                              <button
                                className="iconBtn"
                                onClick={() => this.toggleModal()}
                              >
                                <i className="fa fa-undo"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Restore Tenant </ModalHeader>

                <ModalBody>
                  Are you sure to restore the tenant "
                  {this.state.particularTenant.name}"
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.deleteLoading}
                      onClick={this.restoreTenant}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.deleteLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleModal}>
                      <i class="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
