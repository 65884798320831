import React, { Component } from 'react';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import { Link } from 'react-router-dom';
import '../sass/admin/dashboard.scss';
import AuthService from '../services/auth.service';
import pageHeader from '../cssmodules/pageheader.module.scss';
export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
    };
  }
  signout() {
    AuthService.logout();
  }
  componentDidMount() {
    let username = AuthService.getCurrentUser().name;

    this.setState({
      username: username,
    });
  }
  render() {
    return (
      <div id="dashboard">
        <Header history={this.props.history} />

        <div className="bulbs">
          <div className="user">
            <a
              href="#"
              id="user"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                width="150"
                height="150"
                src="/img/whitelogo.svg"
                alt="Profile"
              />
              <span>{this.state.username}</span>
            </a>
          </div>
          <div className="navigation">
            <div className="container">
              <ul className="row">
                <li className="col-sm-4">
                  <Link to="/clients" className="nav-link">
                    <img src="/img/avatar.svg" width="30" height="30" />
                    <span>Clients</span>
                  </Link>
                </li>
                <li className="col-sm-4">
                  <Link to="/news" className="nav-link">
                    <img src="/img/paper.svg" width="30" height="30" />
                    <span> News</span>
                  </Link>
                </li>

                <li className="col-sm-4">
                  <a className="nav-link" onClick={this.signout} href="/">
                    <img src="/img/logout.svg" width="30" height="30" />
                    <span>Signout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
