import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
class LogService {
  async getAll(params = null) {
    try {
      let response = await axios.get(API_URL + '/logs', { params: params });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
export default new LogService();
