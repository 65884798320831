import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PropTypes from 'prop-types';
import { Global } from '../api/Global';

class EditCommentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.commentBox = React.createRef();
    this.sendBtn = React.createRef();
    this.state = {
      id: null,
      msg: null,
    };
  }
  componentDidMount() {
    try {
      this.setState({
        id: this.props.id,
        msg: this.props.msg,
      });

      if (Global.socket) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  setMessage() {
    try {
      this.setState({
        msg: this.commentBox.current.innerText,
      });
    } catch (error) {
      console.log(error);
    }
  }
  editComment() {
    try {
      let obj = {
        id: this.state.id,
        commentDescription: this.state.msg,
        urlParams: Global.urlParams,
      };

      Global.socket.emit('updateComment', obj);
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { id, msg } = this.state;
    return (
      <div className="commentForm">
        <div className="leftSide">
          <div className="img"></div>
          {/* <div
            ref={this.commentBox}
            className="comment"
            placeholder="Write your comment here"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onInput={() => this.setMessage()}
          >
            {this.props.msg}
          </div> */}
          <input
          ref={this.commentBox}
          className="comment"
          placeholder="Comment here..."
          
          onChange={() => this.setMessage()}
          value={this.props.msg}
          />
        </div>
        <div className="rightSide">
          {/*  onClick={() => this.props.onEditComment({ id: id, msg: msg })} */}
          <button
            onClick={() => this.editComment()}
            className="sendComment btn btn-dark"
            disabled={msg && msg.length > 0 ? '' : 'disabled'}
          >
            Update
          </button>
        </div>
      </div>
    );
  }
}

export default React.memo(EditCommentForm);
