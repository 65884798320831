import React, { PureComponent } from 'react';
import '../sass/newsfeed.scss';
import SchedulePostList from '../components/newsfeed/SchedulePostList';
import CreatePost from '../components/newsfeed/CreatePost';
import PostList from '../components/newsfeed/PostList';
import Loader from '../components/newsfeed/ui/Loader';
import AlertBox from '../components/newsfeed/ui/AlertBox';
import ErrorBoundary from '../components/newsfeed/ErrorBoundary';
import { API } from '../components/newsfeed/api/API';
import { Global } from '../components/newsfeed/api/Global';

class NewsFeeds extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listPostAgain: false,
      newPost: null,
      isLoading: false,
      postList: [],
      alertBoxStatus: false,
      alertMsg: '',
      page: 1,
      totalPages: null,
      infinitPostAPI: false,
      totalNoSchedulePosts: 0,
      hasUserInfo: false,
      loadDatas: true,
    };
    this.observer = null;
    this.lastDivRef = React.createRef();
    this.scrollToRef = React.createRef(); // Add a reference for the scroll position
  }

  showAlertBox(data) {
    this.setState({
      alertMsg: data,
      alertBoxStatus: true,
    });
  }

  hideAlertBox() {
    this.setState({
      alertMsg: '',
      alertBoxStatus: false,
    });
  }

  setLoading(val) {
    this.setState({
      isLoading: val,
    });
  }

  addPostList = () => {
    const currentPostCount = this.state.postList.length;
    this.setState(
      (prevState) => ({ page: prevState.page + 1 }),
      () => {
        this.setLoading(true);
        API.getPostList({ page: this.state.page })
          .then((response) => {
            const data = response.data.data;
            const mydata = data.map((item) => Global.createNewsFeedObj(item));
            const postList = [...this.state.postList, ...mydata];
            this.setState(
              {
                postList,
                meta: response.data.meta,
                infinitPostAPI: false,
              },
              () => {
                const firstNewPost = document.getElementById(
                  `post-${currentPostCount}`,
                );
                if (firstNewPost) {
                  firstNewPost.scrollIntoView({ behavior: 'smooth' });
                }
              },
            );
            this.setLoading(false);
            document.body.style.overflow = 'auto';
          })
          .catch((error) => {
            console.log(error);
            this.setLoading(false);
            this.setState({ infinitPostAPI: false });
          });
      },
    );
  };

  async componentDidMount() {
    this.setLoading(true);
    if (Global.user() !== 'learner') {
      await API.getTotalNoSchedulePosts()
        .then((response) => {
          if (response.data && response.data.total) {
            this.setState({
              totalNoSchedulePosts: parseInt(response.data.total, 10),
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    await API.getPostList()
      .then((response) => {
        if (response && response.data && response.data.data) {
          const data = response.data.data;

          const mydata = data.map((item) => Global.createNewsFeedObj(item));

          this.setState({
            postList: mydata,
            isLoading: false,
            meta: response.data.meta,
          });
        } else {
          this.showAlertBox('Something went wrong');
          this.setLoading(false);
        }
      })
      .catch((error) => {
        this.showAlertBox(error);
        this.setLoading(false);
      });

    // Set up Intersection Observer
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.state.infinitPostAPI) {
            this.setState({ infinitPostAPI: true });
            if (this.state.meta && this.state.meta.pages > this.state.page) {
              this.addPostList();
            }
          }
        });
      },
      { threshold: 0.5 },
    );

    if (this.lastDivRef.current) {
      this.observer.observe(this.lastDivRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer && this.lastDivRef.current) {
      this.observer.unobserve(this.lastDivRef.current);
    }
  }

  async addPost(obj) {
    let postList = [obj, ...this.state.postList];
    this.setState({ postList });
  }

  fetchAllPost() {
    this.setLoading(true);
    this.setState({ postList: [] });
    API.getPostList()
      .then((response) => {
        this.setLoading(false);
        if (response && response.data && response.data.data) {
          const data = response.data.data;
          const mydata = data.map((item) => Global.createNewsFeedObj(item));
          this.setState({
            postList: mydata,
            isLoading: false,
            meta: response.data.meta,
          });
        } else {
          this.showAlertBox('Something went wrong');
          this.setLoading(false);
        }
      })
      .catch((error) => {
        this.showAlertBox(error);
        this.setLoading(false);
      });
  }

  render() {
    return (
      <ErrorBoundary>
        <div id="newsfeed">
          {this.state.isLoading && <Loader />}

          {this.state.alertBoxStatus && (
            <AlertBox
              msg={this.state.alertMsg}
              onAlert={(data) => this.showAlertBox(data)}
              onOK={() => this.hideAlertBox()}
            />
          )}

          {Global.user() === 'admin' && (
            <CreatePost
              onLoading={(val) => this.setLoading(val)}
              onAlert={(val) => this.showAlertBox(val)}
              onOK={() => this.hideAlertBox()}
              onAddPost={(obj) => this.addPost(obj)}
            />
          )}

          {Global.user() === 'admin' && this.state.totalNoSchedulePosts > 0 && (
            <SchedulePostList
              onLoading={(val) => this.setLoading(val)}
              onAlert={(val) => this.showAlertBox(val)}
              onOK={() => this.hideAlertBox()}
              total={this.state?.totalNoSchedulePosts}
            />
          )}

          {this.state.postList.length > 0 && (
            <PostList
              listPostAgain={this.state.listPostAgain}
              postList={this.state.postList}
              onLoading={(val) => this.setLoading(val)}
              onAlert={(data) => this.showAlertBox(data)}
              onOK={() => this.hideAlertBox()}
              meta={this.state.meta}
              postRef={this.lastDivRef}
              onCrawlPostList={() => this.fetchAllPost()}
              loading={this.state.isLoading}
              key="23456789"
            />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

export default React.memo(NewsFeeds);
