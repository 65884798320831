import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CommentOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.commentBox = React.createRef();
    this.sendBtn = React.createRef();
    this.state = {
      id: null,
    };
  }
  componentDidMount() {
    this.setState({
      id: this.props.id,
    });
  }
  render() {
    const { id } = this.state;
    return (
      <div className="optionsPop">
        <h4>Comment Options</h4>
        <div className="item">
          <button
            className="linkonly"
            onClick={() => this.props.onRemoveComment(id)}
          >
            Remove Comment
          </button>
        </div>
        {/* <div className="item">
          <button
            className="linkonly"
            onClick={() => this.props.onEditCommentStatus(true)}
          >
            Edit Comment{' '}
          </button>
        </div> */}
      </div>
    );
  }
}

CommentOptions.propTypes = {
  id: PropTypes.string.isRequired,
  onRemoveComment: PropTypes.func.isRequired,
  onEditCommentStatus: PropTypes.func.isRequired,
};

export default React.memo(CommentOptions);
