import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import CheckButton from 'react-validation/build/button';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import ClientService from '../services/client.service';
import TenantService from '../services/tenant.service';
import NewsService from '../services/news.service';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class DeletedNewsClient extends Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.restoreClient = this.restoreClient.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.state = {
      unpublishedClients: [],
      news: '',
      activeIndex: 0,
      getParticularClient: {},
      loading: true,
      createLoading: false,

      showModal: false,
      errmessage: '',
      successmessage: '',

      clientToRestore: '',
    };
  }

  async componentDidMount() {
    let news = await NewsService.readMore(this.props.match.params.newsId);

    let unpublishedClients = await NewsService.getunPublishedClientsByNews(
      this.props.match.params.newsId,
    );

    this.setState({
      loading: false,
      unpublishedClients: unpublishedClients,
      news: news,
    });
  }

  toggleModal(id, name) {
    this.setState({
      showModal: !this.state.showModal,
      clientToRestore: {
        id,
        name,
      },
      errmessage: '',
      successmessage: '',
    });
  }

  async getParticularClient(index, id, editOfId, client_name) {
    this.setState({
      activeIndex: index,
      getParticularClient: this.state.unpublishedClients[index],
    });
  }

  fadeOut() {
    setTimeout(
      function () {
        this.setState({ successmessage: '' });
      }.bind(this),
      4000,
    );
  }

  changeStatus(e) {
    if (e.target.value === 'active') {
      this.props.history.push(
        '/news/' + this.props.match.params.newsId + '/clients',
      );
    }
  }

  restoreClient() {
    this.setState({
      createLoading: true,
    });

    NewsService.restoreNewsToClient(
      this.state.news._id,
      this.state.clientToRestore.id,
    ).then(
      (response) => {
        let client = [...this.state.unpublishedClients];
        let clientFiltered = client.filter(
          (client) => client.client._id !== this.state.clientToRestore.id,
        );
        this.setState({
          successmessage: `client "${this.state.clientToRestore.name}" restored to the News "${this.state.news.title}" successfully`,
          createLoading: false,
          unpublishedClients: clientFiltered,
          showModal: !this.state.showModal,
        });

        this.fadeOut();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.data) ||
          error.message ||
          error.toString();

        this.setState({
          createLoading: false,
          errmessage: resMessage,
        });
      },
    );
  }

  render() {
    const { unpublishedClients, activeIndex, loading } = this.state;

    return (
      <div>
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Deleted clients of "{this.state.news.title}"
                </h1>
                <div className="col-md-3">
                  <select
                    name="status"
                    className="form-control status"
                    onChange={this.changeStatus}
                  >
                    <option value="active">Active</option>
                    <option value="deleted" selected>
                      Deleted
                    </option>
                  </select>
                  <Link to="/news" className="right btn btn-secondary">
                    <i className="fa fa-arrow-left"></i>Back to news
                  </Link>
                </div>
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-3 client-list">
                  <ul className="list-group">
                    {unpublishedClients.map((client, index) => (
                      <>
                        <li
                          className={`list-group-item ${
                            index == activeIndex ? 'active' : ''
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-2 client-icon">
                              <img src={require('../user.svg')} alt="Client" />
                            </div>
                            <div className="col-md-10">
                              <h5> {client.client.name}</h5>
                              <p>
                                <button
                                  className="right btn btn-secondary "
                                  onClick={() =>
                                    this.toggleModal(
                                      client.client._id,
                                      client.client.name,
                                    )
                                  }
                                >
                                  <i class="fa fa-undo" aria-hidden="true"></i>
                                  Restore
                                </button>
                              </p>
                            </div>
                          </div>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>

              <Modal
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
                className="modal"
              >
                <ModalHeader>Restore Client to the news</ModalHeader>

                <ModalBody>
                  Are you sure to restore the client "
                  {this.state.clientToRestore.name}" to the news "
                  {this.state.news.title}"
                  {this.state.errmessage && (
                    <div className="form-group">
                      <div
                        className="alert alert-danger error-flash-message"
                        role="alert"
                      >
                        {this.state.errmessage}
                      </div>
                    </div>
                  )}
                  {this.state.successmessage && (
                    <div className="form-group">
                      <div className="alert alert-success" role="alert">
                        {this.state.successmessage}
                      </div>
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="form-group">
                    <Button
                      color="primary"
                      disabled={this.state.createLoading}
                      onClick={this.restoreClient}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i>Ok
                      {this.state.createLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </Button>
                    <Button color="secondary" onClick={this.toggleModal}>
                      <i class="fa fa-close" aria-hidden="true"></i>Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
