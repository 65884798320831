import React, { PureComponent } from 'react';

class SinglePortait extends PureComponent {
  constructor(props) {
    super(props);
    this.image1Ref = React.createRef();
    this.image2Ref = React.createRef();
    this.videoRef = React.createRef();
    this.state = {
      image1AspectRatio: '',
      image2AspectRatio: '',
      videoAspectRatio: '',
    };
  }

  triggerSlider(e) {
    try {
      let obj = {
        type: this.props.items[0].type,
        src: this.props.items[0].src,
      };
      this.props.onShowBigImg(e, obj);
    } catch (error) {
      console.log(error);
    }
  }

  showImage(img) {
    try {
      return (
        <>
          <img
            src={img}
            alt="pic"
            ref={this.image1Ref}
            style={{ aspectRatio: this.state.image1AspectRatio }}
            onClick={(e) => this.triggerSlider()}
          />
          <img
            onClick={(e) => this.triggerSlider()}
            src={img}
            className="bgImage"
            alt="bg pic"
            ref={this.image2Ref}
            style={{ aspectRatio: this.state.image2AspectRatio }}
          />
        </>
      );
    } catch (error) {
      console.log(error);
    }
  }

  showItem(item) {
    try {
      return item.type === 'image' ? (
        this.showImage(item.src)
      ) : (
        <video width="320" height="240" controls allowfullscreen>
          {' '}
          <source
            src={item.src}
            ref={this.videoRef}
            style={{ aspectRatio: this.state.videoAspectRatio }}
            type="video/mp4"
          />
        </video>
      );
    } catch (error) {
      console.log(error);
    }
  }

  calculateImg1AspectRatio = () => {
    if (this.image1Ref.current) {
      const image1Src = this.image1Ref.current.src;

      const newImg = new Image();
      newImg.src = image1Src;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ image1AspectRatio: aspectRatio });
      };
    }
  };

  calculateImg2AspectRatio = () => {
    if (this.image2Ref.current) {
      const img2Src = this.image2Ref.current.src;

      const newImg = new Image();
      newImg.src = img2Src;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ image2AspectRatio: aspectRatio });
      };
    }
  };
  calculateVideoAspectRatio = () => {
    if (this.videoRef.current) {
      const videoSrc = this.videoRef.current.src;

      const newImg = new Image();
      newImg.src = videoSrc;

      newImg.onload = () => {
        const aspectRatio = newImg.width / newImg.height;
        this.setState({ videoAspectRatio: aspectRatio });
      };
    }
  };

  componentDidMount() {
    this.calculateImg1AspectRatio();
    this.calculateImg2AspectRatio();
    this.calculateVideoAspectRatio();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.calculateImg1AspectRatio();
      this.calculateImg2AspectRatio();
      this.calculateVideoAspectRatio();
    }
  }
  render() {
    return (
      <>
        {this.props.items && this.props.items.length === 1 ? (
          <div className="gallery singlePortait">
            <div className="firstTile">
              {this.showItem(this.props.items[0])}
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}
export default React.memo(SinglePortait);
