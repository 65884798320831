import React, { useEffect, useState } from 'react';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import pageHeader from '../cssmodules/pageheader.module.scss';
import GroupsService from '../services/group.service';

import EditGroup from '../components/groups/EditGroup';
import AddGroup from '../components/groups/AddGroup';
import GroupNameList from '../components/groups/GroupNameList';
export default function Groups() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [client, setClient] = useState(null);
  const [load, setLoad] = useState('orgList');
  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    try {
      setLoading(true);
      let groups = await GroupsService.getGroups();
      setLoading(false);
      setData(groups);

      setSelectedGroup(groups[0]);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const createHandler = () => {
    getGroups();
    setLoad('orgList');
  };
  const editHandler = (result) => {
    setLoad('orgList');
    let cloneData = [...data];
    console.log(cloneData);
    console.log(result);
    let obj = cloneData.find((item) => item._id === result._id);
    let index = cloneData.indexOf(obj);
    cloneData[index].organisation = result.organisation;
    cloneData[index].name = result.name;
   
    setData(cloneData);
    setSelectedGroup(cloneData[index]);
  };
  const deleteGroup = async (id) => {
    try {
      let payload = {
        id: id,
      };
      let result = await GroupsService.deleteGroup(payload);

      if (result?.status === 200) {
        console.log('Sucessfully deleted');
        getGroups();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const loadGroupHandler = (e) => {
    if (load === 'orgList') {
      let index = parseInt(e.currentTarget.dataset.index);
      setSelectedGroup(data[index]);
      setClient(data[index].clients[0]);
    }
  };
  const candleHandler = () => {
    setLoad('orgList');
    setSelectedGroup(data[0]);
  };
  useEffect(() => {
    setClient(selectedGroup?.clients[0]);
  }, [selectedGroup]);
  return (
    <div id="clientList">
      <Header />

      <div className="container-fluid">
        <div className="row">
          <Sidebar />

          <main
            role="main"
            className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
          >
            <div className={pageHeader.colHeader}>
              <h1 className={pageHeader.leftSide}>Groups </h1>
              <div className={pageHeader.rightSide}>
                <div></div>
                <button
                  className="right btn btn-dark rounded-0"
                  onClick={() => setLoad('addGroup')}
                >
                  Create Group
                </button>
              </div>
            </div>
            {loading === true && (
              <div className="row contHolder">
                <div className="col">
                  <div>Loading ...</div>
                </div>
              </div>
            )}
            <div className="row contHolder">
              <div className="col-md-4 contLeftSide">
                {data.length > 0 && (
                  <GroupNameList
                    loading={loading}
                    data={data}
                    onDelete={(id) => deleteGroup(id)}
                    onLoadGroup={(e) => loadGroupHandler(e)}
                  />
                )}
              </div>
              <div className="col-md-8  contRightSide">
                <div className="card">
                  <div className="card-header">
                    <div className="header">
                      <div className="row">
                        <div className="col-md-12">
                          {load !== 'addGroup' && (
                            <h4>{selectedGroup?.name} </h4>
                          )}

                          {load === 'orgList' && (
                            <button
                              className="right btn btn-secondary "
                              onClick={() => setLoad('editGroup')}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="orgList">
                      <div className="row">
                        {load != 'addGroup' && (
                          <div className="col-md-12 mb-4">
                            Clients : {}
                            {selectedGroup?.clients.map((item) => item.name)}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {load === 'orgList' &&
                          selectedGroup?.organisation.length > 0 && (
                            <div className="groupTableHolder">
                              {' '}
                              <table className="groupList">
                                <thead>
                                  <tr>
                                    <td>S.No</td>
                                    <td>Organisation ID</td>
                                    <td>Group Name</td>
                                    <td>Domain</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {load === 'orgList' &&
                                    selectedGroup?.organisation.map(
                                      (item, index) => (
                                        <tr
                                          className="col-4 mb-4"
                                          key={item.id}
                                        >
                                          <td className="sn">{index + 1}</td>
                                          <td className="id">{item.id}</td>
                                          <td className="name">{item.name}</td>
                                          <td className="domain">
                                            {item.domain ? item?.domain : ''}
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                </tbody>
                              </table>{' '}
                            </div>
                          )}

                        {load === 'addGroup' && (
                          <AddGroup
                            onCreate={createHandler}
                            name={selectedGroup?.name}
                            onCancel={candleHandler}
                          />
                        )}

                        {load === 'editGroup' && (
                          <EditGroup
                            onClose={() => setLoad('orgList')}
                            onEdit={(data) => editHandler(data)}
                            name={selectedGroup?.name}
                            orgs={selectedGroup?.organisation}
                            id={selectedGroup._id}
                            client={client}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
