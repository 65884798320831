import axios from 'axios';
import AuthService from './auth.service';
const API_URL = process.env.REACT_APP_API_GATEWAY_URL;

class MyKademyService {
  organisation(keyword) {
    delete axios.defaults.headers.common['access-token'];
    return axios
      .get(
        `https://accounts.mykademy.com/api/v1/accounts?page_limit=10&page=1&search_keyword=${keyword}&status=1`,
        {
          headers: {
            Authorization: `Bearer 77f718c324c637f2165d5c6811bc78ab1a32fd65`,
          },
        },
      )
      .then((response) => {
        const options = response.data.body.map((i) => ({
          name: i.name,
          id: i.id,
        }));
        console.log(options);
        return options;
      });
  }
  getOrg(keyword) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/news-feeds/list-mykademy-organisations?searchKeyword=${keyword}&status=1`,
      )
      .then((response) => {
        const options = response.data.data.accounts.map((i) => {
          let domain = i.acct_domain;
          let name = domain ? `${i.acct_name} (${domain})` : `${i.acct_name}`;
          return {
            name: name,
            id: i.id,
          };
        });

        return options;
      });
  }
}

export default new MyKademyService();
