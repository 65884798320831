import React, { Component } from 'react';
import Header from '../components/partials/header.component';
import Sidebar from '../components/partials/sidebar.component';
import Loader from '../components/Loader';
import Paginate from '../components/Pagination';
import LogService from '../services/log.service';
import pageHeader from '../cssmodules/pageheader.module.scss';
import Moment from 'react-moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class Log extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: [],
      loading: true,
      errmessage: '',
      successmessage: '',
      pagination: '',
      page: 1,
      showModal: false,
      createLoading: false,
      isOpen: false,
      toggle: false,
      requestLog: null,
      errorLog: null,
    };
  }

  async componentDidMount() {
    let logs = await LogService.getAll();

    this.setState({
      pagination: logs && logs.meta ? logs.meta : {},
      logs: logs && logs.data ? logs.data : [],
      loading: false,
    });
  }

  seachWithKeyword = async (e) => {
    let logs = await LogService.getAll({ q: e.target.value });
    if (logs.data) {
      this.setState({
        logs: logs.data,
        pagination: logs.meta,
      });
    }
  };

  handlePagination = async (page) => {
    this.setState({
      loading: true,
    });

    let logs = await LogService.getAll({
      page,
    });

    this.setState({
      logs: logs.data,
      pagination: logs.meta,

      loading: false,
    });
  };

  capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  toggleModal = (log = null) => {
    if (log) {
      this.setState({
        requestLog: log.request,
        errorLog: log.errorMessage,
      });
    }
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { logs, loading } = this.state;

    return (
      <div className="logs">
        <Header history={this.props.history} />
        <div className="container-fluid">
          <div className="row">
            <Sidebar history={this.props.history} />

            <main
              role="main"
              className="col-md-9 col-sm-8 ml-sm-auto col-lg-10 adminRightCol"
            >
              <div className={pageHeader.colHeader}>
                <h1 className={pageHeader.leftSide}>Logs </h1>
              </div>

              <div className={pageHeader.searchBar}>
                <input
                  type="text"
                  placeholder="Search by category & action by..."
                  onKeyUp={(e) => this.seachWithKeyword(e)}
                />
              </div>

              {loading && (
                <div className="row">
                  <Loader />
                </div>
              )}

              {this.state.successmessage && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.successmessage}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <table className="groupList">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Category</td>
                        <td>Name</td>
                        <td>Action By</td>
                        <td>Action</td>
                        <td>Date</td>
                        <td>Error</td>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.map((log, index) => (
                        <tr className="col-4 mb-4" key={log.id}>
                          <td className="sn">{index + 1}</td>
                          <td className="id">
                            {this.capitalize(log.category)}
                          </td>
                          <td className="name">
                            {this.capitalize(log.item.name)}
                          </td>
                          <td className="actionBy">
                            {this.capitalize(log.actionBy.name)}
                          </td>
                          <td className="action">
                            {this.capitalize(log.action)}
                          </td>
                          <td
                            className="created"
                            style={{ fontFamily: 'FontAwesome' }}
                          >
                            {
                              <Moment format="MMM Do YYYY, h:mm:ss a">
                                {log.createdAt}
                              </Moment>
                            }
                          </td>
                          <td>
                            {log.errorMessage && (
                              <span
                                onClick={() => {
                                  this.toggleModal(log);
                                }}
                              >
                                View
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {logs.length > 0 && (
                <div className="pagination-section">
                  <Paginate
                    pages={this.state.pagination.pages}
                    currentPage={this.state.pagination.currentPage}
                    handlePagination={this.handlePagination}
                  />
                </div>
              )}
            </main>
          </div>
        </div>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          className="modal"
        >
          <ModalHeader>Error details</ModalHeader>

          <ModalBody>
            <div className="form-group">
              Request : <pre>{this.state.requestLog}</pre>
            </div>

            {this.state.errorLog && (
              <div className="form-group">
                <div
                  className="alert alert-danger error-flash-message"
                  role="alert"
                >
                  Error <pre>{this.state.errorLog}</pre>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="form-group">
              <Button color="secondary" onClick={this.toggleModal}>
                <i className="fa fa-close" aria-hidden="true"></i>Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
