import React from "react";
function TestApp () {
    const inputHandler = (e)=>{
        e.preventDefault();
    }
    return <div>
        <input type="text" onChange={inputHandler} placeholder="Type..." />
    </div>
}

export default TestApp;