import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
class Loader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    try {
      this.setState({
        msg: this.props.msg,
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="loader">
        <img src="/img/loader.svg" alt="loading" />
      </div>
    );
  }
}

export default React.memo(Loader);
