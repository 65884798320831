import React, { PureComponent } from 'react';
import { API } from '../api/API';
import PropTypes from 'prop-types';
class AddOption extends PureComponent {
  constructor(props) {
    super(props);

    this.firstFileUpload = React.createRef();
    this.firstInput = React.createRef();

    this.state = {
      tempOption: {
        url: '',
        name: '',
        id: 0,
        order: 1,
      },
    };
  }
  async uploadFile() {
    try {
      let payload = {
        file: this.firstFileUpload.current.files[0],
      };
      this.props.onLoading(true);
      await API.fileUpload(payload)
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          this.props.onLoading(false);
          let tempOption = Object.assign({}, this.state.tempOption);
          tempOption.url = response.data.data.imageThumbnail[0].url;

          this.setState({
            tempOption: tempOption,
          });
        });
    } catch (error) {
      this.props.onLoading(false);
      console.log(error);
    }
  }
  componentDidMount() {}
  addOption() {
    try {
      if (this.state.tempOption.name && this.state.tempOption.name.length > 0) {
        let tempOption = Object.assign({}, this.state.tempOption);
        tempOption.id = Math.floor(Math.random() * 99999);

        let nextOrder = tempOption.order + 1;

        this.firstInput.current.textContent = '';
        this.setState({
          tempOption: {
            url: '',
            name: '',
            order: nextOrder,
          },
        });

        this.props.onAddOption(tempOption);
      }
    } catch (error) {
      console.log(error);
    }
    /* Creating Random ID Generator */
  }
  setPollItem = (e) => {
    let name = e.currentTarget.textContent.trim();
    if (name.length > 0) {
      if (e.currentTarget.dataset.child === 'first') {
        let tempOption = Object.assign({}, this.state.tempOption);
        tempOption.name = name;
        this.setState({
          tempOption: tempOption,
        });
      }
    }
  };

  chooseFirstFile() {
    try {
      this.firstFileUpload.current.click();
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <div className="addOption option">
        <button className="addBtn" onClick={() => this.addOption()}>
          <img
            width="14"
            height="13"
            src="/img/plus.svg"
            alt="Add Option"
            title="Add Option"
          />
        </button>
        <div className="centerBox">
          <div
            placeholder="Add a response"
            className="editableBox"
            onKeyUp={(e) => this.setPollItem(e)}
            contentEditable="true"
            suppressContentEditableWarning={true}
            data-child="first"
            ref={this.firstInput}
          ></div>
        </div>
        {this.state.tempOption.url.length > 0 ? (
          <div className="userImg">
            <img
              src={this.state.tempOption.url}
              alt="user"
              title="user"
              width="75"
              height="75"
            />{' '}
          </div>
        ) : (
          ''
        )}
        <div className="btns">
          <button>
            <img
              data-child="first"
              src="/img/image.svg"
              alt="user"
              title="user"
              onClick={() => this.chooseFirstFile()}
            />
          </button>
          <input
            type="file"
            ref={this.firstFileUpload}
            accept="image/*"
            onChange={() => this.uploadFile()}
          />
        </div>
      </div>
    );
  }
}
AddOption.propTypes = {
  onLoading: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired,
};
export default React.memo(AddOption);
